import styled from "styled-components";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import React from "react";

export enum DropDownComponentStyle {
  OUTLINED = "outlined",
  TRANSPARENT = "transparent",
}

interface DropDownComponentProps {
  key: string;
  onSelectedValueChanged: (key: string, value: string) => void;
  values: string[];
  defaultValue?: string;
  style?: DropDownComponentStyle;
  selectedValue?: string;
  disabled?: boolean;
  label?: string;
  isWhite?: boolean;
}

const DropDownComponent = (props: DropDownComponentProps) => {
  const key = props.key;
  const values = props.values;
  const deaultValue = props.defaultValue;
  const style = props.style ?? DropDownComponentStyle.OUTLINED;
  const [selectedValue, setSelectedValue] = React.useState(props.defaultValue);

  React.useEffect(() => {
    setSelectedValue(props.selectedValue);
  }, [props.selectedValue]);

  const handleInputChange = (key: string, value: any) => {
    props.onSelectedValueChanged(key, value);
    setSelectedValue(value);
  };

  const getLabel = () => {
    if (selectedValue) {
      return props.label;
    }

    if (deaultValue) {
      return deaultValue;
    }

    return props.label || deaultValue || "Selecione";
  };

  return (
    <Root>
      <FormControl size="small" fullWidth>
        <InputLabel
          disabled={props.disabled === true}
          id="demo-simple-select-label"
          style={props.isWhite ? { color: "white" } : {}}
        >
          {getLabel()}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          displayEmpty={false}
          style={{ borderRadius: "16px" }}
          label={deaultValue}
          value={selectedValue}
          disabled={props.disabled === true}
          onChange={(event) => {
            handleInputChange(key, event.target.value);
          }}
          sx={
            props.isWhite
              ? {
                  color: "white",
                  borderRadius: 16,
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                }
              : null
          }
        >
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export default DropDownComponent;
