import styled from "styled-components";
import ReactPageBaseComponent from "../../components/BaseComponents/ReactPageBaseComponent";
import { Colors } from "../../theme/variants";
import Title from "../../components/Typography/Tittle";
import ActionsByStatus from "../../components/charts/ActionStatus/ActionsByStatus";
import ActionsEvolution from "../../components/charts/EvolutionChart/ActionEvolution";
import RestrictionsRemoveChart from "../../components/charts/RestrictionsStatusControlChart/RestrictionsRemoveChart";
import { useState } from "react";
import { BaseState } from "../Actions/ViewActions/utils/BaseState";
import ActionsService from "../../services/action/ActionsService";
import { useDashboard } from "../../contexts/dashboard";
import PriorityCriteriaChart from "../../components/charts/PriorityCriteria/PriorityCriteriaChart";
import LoadingComponent from "../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../components/StateComponents/ErrorComponent";
import EmptyClientAndConstructionState from "../../components/StateComponents/EmptyClientAndConstruction";
import React from "react";
import DelayedActionsChart from "../../components/charts/DelayedActionsChart/DelayedActionsChart";
import DropDownComponent from "../../components/InputValues/DropDownComponent";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

type Props = {
  section: string;
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  const firstYear = 2019;

  for (let year = firstYear; year <= currentYear - 1; year++) {
    years.push(year.toString());
  }

  for (let year = currentYear; year <= currentYear + 5; year++) {
    years.push(year.toString());
  }

  years.sort((a, b) => {
    return parseInt(a) - parseInt(b);
  });

  return years;
};

const Dashboard: React.FC<Props> = ({ section }) => {
  const actionService = new ActionsService();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const { constructionId } = useDashboard();
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <EmptyClientAndConstructionState pageDescription="o dashboard" />
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <LoadingComponent />
        ) : null}

        {state === BaseState.error ? <ErrorComponent /> : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <React.Fragment>
            <TittleContainer>
              <Title>DASHBOARD</Title>
              <DropDownComponent
                key="months"
                values={getYears()}
                label={"Ano"}
                defaultValue={selectedYear}
                onSelectedValueChanged={(key,value) => setSelectedYear(value)}
              />
            </TittleContainer>
            <CardContainer>
              <ActionsByStatus year={selectedYear} constructionId={constructionId.toString()} />
            </CardContainer>
            <CardContainer>
              <ActionsEvolution constructionId={constructionId.toString()} year={selectedYear} />
            </CardContainer>
            <CardContainer>
              <RestrictionsRemoveChart
                constructionId={constructionId.toString()}
                year={selectedYear}
              />
            </CardContainer>
            <PriorityCriteriaAndDelayChartContainer>
              <PriorityCriteriaChart
                constructionId={constructionId.toString()}
                year={selectedYear}
              />
              <DelayedActionsChart constructionId={constructionId.toString()} />
            </PriorityCriteriaAndDelayChartContainer>
          </React.Fragment>
        ) : null}
      </PageContainer>
    </ReactPageBaseComponent>
  );
};

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  padding-top: 84px;
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto, auto auto auto;
  overflow-x: auto;
  background-color: ${Colors.neutralColorSurface};
  padding-bottom: 32px;


  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: hidden;
  }
`;

const CardContainer = styled.div`
  margin-top: 32px;
  margin-right: 32px;
  margin-left: 32px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-right: 16px;
    margin-left: 16px;
    height: max-content;
    overflow-x: hidden;
  }
`;

const PriorityCriteriaAndDelayChartContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 32px;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const TittleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 159px;
  align-items: center;
  padding-right: 32px;
  width: 100%;
`;

export default Dashboard;
