import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BaseComponent from "../../../shared/base/BeaseComponent";
import TextField from "@mui/material/TextField";
import "dayjs/locale/pt-br";

interface InputDateComponentProps {
  value: Date | undefined;
  label: string;
  format: string;
  enable?: boolean;
  onChange: (value: Date | null) => void;
}

interface InputDateComponentRef {}

const InputDateComponent = React.forwardRef<
  InputDateComponentRef | undefined,
  InputDateComponentProps
>(({ value, label, format, onChange, enable }, ref) => {
  return (
    <BaseComponent>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          // onChange={(newValue) => onChange(newValue)}
          inputFormat={format || "DD/MM/YYYY"}
          label={label}
          onChange={() => {}}
          renderInput={(params) => (
            <TextField
              placeholder="dd/mm/aaaa"
              size="small"
              fullWidth
              {...params}
            />
          )}
          disabled={!enable}
          // sx={{ width: "100%" }}
          // slotProps={{ textField: { variant: "standard" } }}
          // format={format}
        />
      </LocalizationProvider>
    </BaseComponent>
  );
});

export default InputDateComponent;
