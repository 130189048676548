import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { chartColors } from "../../../config/app-info";

type Props = {
  title: string;
  labels: string[];
  values: number[];
};

const GlobalChart: React.FC<Props> = ({ title, labels, values }) => {
  const data = [
    {
      name: labels[0],
      data: [values[0]],
    },
    {
      name: labels[1],
      data: [values[1]],
    },
    {
      name: labels[2],
      data: [values[2]],
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: title,
      align: "center",
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 40,
      colors: ["transparent"],
    },
    yaxis: {
      show: false,
      min: 1,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [""],
    },
    fill: {
      opacity: 1,
    },
    colors: chartColors,
  };

  return <Chart options={options} series={data} type="bar" height="350" />;
};

export default GlobalChart;
