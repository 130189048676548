import React from "react";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
//import { chartColors } from "../../../config/app-info";

type Props = {
  title: string;
  labels: string[];
  data: number[];
};
const MethodologyChart: React.FC<Props> = ({ title, labels, data }) => {
  const series: ApexAxisChartSeries = [];

  if (data !== undefined && data.length) {
    for (let i = 0; i < data.length; i++) {
      const object = {
        name: labels[i] ? labels[i] : "",
        data: [
          {
            x: labels[i] ? labels[i] : "",
            y: data[i],
          },
        ],
      };

      series.push(object);
    }
  }

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return `${value}: ${w.config.series[seriesIndex].data[0].y}`;
      },
    },
    title: {
      text: title,
      align: "center",
    },
    colors: ["#A9A9A9", "#4169E1", "#87CEEB", "#1E90FF"],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Chart options={options} series={series} type="treemap" height="300" />
  );
};

export default withTheme(MethodologyChart);
