import styled from "styled-components";
import { Colors } from "../../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

const HeaderCellContainer = styled.div<{ isVisible?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.neutralColorSurface};

  /* visibility: ${(props) => (props.isVisible ? "visible" : "hidden")}; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 48px;
  }
`;

const HeaderCellText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  color: ${Colors.textColorBlack};
`;

export { HeaderCellContainer, HeaderCellText };
