import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/variants";
import { Button } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import { ReactComponent as CheckIcon } from "../../../assets/check.svg";
import { ReactComponent as ImageIcon } from "../../../assets/image.svg";
import { ReactComponent as PCCIcon } from "../../../assets/pcc-icon.svg";

interface MouseHoverComponentProps {
  model?: VerticalServiceModel;
  editServiceOnClick?: (model?: VerticalServiceModel) => void;
  deleteServiceOnClick?: (model?: VerticalServiceModel) => void;
  uploadImageOnClick?: (model?: VerticalServiceModel) => void;
  addPurchasingContractingPlanOnClick?: (model?: VerticalServiceModel) => void;
  updateServiceProgressOnClick?: (model?: VerticalServiceModel) => void;
}

const MouseHoverActionsComponent: React.FC<MouseHoverComponentProps> = ({
  model,
  editServiceOnClick,
  deleteServiceOnClick,
  uploadImageOnClick,
  updateServiceProgressOnClick,
  addPurchasingContractingPlanOnClick,
}) => {
  return (
    <ReactBaseComponent>
      <Container>
        <Button
          variant="text"
          style={{ color: "#000", width: "170px" }}
          startIcon={<Edit />}
          onClick={() => editServiceOnClick?.(model)}
        >
          Editar serviço
        </Button>
        <Button
          variant="text"
          style={{ color: "#000" }}
          onClick={() => updateServiceProgressOnClick?.(model)}
          startIcon={<CheckIcon style={{ color: "#000" }} />}
        >
          Avançar serviço
        </Button>
        <Button
          variant="text"
          style={{ color: "#000" }}
          onClick={() => uploadImageOnClick?.(model)}
          startIcon={<ImageIcon style={{ color: "#000" }} />}
        >
          Adicionar imagens
        </Button>

        <Button
          variant="text"
          style={{ color: "#000" }}
          onClick={() => addPurchasingContractingPlanOnClick?.(model)}
          startIcon={<PCCIcon style={{ color: "#000" }} />}
        >
          Compra/Contratação
        </Button>

        <Button
          variant="text"
          style={{ color: "#000" }}
          onClick={() => deleteServiceOnClick?.(model)}
          startIcon={<Delete style={{ color: "#000" }} />}
        >
          Excluir serviço
        </Button>
      </Container>
    </ReactBaseComponent>
  );
};

const Container = styled.div`
  width: 179px;
  background-color: ${Colors.neutralColorSurface};
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 48px;
  position: absolute;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default MouseHoverActionsComponent;
