import { useEffect, useState } from "react";
import PopUpBaseComponent from "../../../../../../components/PopUps/PopUpBaseComponent";
import {
  ContentContainer,
  NameOfOrderAndQuantityContainer,
  StepsListContainer,
  VerticalGroupDropDownComponentContainer,
} from "./OrderOfStepsFormPopUp.styles";
import InputValueComponent from "../../../../../../components/InputValues/InputValueComponent";
import VerticalGroupDropDownComponent, {
  ItemType,
} from "../../../../../../components/InputValues/VerticalGroupDropDownComponent";
import VerticalServiceGroup from "../../../../../../services/interfaces/VerticalServiceGroup";
import OrderOfStepModel from "../../models/OrderOfStepModel";
import StepModel from "../../models/StepModel";
import { ReactComponent as PlusIcon } from "../../../../../../assets/icon-plus-blue.svg";
import { PlusIconButton } from "../OrderOfStepsEditServicePopUp/OrderOfStepsEditServicePopUp.styles";

interface OrderOfStepsFormPopUpProps {
  isOpen: boolean;
  verticalServiceGroups: VerticalServiceGroup[];
  verticalServiceGroupsAvailable: VerticalServiceGroup[];
  isLoading: boolean;
  orderOfStep?: OrderOfStepModel;
  isMobile?: boolean;
  onClose: () => void;
  cancelOnClick: () => void;
  onConfirm: (name: string, steps: StepModel[]) => void;
}

interface QuanityOfSteps {
  number: number;
  steps: string;
  itemStep: string;
}

const getOrderOfSteps = (quantity: number): QuanityOfSteps[] => {
  const steps: QuanityOfSteps[] = [];

  for (let i = 1; i <= quantity; i++) {
    steps.push({
      number: i,
      steps: i === 1 ? "1 etapa" : i + " etapas",
      itemStep: i === 1 ? "Etapa 1" : "Etapa " + i,
    });
  }

  return steps;
};

interface SelectedGroupByStep {
  step: number;
  item?: ItemType;
}

const OrderOfStepsFormPopUp: React.FC<OrderOfStepsFormPopUpProps> = ({
  isOpen,
  orderOfStep,
  onClose,
  isLoading,
  verticalServiceGroups,
  verticalServiceGroupsAvailable,
  cancelOnClick,
  onConfirm,
  isMobile,
}) => {
  const [numberOfSteps, setNumberOfSteps] = useState<QuanityOfSteps[]>(
    getOrderOfSteps(8)
  );
  const [selectedNumberSteps, setSelectedNumberSteps] =
    useState<QuanityOfSteps>();

  const [orderName, setOrderName] = useState<string>("");
  const [selectedGroup, setSelectedGroup] = useState<SelectedGroupByStep[]>([]);
  const [verticalServiceGroupsList, setVerticalServiceGroupsList] = useState<
    VerticalServiceGroup[][]
  >([]);
  const [countOfSteps, setCountOfSteps] = useState<number>(
    verticalServiceGroups.length
  );

  useEffect(() => {
    if (orderOfStep) {
      setOrderName(orderOfStep.name);
      setSelectedNumberSteps(
        numberOfSteps.find((step) => step.number === orderOfStep.steps.length)
      );
      setCountOfSteps(orderOfStep.steps.length);
      const steps = orderOfStep.steps.map(
        (step) =>
          ({
            step: step.step,
            item: {
              group: step.serviceGroup.name,
              color: step.serviceGroup.color,
              id: step.serviceGroup.id,
            },
          } as SelectedGroupByStep)
      );
      setSelectedGroup(steps);

      const supportList: VerticalServiceGroup[][] = [];
      for (
        let index = 0;
        index < verticalServiceGroupsAvailable.length;
        index++
      ) {
        if (
          verticalServiceGroupsAvailable.find(
            (group) => group.id === verticalServiceGroupsAvailable[index].id
          ) !== undefined
        ) {
          supportList?.push(verticalServiceGroupsAvailable);
        }
      }
      setVerticalServiceGroupsList(supportList);
    }
  }, [orderOfStep]);

  useEffect(() => {
    if (orderOfStep) {
      return;
    }

    const supportList: VerticalServiceGroup[][] = [];
    for (let index = 0; index < verticalServiceGroups.length; index++) {
      supportList?.push(verticalServiceGroups);
    }
    setCountOfSteps(verticalServiceGroups.length);
    setVerticalServiceGroupsList(supportList);

    const list: ItemType[] = verticalServiceGroups.map(
      (group) =>
        ({
          group: group.name,
          color: group.color,
          id: group.id,
        } as ItemType)
    );

    const selectedGroup: SelectedGroupByStep[] = [];
    for (let index = 0; index < verticalServiceGroups.length; index++) {
      selectedGroup.push({
        step: index + 1,
        item: list[index],
      });
    }
    setSelectedGroup(selectedGroup);
  }, [verticalServiceGroups]);

  const confirmOnClick = () => {
    const steps: StepModel[] = [];
    selectedGroup.forEach((groupId, index) => {
      steps.push({
        step: index + 1,
        serviceGroup: verticalServiceGroups.find(
          (group) => group.id.toString() === groupId?.item?.id.toString()
        )!,
      });
    });

    onConfirm(orderName, steps);

    setSelectedGroup([]);
    setSelectedNumberSteps(undefined);
  };

  const handleSelectedGroup = (item: ItemType, step: number) => {
    const newSelectedGroup = [...selectedGroup];
    const selectedStep = newSelectedGroup.find((group) => group.step === step);
    if (selectedStep) {
      selectedStep.item = item;
      setSelectedGroup(newSelectedGroup);
    } else {
      setSelectedGroup([
        ...newSelectedGroup,
        { step: step, item: item } as SelectedGroupByStep,
      ]);
    }
  };

  const removeSelectedGroup = (step: number) => {
    const copySelectedGroup = [...selectedGroup];
    const filteredList = copySelectedGroup.filter(
      (group) => group.step !== step
    );
    filteredList.forEach((group, index) => {
      group.step = index + 1;
    });
    setSelectedGroup(filteredList);
    setCountOfSteps(countOfSteps - 1);
  };

  const handleCancel = () => {
    resetSelectedGroupsAndName();
    cancelOnClick();
  };

  const handleOnClose = () => {
    resetSelectedGroupsAndName();
    onClose();
  };

  const resetSelectedGroupsAndName = () => {
    const supportList: VerticalServiceGroup[][] = [];
    for (let index = 0; index < verticalServiceGroups.length; index++) {
      supportList?.push(verticalServiceGroups);
    }
    setCountOfSteps(verticalServiceGroups.length);
    setVerticalServiceGroupsList(supportList);
    setOrderName("");
  };

  const addStep = () => {
    if (countOfSteps === verticalServiceGroups.length) {
      return;
    }

    setCountOfSteps(countOfSteps + 1);
  };

  return (
    <PopUpBaseComponent
      isOpen={isOpen}
      closeOnClick={handleOnClose}
      cancelOnClick={handleCancel}
      confirmOnClick={confirmOnClick}
      isLoading={isLoading}
      titte="Cadastrar nova ordem de etapas"
      width={954}
      isMobile={isMobile}
      enableConfirmButton={
        selectedGroup.length === countOfSteps && orderName !== ""
      }
    >
      <ContentContainer>
        <NameOfOrderAndQuantityContainer>
          <InputValueComponent
            label="Nome da ordem"
            value={orderName}
            onChange={(value) => setOrderName(value)}
          />
        </NameOfOrderAndQuantityContainer>
        <StepsListContainer>
          {getOrderOfSteps(countOfSteps).map((step, index) => {
            return (
              <VerticalGroupDropDownComponentContainer key={step.number}>
                <VerticalGroupDropDownComponent
                  key={step.steps}
                  values={
                    verticalServiceGroupsList[index]?.filter(
                      (group) =>
                        selectedGroup.find(
                          (id) =>
                            id?.item?.id.toString() === group.id.toString()
                        ) === undefined
                    ) || []
                  }
                  label={step.itemStep}
                  onSelectedValueChanged={(item: ItemType) =>
                    handleSelectedGroup(item, index + 1)
                  }
                  onClear={() => removeSelectedGroup(index + 1)}
                  selectedValue={
                    selectedGroup.find(
                      (selectedGroup) => selectedGroup.step === step.number
                    )?.item
                  }
                />
              </VerticalGroupDropDownComponentContainer>
            );
          })}
          <PlusIconButton
            onClick={addStep}
            disabled={countOfSteps === verticalServiceGroups.length}
          >
            <PlusIcon />
          </PlusIconButton>
        </StepsListContainer>
      </ContentContainer>
    </PopUpBaseComponent>
  );
};

export default OrderOfStepsFormPopUp;
