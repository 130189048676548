import IRestrictionEntity from "../../../services/restrictions/entity/RestrictionEntity";
import CriteriaModel from "./CriteriaModel";

export interface IRestrictionMappingModel {
  id?: string;
  restrictionId: string;
  name: string;
  category?: number;
  priority?: number;
  impact?: number;
  comment?: string;
  code?: string;
  status: boolean | null;
  createdAt: Date;
  criteriaId: string;
  criteriaName: string;
}

export default class RestrictionMappingModel
  implements IRestrictionMappingModel
{
  id?: string;
  restrictionId: string;
  name: string;
  category?: number;
  priority?: number;
  impact?: number;
  comment?: string;
  code?: string;
  status: boolean | null;
  createdAt: Date;
  criteriaId: string;
  criteriaName: string;

  constructor(data: IRestrictionMappingModel) {
    this.restrictionId = data.restrictionId;
    this.name = data.name;
    this.category = data.category;
    this.priority = data.priority;
    this.impact = data.impact;
    this.comment = data.comment;
    this.code = data.code;
    this.status = data.status;
    this.createdAt = new Date(data.createdAt);
    this.criteriaId = data.criteriaId;
    this.criteriaName = data.criteriaName;
    this.id = data.id || "";
  }

  static emptyObject(): IRestrictionMappingModel {
    return new RestrictionMappingModel({} as IRestrictionMappingModel);
  }

  static parseFromEntityArray(
    entities: IRestrictionEntity[]
  ): IRestrictionMappingModel[] {
    return entities.map((entity) => this.parseFromEntity(entity));
  }

  static parseFromEntity(entity: IRestrictionEntity): IRestrictionMappingModel {
    return new RestrictionMappingModel({
      id: entity.restrictionMappingId,
      restrictionId: entity.id || entity?.restrictionId?.id || "",
      name: entity.name || entity?.restrictionId?.name || "",
      category: entity.category,
      priority: entity.priority,
      impact: entity.impact,
      comment: entity.comment || entity?.comments,
      code: entity.code,
      status: entity.status,
      createdAt: new Date(entity.createdAt),
      criteriaId:
        entity?.criteriaId?.id || entity?.restrictionId?.criteriaId?.id,
      criteriaName:
        entity?.criteriaId?.name || entity?.restrictionId?.criteriaId?.name,
    });
  }
}
