import styled from "styled-components";
import {
  Autocomplete,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useState } from "react";
import IconButtonComponent from "../Button/IconButtonComponent";
import { ReactComponent as TrashIcon } from "../../assets/trash_without_border.svg";
import VerticalServiceGroup from "../../services/interfaces/VerticalServiceGroup";

export enum DropDownComponentStyle {
  OUTLINED = "outlined",
  TRANSPARENT = "transparent",
}

interface DropDownComponentProps {
  key: string;
  onSelectedValueChanged: (item: ItemType) => void;
  onClear?: () => void;
  values: VerticalServiceGroup[];
  defaultValue?: number;
  style?: DropDownComponentStyle;
  selectedValue?: ItemType;
  label: string;
  onDeletedCustomType?: (customTypeId: string) => void;
}

export interface ItemType {
  group: string;
  color: string;
  id: number;
}

const NEW_SERVICE_TEMP_ID = -19039;

const VerticalGroupDropDownComponent = (props: DropDownComponentProps) => {
  const groupValues = (values: any[]): ItemType[] => {
    const listItens: ItemType[] = [];
    const groupValues: ItemType[] = [];

    for (const value of values) {
      const groupName = value.name;
      const color = value.color;

      listItens.push({
        id: value.id,
        group: groupName,
        color: color,
      });
    }
    return listItens;
  };
  const [text, setText] = React.useState<string>("");
  const [value, setValue] = useState<ItemType[]>([
    {
      group: "",
      color: "",
      id: -1,
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<ItemType>({
    group: "",
    color: "",
    id: -1,
  });
  // props.selectedValue

  React.useEffect(() => {
    const uniqueValues = Array.from(
      new Set(props.values.map((value) => value.id))
    )
      .map((id) => props.values.find((value) => value.id === id))
      .filter((value) => value !== undefined) as VerticalServiceGroup[];
    setValue(groupValues(uniqueValues));
  }, [props.values]);

  React.useEffect(() => {
    if (props.selectedValue) {
      setSelectedItem({
        group: props.selectedValue?.group || "",
        color: props.selectedValue?.color || "",
        id: props.selectedValue?.id || 0,
      });
    }
  }, [props.selectedValue]);

  const handleInputChange = (newValue: string | ItemType | null) => {
    if (!newValue) {
      return;
    }

    if (typeof newValue === "string") {
      return;
    }
    setSelectedItem(newValue);
    props.onSelectedValueChanged(newValue);
    return;
  };

  const onInputValueChange = (text: string) => {
    setText(text);
  };

  const _filterOptions = createFilterOptions<ItemType>();
  const filterOptions = (options: any, state: any) => {
    const results = _filterOptions(options, state);

    if (state.inputValue === "") {
      return results;
    }

    return options.filter((option: ItemType) => {
      return option.group
        .toLowerCase()
        .includes(state.inputValue.toLowerCase());
    });
  };

  const textFieldOnChange = (e: any) => {
    if (e.target.value === "") {
      setSelectedItem({
        group: "",
        color: "",
        id: -1,
      });
      props.onClear?.();
    }
  };

  return (
    <Root>
      <Autocomplete
        id="service_type_selector"
        value={selectedItem}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : selectedItem?.group || ""
        }
        fullWidth
        size="small"
        onChange={(e, data) => handleInputChange(data)}
        onInputChange={(event, value) => onInputValueChange(value)}
        options={value || []}
        renderOption={(props, group) => (
          <CustomMenuItem
            {...props}
            key={group.group}
            autoFocus={false}
            value={group.group}
          >
            <CustomCircleIndicator
              style={{ background: `${group.color || "#fff"}` }}
            />
            <CustomMenuItemText>{group.group}</CustomMenuItemText>
          </CustomMenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: "search",
              startAdornment: (
                <CustomCircleIndicator
                  style={{
                    backgroundColor: `${selectedItem?.color}`,
                    marginLeft: "2px",
                  }}
                />
              ),
              onChange: (e) => {
                textFieldOnChange(e);
              },
            }}
          />
        )}
        disableClearable
        disableCloseOnSelect
        noOptionsText="Nenhuma opção encontrada"
        filterOptions={filterOptions}
        filterSelectedOptions
        freeSolo
      />
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const CustomMenuItem = styled(MenuItem)`
  display: flex;
  height: 40px;
  color: #565757;
  flex-direction: row;
  gap: 8px;
`;

const CustomCircleIndicator = styled.div`
  width: 20px;
  height: 20px;
`;

const CustomMenuItemContainer = styled.div`
  width: 100%;
`;

const CustomMenuItemText = styled.div`
  width: 150px;
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  //three dots
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputTextContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px;
`;

export default VerticalGroupDropDownComponent;
