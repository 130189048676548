import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../../../utils/mobileDimen";
import { Colors } from "../../../../../../theme/variants";

const ContentContainer = styled.div`
  display: flex;
  width: 953px;
  flex-direction: column;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100vw - 64px);
    height: calc(100vh - 240px);
  }

  overflow-y: auto;
  overflow-x: hidden;
`;

const NameOfOrderAndQuantityContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-top: 8px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
  }
`;

const StepsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

const VerticalGroupDropDownComponentContainer = styled.div`
  min-width: 226px;
`;

const MoreItemsNumber = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: ${Colors.primaryColor};
  margin-left: 4px;
  place-self: center;
  padding: 4px;
  border-radius: 50%;
`;

export {
  ContentContainer,
  NameOfOrderAndQuantityContainer,
  StepsListContainer,
  VerticalGroupDropDownComponentContainer,
  MoreItemsNumber,
};
