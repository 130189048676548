import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Menu as MenuIcon } from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { api } from "../../services/api";
import { useDashboard } from "../../contexts/dashboard";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import DropDownComponent from "../InputValues/DropDownComponent";
import ProfileComponent from "../Profile/ProfileComponent";
import { Colors } from "../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import {
  Client,
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
  isUserClientRole,
} from "../../config/app-info";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const [dtFirstBeginDate, setDtFirstBeginDate] = useState<Date | null>(null);

  const {
    clientId,
    setClientId,
    constructionId,
    setConstructionId,
    dtBegin,
    setDtBegin,
    dtEnd,
    setDtEnd,
    setIsDashboard,
    isDashboard,
    setConstructions,
    constructions,
    clients,
    setClients,
  } = useDashboard();
  const location = useLocation();
  const { user } = useAuth();
  const pathname = location.pathname;
  const [blockClientSelect, setBlockClientSelect] = useState(false);
  const isNewScreens =
    pathname.indexOf("/acompanhar-acoes") !== -1 ||
    pathname.indexOf("/dashboard/") !== -1 ||
    pathname.indexOf("/vertical-servico") !== -1 ||
    // pathname.indexOf("/restricoes/listar/") !== -1 ||
    pathname.indexOf("/mapeamento-restricoes/listar/") !== -1 ||
    pathname.indexOf("mapeamento-restricoes/cadastrar/") !== -1 ||
    pathname.indexOf("/mapeamento-restricoes/editar/") !== -1 ||
    pathname.indexOf(ORDER_OF_STEPS_PATH) !== -1 ||
    pathname.indexOf(PURCHASING_CONTRACTING_PLAN_PATH) !== -1;
  // pathname.includes("restricoes/editar");
  const isDashboardSection = pathname.indexOf("/dashboard/") !== -1;
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedConstruction, setSelectedConstruction] = useState("");

  useEffect(() => {
    if (clientId > 0) {
      const clientName = clients.filter((client) => client.id === clientId)[0];

      if (!clientName || !clientName.name) {
        return;
      }
      setSelectedClient(clientName.name);

      const fetchConstructions = async () => {
        const response = await api.get(`api/constructions/client/${clientId}`);
        setConstructions(response.data);
      };
      fetchConstructions();

      const fetchFirstDtBegin = async () => {
        const response = await api.get(
          `api/actions/first-begin-date/client/${clientId}`
        );
        setDtFirstBeginDate(response.data);
      };
      fetchFirstDtBegin();
    }
  }, [clientId]);

  useEffect(() => {
    if (user.id) {
      setIsDashboard(isDashboardSection);

      if (isDashboardSection || isNewScreens) {
        const fetchClients = async () => {
          const response = await api.get("api/clients", {
            params: { status: true },
          });

          if (isUserClientRole(user.role) && user.responsibleClientId) {
            const result = selectDefaultClientByUserClient();
            if (result) {
              return;
            }
          }

          setClients(response.data);

          const responsibleClientId = user.responsibleClientId
            ? Number(user.responsibleClientId)
            : undefined;

          // Client's role
          if (isUserClientRole(user.role) && responsibleClientId) {
            setClientId(responsibleClientId);
            setBlockClientSelect(true);
          }

          function selectDefaultClientByUserClient(): boolean {
            const client = (response.data as Client[]).find(
              (client) => client.id === user.responsibleClientId
            );
            if (client) {
              setSelectedClient(client?.name || "");
              setClients([client as Client]);
              setClientId(user.responsibleClientId!!);
              return true;
            } else {
              return false;
            }
          }
        };
        fetchClients();
      }

      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).getDate();
      //const defaultDtBegin = new Date(`${year}-${month}-01 08:00:00`);
      const defaultDtEnd = new Date(
        `${year}-${month}-${lastDayOfMonth} 08:00:00`
      );

      if (dtFirstBeginDate) {
        setDtBegin(dtFirstBeginDate);
      }
      setDtEnd(defaultDtEnd);
    }
  }, [
    dtFirstBeginDate,
    pathname,
    setClientId,
    setConstructions,
    setDtBegin,
    setDtEnd,
    setIsDashboard,
    user,
  ]);

  useEffect(() => {
    if (constructionId === 0) {
      return;
    }

    const constructionName = constructions.filter(
      (construction) => construction.id === constructionId
    )[0];

    if (!constructionName || !constructionName.name) {
      return;
    }

    setSelectedConstruction(constructionName.name);
  }, [constructionId, constructions]);

  const handleChangeClient = async (client: number) => {
    setClientId(client);
  };

  const handleChangeConstruction = async (construction: number) => {
    setConstructionId(construction);
  };

  return (
    <React.Fragment>
      {isNewScreens === true ? (
        <NavbarV2>
          <MunuItemContainer>
            <IconButton onClick={onDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </MunuItemContainer>

          <DropdownContainer>
            <DropDownComponent
              defaultValue="Cliente"
              label="Cliente"
              key="clients"
              selectedValue={selectedClient}
              onSelectedValueChanged={(key, value) =>
                handleChangeClient(
                  clients.filter((client) => client.name === value)[0].id
                )
              }
              values={clients.map((client) => client.name)}
            />
          </DropdownContainer>
          <DropdownContainer>
            <DropDownComponent
              defaultValue="Obra"
              label="Obra"
              key="obra"
              selectedValue={selectedConstruction}
              onSelectedValueChanged={(key, value) => {
                handleChangeConstruction(
                  constructions.filter(
                    (construction) => construction.name === value
                  )[0].id
                );
              }}
              values={constructions.map((construction) => construction.name)}
            />
          </DropdownContainer>
          <ProfileComponentContainer>
            <ProfileComponent userName={user.name} />
          </ProfileComponentContainer>
        </NavbarV2>
      ) : (
        <AppBar position="sticky" elevation={0} style={{ padding: 0 }}>
          <Toolbar>
            {isDashboard ? (
              <Grid container alignItems="center" spacing={6} p={5}>
                {/*<Hidden mdUp>*/}
                <Grid item xs={2} md={1}>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                {/*</Hidden>*/}

                <Grid item xs={5} md={3}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="client">Cliente</InputLabel>
                    <Select
                      disabled={blockClientSelect}
                      defaultValue={String(clientId)}
                      labelId="client"
                      id="client"
                      value={clientId ? String(clientId) : "0"}
                      onChange={(event: SelectChangeEvent) =>
                        handleChangeClient(Number(event.target.value))
                      }
                      label="Cliente"
                    >
                      <MenuItem disabled value={String(clientId)}>
                        <em>Escolha um cliente</em>
                      </MenuItem>
                      {clients.map((client) => {
                        return (
                          <MenuItem key={client.id} value={client.id}>
                            {client.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={5} md={3}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="construction">Obra</InputLabel>
                    <Select
                      defaultValue={String(constructionId)}
                      labelId="construction"
                      id="construction"
                      value={constructionId ? String(constructionId) : "0"}
                      onChange={(event: SelectChangeEvent) =>
                        handleChangeConstruction(Number(event.target.value))
                      }
                      label="Obra"
                    >
                      <MenuItem key="0" value="0">
                        <em>Todas as obras</em>
                      </MenuItem>
                      {constructions.map((construction) => {
                        return (
                          <MenuItem
                            key={construction.id}
                            value={construction.id}
                          >
                            {construction.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={5} md={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                  >
                    <DatePicker
                      label="Data início"
                      value={dtBegin}
                      onChange={(newValue) => {
                        setDtBegin(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="dd/mm/aaaa"
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={5} md={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                  >
                    <DatePicker
                      label="Data fim"
                      value={dtEnd}
                      onChange={(newValue) => {
                        setDtEnd(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="dd/mm/aaaa"
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={1} md={1} textAlign="right">
                  <NavbarUserDropdown />
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems="center" spacing={6} p={5}>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10} md={11} textAlign="right">
                  {/* <NavbarMessagesDropdown /> */}
                  {/* <NavbarNotificationsDropdown /> */}
                  {/* <NavbarLanguagesDropdown /> */}
                  <NavbarUserDropdown />
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </AppBar>
      )}
    </React.Fragment>
  );
};

const NavbarV2 = styled.div`
  width: 100%;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${Colors.neutralColorSurface};
  position: absolute;
  display: grid;
  grid-template-columns: 1fr minmax(0px, 255px) minmax(0px, 255px) max-content;
  align-items: center;
  justify-content: end;
  grid-gap: 24px;
  z-index: 100;
`;
const MunuItemContainer = styled.div`
  margin-left: 16px;
  justify-self: start;
`;

const ProfileComponentContainer = styled.div`
  margin-left: 8px;
  margin-right: 24px;
`;

const DropdownContainer = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    visibility: collapse;
  }
`;

export default withTheme(Navbar);
