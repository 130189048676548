export const VerticalServiceStatus = [
  {
    name: "Em andamento",
    value: 1,
  },
  {
    name: "Concluído",
    value: 2,
  },
  {
    name: "Em atraso",
    value: 3,
  },
  {
    name: "Não iniciada",
    value: 4,
  },
];
