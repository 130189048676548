import styled from "styled-components";
import { Colors } from "../../../../../../theme/variants";
import { IconButton } from "@mui/material";

const ContentContainer = styled.div`
  width: 352px;
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 16px;
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.primaryColorDark};
`;

const PlusIconButton = styled(IconButton)`
  height: 36px;
  width: 36px;
  border: 1px solid ${Colors.primaryColor};
`;

export { ContentContainer, Description, PlusIconButton };
