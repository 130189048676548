import { api } from "./api";

export interface UserResponsible {
  id: string;
  name: string;
}

export default class UserService {
  async fetchResponsibleUsers(
    constructionEditId: string
  ): Promise<UserResponsible[]> {
    try {
      const response = await api.get(
        `api/users/responsible-construction/${constructionEditId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
