import { Restriction } from "../../config/app-info";
import { RestrictionFormEntity } from "../../pages/Restrictions/Form";
import { api } from "../api";
import RestrictionEntity from "./entity/RestrictionEntity";

export default class RestrictionsService {
  fetchRestrictions = async (): Promise<RestrictionEntity[]> => {
    try {
      const response = await api.get("api/restrictions");
      return response.data as RestrictionEntity[];
    } catch (error) {
      throw error;
    }
  };

  fetchRestrictionByConstructionId = async (
    constructionId: number
  ): Promise<RestrictionEntity[]> => {
    try {
      const response = await api.get(
        `api/restrictions-mappings/construction/${constructionId}/restrictions`
      );

      return response.data.map((restriction: any) => {
        const restrictionEntity: RestrictionEntity = {
          id: restriction.id,
          name: restriction.name,
          status: restriction.status,
          createdAt: restriction.createdAt,
          restrictionMappingId: restriction.restrictionMappingId,
          criteriaId: {
            id: restriction.criteriaId.id,
            name: restriction.criteriaId.name,
            status: restriction.criteriaId.status,
            createdAt: restriction.criteriaId.createdAt,
          },
        };
        return restrictionEntity;
      });
    } catch (error) {
      throw error;
    }
  };

  findByConstructionAndRestriction = async (
    constructionId: number,
    restrictionId: string
  ): Promise<RestrictionEntity[]> => {
    try {
      const response = await api.get(
        `api/actions/construction/${constructionId}/restriction/${restrictionId}`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  createNewRestriction = async (
    restriction: RestrictionFormEntity
  ): Promise<RestrictionFormEntity> => {
    try {
      const response = await api.post("api/restrictions", restriction);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
