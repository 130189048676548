import { api } from "./api";

export default class ConstructionService {
  updateImage = async (constructionId: string, image: any) => {
    const result = await api.post(
      `/api/constructions/${constructionId}/image`,
      image,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  };
}
