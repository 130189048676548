import styled from "styled-components";
import { Colors } from "../../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh);
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  position: relative;
  align-content: start;
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 195px;
  margin-top: 82px;
  align-items: center;
  margin-right: 32px;
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.primaryColor};
  margin-top: 12px;
  margin-left: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 20px;
  }
`;

const EmptyStateText = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.primaryColor};
  margin-left: 16px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StepContainer = styled.div<{ border?: boolean }>`
  max-width: 100%;
  padding-left: 16px;
  display: grid;
  height: 56px;
  grid-template-columns: max-content 1fr 112px;
  gap: 10px;

  align-items: center;
  border-bottom: ${(props) => (props.border ? "1px solid #777C84" : "none")};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 80px 1fr max-content;
  }
`;

const StepOrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StepOrderColumnText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  color: ${Colors.primaryColor};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 89.5px;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 400px;
  }
`;

const StepOrderText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: ${Colors.primaryColor};
  padding-right: 16px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 400px;
  }
`;

const GroupColorSymbol = styled.div<{ color: string }>`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const CreateNewOrderMobileButtonContainer = styled.div`
  height: 40px;
  width: 184px;
  place-self: end;
  margin-top: 32px;
`;

export {
  CenterContainer,
  PageContainer,
  StepOrderContainer,
  ContentContainer,
  StepsContainer,
  StepContainer,
  TitleContainer,
  Description,
  EmptyStateText,
  StepOrderColumnText,
  StepOrderText,
  GroupColorSymbol,
  ActionsContainer,
  CreateNewOrderMobileButtonContainer,
};
