import React from "react";
import ServiceGroupChecklistDownComponent from "../../../../../../components/InputValues/ServiceGroupChecklistDownComponent";
import { ItemType } from "../../../../../../components/InputValues/VerticalGroupDropDownComponent";
import PopUpBaseComponent from "../../../../../../components/PopUps/PopUpBaseComponent";
import VerticalServiceGroup from "../../../../../../services/interfaces/VerticalServiceGroup";
import OrderOfStepModel from "../../models/OrderOfStepModel";
import StepModel from "../../models/StepModel";
import {
  ContentContainer,
  Description,
} from "./OrderOfStepsEditServicePopUp.styles";

interface OrderOfStepsEditServicePopUpProps {
  isOpen: boolean;
  verticalServiceGroups: VerticalServiceGroup[];
  isLoading: boolean;
  onClose: () => void;
  cancelOnClick: () => void;
  onConfirm: (selectedTypes: ItemType[]) => void;
}

const OrderOfStepsEditServicePopUp: React.FC<
  OrderOfStepsEditServicePopUpProps
> = ({
  isOpen,
  onClose,
  isLoading,
  verticalServiceGroups,
  cancelOnClick,
  onConfirm,
}) => {
  const [selectedGroupByStep, setSelectedGroupByStep] = React.useState<
    ItemType[]
  >([]);

  const confirmOnClick = () => {
    onConfirm([...selectedGroupByStep]);
    setSelectedGroupByStep([]);
  };

  return (
    <PopUpBaseComponent
      isOpen={isOpen}
      closeOnClick={onClose}
      cancelOnClick={cancelOnClick}
      confirmOnClick={() => confirmOnClick()}
      isLoading={isLoading}
      enableConfirmButton={selectedGroupByStep.length > 0}
      titte="Editar serviço"
      positiveButtonText="Reprogramar etapas"
      negativeButtonText="Manter Etapas"
    >
      <ContentContainer>
        <Description>
          Essa alteração impacta nas etapas seguintes do serviço. Selecione
          quais etapas você gostaria de reprogramar.
        </Description>
        <ServiceGroupChecklistDownComponent
          values={verticalServiceGroups}
          key="ServiceGroupChecklistDownComponent"
          onSelectedValueChanged={(items) => setSelectedGroupByStep(items)}
        />
      </ContentContainer>
    </PopUpBaseComponent>
  );
};

export default OrderOfStepsEditServicePopUp;
