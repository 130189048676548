import {
  CellContainer,
  CellText,
  ContentContainer,
  MouseHoverContainer,
  TagFinished,
  TagInNegotiation,
  TagNotCompleted,
  TagNotStarted,
  TagOverdue,
  TagPhase,
} from "./PurchasingAndContractingListItem.styles";
import { ReactComponent as ThreeDots } from "../../../../assets/three_dots.svg";
import PurchasingModel from "../../models/PurchasingModel";
import ContractingModel from "../../models/ContractingMode";
import { PurchasingPlanStatusENUM } from "../../models/PurchasingPlanStatusENUM";
import { ContractingPlanStatusENUM } from "../../models/ContractingPlanStatusENUM";
import { PurchasingPhaseENUM } from "../../models/PurchasingPhaseENUM";
import { ContractingPhaseENUM } from "../../models/ContractingPhaseENUM";
import { MouseEventHandler, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import PlanTooltip from "../PlanTooltip/PlanTooltop";
import { PurchasingContractingENUM } from "../../models/PurchasingContractingENUM";
import { useAuth } from "../../../../contexts/auth";
import { isEapTeamORClientManager } from "../../../../config/app-info";

interface PurchasingAndContractingListItemProps {
  purchasingModel?: PurchasingModel;
  contractingModel?: ContractingModel;
  isMobile?: boolean;
  index: number;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  initAndCompleteNegotiationCallback: (
    id: number,
    isPhaseInitiated: boolean,
    type: PurchasingContractingENUM
  ) => void;
  returnPhaseCallback: (id: number, type: PurchasingContractingENUM) => void;
}

const PurchasingAndContractingListItem: React.FC<
  PurchasingAndContractingListItemProps
> = ({
  purchasingModel,
  contractingModel,
  isMobile,
  index,
  onDelete,
  onEdit,
  initAndCompleteNegotiationCallback,
  returnPhaseCallback,
}) => {
  const name = purchasingModel?.name || contractingModel?.name || "-";
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const exportImportMenuIsOpen = Boolean(anchorEl);

  const supplierQuotationDate: Date | undefined =
    purchasingModel?.supplierQuotationDate ||
    contractingModel?.supplierQuotationDate;

  const supplierDateLimitOrNegotiationDateLimit: Date | undefined =
    purchasingModel?.purchasingDateLimit ||
    contractingModel?.contractingDateLimit;
  const deliveryDateLimitOrHireDateLimit: Date | undefined =
    purchasingModel?.deliveryDateLimit || contractingModel?.deliveryDateLimit;

  const purchasingStatus = purchasingModel?.status;
  const contractingStatus = contractingModel?.status;
  const phase = purchasingModel?.phase || contractingModel?.phase || "-";
  const responsibleName =
    purchasingModel?.responsisbleName ||
    contractingModel?.responsisbleName ||
    "-";

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    if (purchasingModel && purchasingModel.id) {
      onDelete(purchasingModel.id);
    }

    if (contractingModel && contractingModel.id) {
      onDelete(contractingModel.id);
    }
  };

  const handleEdit = () => {
    if (purchasingModel && purchasingModel.id) {
      onEdit(purchasingModel.id);
    }

    if (contractingModel && contractingModel.id) {
      onEdit(contractingModel.id);
    }
  };

  const handleReturnPhase = () => {
    if (purchasingModel && purchasingModel.id) {
      returnPhaseCallback(purchasingModel.id, PurchasingContractingENUM.PURCHASING)
    }
    if (contractingModel && contractingModel.id) {
      returnPhaseCallback(contractingModel.id, PurchasingContractingENUM.CONTRACTING)
    }
  }

  const handleInitAndCompleteNegotiation = () => {
    if (purchasingModel && purchasingModel.id) {
      initAndCompleteNegotiationCallback(
        purchasingModel.id,
        purchasingModel.isPhaseInitiated === true,
        PurchasingContractingENUM.PURCHASING
      );
    }

    if (contractingModel && contractingModel.id) {
      initAndCompleteNegotiationCallback(
        contractingModel.id,
        contractingModel.isPhaseInitiated === true,
        PurchasingContractingENUM.CONTRACTING
      );
    }
  };

  const onMouseEnter = (event: any) => {
    if (isMouseOver !== undefined) {
      setIsMouseOver(true);
    }
  };

  const onMouseLeave = (event: any) => {
    if (isMouseOver) {
      setIsMouseOver(false);
    }
  };

  return (
    <ContentContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <CellContainer isVisible={true}>
        <CellText>{name}</CellText>
      </CellContainer>
      <CellContainer isVisible={true}>
        <CellText>
          {supplierQuotationDate?.toLocaleDateString("PT-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) || "-"}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>
          {supplierDateLimitOrNegotiationDateLimit?.toLocaleDateString(
            "PT-BR",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          ) || "-"}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>
          {deliveryDateLimitOrHireDateLimit?.toLocaleDateString("PT-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) || "-"}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        {purchasingStatus && (
          <div>
            {purchasingStatus === PurchasingPlanStatusENUM.IN_PROGRESS && (
              <TagInNegotiation>{"Em progresso"}</TagInNegotiation>
            )}

            {purchasingStatus === PurchasingPlanStatusENUM.NOT_STARTED && (
              <TagNotStarted>{"Não iniciado"}</TagNotStarted>
            )}

            {purchasingStatus === PurchasingPlanStatusENUM.OVERDUE && (
              <TagOverdue>{"Atrasado"}</TagOverdue>
            )}

            {purchasingStatus === PurchasingPlanStatusENUM.INCOMPLETE && (
              <TagNotCompleted>{"Incompleto"}</TagNotCompleted>
            )}

            {purchasingStatus ===
              PurchasingPlanStatusENUM.TOTALLY_COMPLETED && (
              <TagFinished>{"Finalizado"}</TagFinished>
            )}
          </div>
        )}

        {contractingStatus && (
          <div>
            {contractingStatus === ContractingPlanStatusENUM.IN_PROGRESS && (
              <TagInNegotiation>{"Em progresso"}</TagInNegotiation>
            )}

            {contractingStatus === ContractingPlanStatusENUM.NOT_STARTED && (
              <TagNotStarted>{"Não iniciado"}</TagNotStarted>
            )}

            {contractingStatus === ContractingPlanStatusENUM.OVERDUE && (
              <TagOverdue>{"Atrasado"}</TagOverdue>
            )}

            {contractingStatus === ContractingPlanStatusENUM.INCOMPLETE && (
              <TagNotCompleted>{"Incompleto"}</TagNotCompleted>
            )}
            {contractingStatus ===
              ContractingPlanStatusENUM.TOTALLY_COMPLETED && (
              <TagFinished>{"Finalizado"}</TagFinished>
            )}
          </div>
        )}
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        {purchasingModel && phase === PurchasingPhaseENUM.ON_APPROVAL && (
          <TagPhase>{"Em aprovação"}</TagPhase>
        )}

        {purchasingModel && phase === PurchasingPhaseENUM.PURCHASING && (
          <TagPhase>{"Comprar"}</TagPhase>
        )}

        {purchasingModel && phase === PurchasingPhaseENUM.QUOTATION && (
          <TagPhase>{"Cotação"}</TagPhase>
        )}

        {purchasingModel &&
          phase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT && (
            <TagPhase>{"Aprovar entrega"}</TagPhase>
          )}

        {purchasingModel && phase === PurchasingPhaseENUM.TOTALLY_COMPLETED && (
          <div>-</div>
        )}

        {contractingModel && phase === ContractingPhaseENUM.ON_APPROVAL && (
          <TagPhase>{"Em aprovação"}</TagPhase>
        )}

        {contractingModel && phase === ContractingPhaseENUM.CONTRACTING && (
          <TagPhase>{"Contratação"}</TagPhase>
        )}

        {contractingModel && phase === ContractingPhaseENUM.QUOTATION && (
          <TagPhase>{"Cotação"}</TagPhase>
        )}

        {contractingModel &&
          phase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT && (
            <TagPhase>{"Aprovar serviço"}</TagPhase>
          )}
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>{responsibleName}</CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <IconButton onClick={handleThreeDotsClick}>
          <ThreeDots />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={exportImportMenuIsOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {purchasingModel &&
            purchasingModel?.status !==
              PurchasingPlanStatusENUM.TOTALLY_COMPLETED && (
              <MenuItem
                disabled={
                  (
                    purchasingModel?.responsibleId === undefined ||
                    isEapTeamORClientManager(user.role) === false
                  ) && (purchasingModel?.responsibleId !== user.id)
                }
                onClick={() => handleInitAndCompleteNegotiation()}
              >
                {purchasingModel?.isPhaseInitiated === false
                  ? "Iniciar fase"
                  : "Finalizar fase"}
              </MenuItem>
            )}

          {purchasingModel && purchasingModel?.phase !== PurchasingPhaseENUM.QUOTATION && (
            <MenuItem
              disabled={
                (purchasingModel?.responsibleId === undefined || !isEapTeamORClientManager(user.role)) && (purchasingModel?.responsibleId !== user.id)
              }
              onClick={() => handleReturnPhase()}
            >
              Retornar fase
            </MenuItem>
          )}

          {contractingModel && contractingModel?.phase !== ContractingPhaseENUM.QUOTATION && (
            <MenuItem
              disabled={
                (contractingModel?.responsibleId === undefined || !isEapTeamORClientManager(user.role)) && (contractingModel?.responsibleId !== user.id)
              }
              onClick={() => handleReturnPhase()}
            >
              Retornar fase
            </MenuItem>
          )}

          {contractingModel &&
            contractingModel?.status !==
              ContractingPlanStatusENUM.TOTALLY_COMPLETED && (
              <MenuItem
                disabled={
                  contractingModel?.responsibleId === undefined ||
                  isEapTeamORClientManager(user.role) === false
                }
                onClick={() => handleInitAndCompleteNegotiation()}
              >
                {contractingModel?.isPhaseInitiated === false
                  ? "Iniciar fase"
                  : "Finalizar fase"}
              </MenuItem>
            )}
          <MenuItem onClick={() => handleEdit()}>Editar plano</MenuItem>
          <MenuItem onClick={() => handleDelete()}>Excluir plano</MenuItem>
        </Menu>
      </CellContainer>

      {isMouseOver && (
        <MouseHoverContainer style={index === 0 ? { marginTop: "-44px" } : {}}>
          <PlanTooltip
            name={purchasingModel?.name || contractingModel?.name || ""}
            quantity={
              purchasingModel?.quantity || contractingModel?.quantity || 0
            }
            unit={purchasingModel?.unit || contractingModel?.unit || ""}
            specifications={
              purchasingModel?.specifications ||
              contractingModel?.specifications ||
              ""
            }
          />
        </MouseHoverContainer>
      )}
    </ContentContainer>
  );
};

export default PurchasingAndContractingListItem;
