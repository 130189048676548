import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import logo from "../../vendor/logo.png";
import FormLogin from "../../components/FormLogin";

const Brand = styled.div`
  width: auto;
  height: 50px;
  margin-bottom: 10px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center center;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Login() {
  return (
    <React.Fragment>
      {/*<Brand />*/}
      <Wrapper>
        <Helmet title="Bem vindo" />

        <Brand />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Bem vindo ao sistema
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Acesso restrito. Por favor, identifique-se!
        </Typography>

        <FormLogin />
      </Wrapper>
    </React.Fragment>
  );
}

export default Login;
