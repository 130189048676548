import { ArrowRight } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../theme/variants";
import { ChevronLeft } from "react-feather";

interface IconButtonProps {
  children?: any;
  border?: boolean;
  enabled?: boolean;
  onClick?: () => void;
}

const IconButtonComponent: React.FC<IconButtonProps> = ({
  children,
  onClick,
  border,
  enabled,
}) => {
  return (
    <CustomIconButton
      style={{ borderWidth: border === false ? 0 : "1px" }}
      disabled={enabled === false}
      onClick={onClick}
    >
      {children}
    </CustomIconButton>
  );
};

const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  border: solid;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${Colors.primaryColor};
`;

const CustomIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: solid;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${Colors.primaryColor};
`;

export default IconButtonComponent;
