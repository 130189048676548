import React, { useEffect, useState } from "react";
import { CardContent, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { AlertNoRecords, Card, Divider, Typography } from "./styles";
import { useDashboard } from "../../contexts/dashboard";
import { api } from "../../services/api";
import RestrictionStatusControlChart from "../../components/charts/RestrictionsStatusControlChart";
import RestrictionStatusControlLateChart from "../../components/charts/RestrictionsStatusControlLateChart";
import ActionsAnalysisDaysOfDelayChart from "../../components/charts/ActionsAnalysisDaysOfDelayChart";
import ActionsAnalysisChart from "../../components/charts/ActionsAnalysisChart";
import EvolutionChart from "../../components/charts/EvolutionChart";
import GlobalChart from "../../components/charts/GlobalChart";
import GlobalCriteriaChart from "../../components/charts/GlobalCriteriaChart";
import GlobalObjectiveChart from "../../components/charts/GlobalObjectiveChart";
import MethodologyChart from "../../components/charts/MethodologyChart";
import CriteriaChart from "../../components/charts/CriteriaChart";
import PriorityActionsChart from "../../components/charts/PriorityActionsChart";
import ConstructionIRRChart from "../../components/charts/ConstructionIRRChart";
import styled from "styled-components";

type GlobalActionsIdentifiers = {
  actionsIdentified: number;
  newActionsIdentified: number;
  totalActions: number;
};

type GlobalActionsIRR = {
  labels: string[];
  totalActions: number[];
  concludedActions: number[];
  monthIRR: number[];
  globalIRR: number[];
};

type GlobalActionsCriteria = {
  labels: string[];
  totalActions: number[];
};

type GlobalActionsCategory = {
  labels: string[];
  totalActions: number[];
};

type ConstructionActionsIRR = {
  labels: string[];
  totalActions: number[];
  concludedActions: number[];
  monthIRR: number[];
  globalIRR: number[];
};

type ConstructionActionsEvolution = {
  labels: string[];
  totalActions: number[];
  concludedActions: number[];
  newActions: number[];
  concludedMonthActions: number[];
};

type ConstructionActionsMethodology = {
  labels: string[];
  totalActions: number[];
};

type ConstructionActionsCriteria = {
  labels: string[];
  totalActions: number[];
};

type ConstructionActionsPriority = {
  labels: string[];
  totalActions: number[];
};

type ConstructionPriorityCriteria = {
  labels: string[];
  totalActions: number[];
};

type ConstructionDaysOfDelay = {
  labels: string[];
  totalActions: number[];
};

type ConstructionLateCriteria = {
  labels: string[];
  totalActions: number[];
};

type ConstructionRestrictionStatusControl = {
  totalActions: number;
  concludedActions: number;
  monthActions: number;
  concludedMonthActions: number;
  pendingActions: number;
  lateActions: number;
  ontimeActions: number;
  futureActions: number;
};

type Props = {
  section: string;
};

const Dashboard: React.FC<Props> = ({ section }) => {
  const [managerName, setManagerName] = useState("");
  const [globalActionsIdentifiers, setGlobalActionsIdentifiers] =
    useState<GlobalActionsIdentifiers>({} as GlobalActionsIdentifiers);
  const [globalActionsIRR, setGlobalActionsIRR] = useState<GlobalActionsIRR>(
    {} as GlobalActionsIRR
  );
  const [globalActionsCriteria, setGlobalActionsCriteria] =
    useState<GlobalActionsCriteria>({} as GlobalActionsCriteria);
  const [globalActionsCategory, setGlobalActionsCategory] =
    useState<GlobalActionsCategory>({} as GlobalActionsCategory);
  const [
    constructionRestrictionStatusControl,
    setConstructionRestrictionStatusControl,
  ] = useState({} as ConstructionRestrictionStatusControl);
  const [constructionActionsIRR, setConstructionActionsIRR] =
    useState<ConstructionActionsIRR>({} as ConstructionActionsIRR);
  const [constructionCustomCriteria, setConstructionCustomCriteria] =
    useState<ConstructionActionsCriteria>({} as ConstructionActionsCriteria);
  const [constructionMethodology, setConstructionMethodology] =
    useState<ConstructionActionsMethodology>(
      {} as ConstructionActionsMethodology
    );
  const [constructionPriority, setConstructionPriority] =
    useState<ConstructionActionsPriority>({} as ConstructionActionsPriority);
  const [constructionPriorityCriteria, setConstructionPriorityCriteria] =
    useState<ConstructionPriorityCriteria>({} as ConstructionPriorityCriteria);
  const [constructionDaysOfDelay, setConstructionDaysOfDelay] =
    useState<ConstructionDaysOfDelay>({} as ConstructionDaysOfDelay);
  const [constructionLateCriteria, setConstructionLateCriteria] =
    useState<ConstructionLateCriteria>({} as ConstructionLateCriteria);
  const [constructionActionsEvolution, setConstructionActionsEvolution] =
    useState<ConstructionActionsEvolution>({} as ConstructionActionsEvolution);
  const { constructionId, clientId, dtBegin, dtEnd } = useDashboard();

  useEffect(() => {
    if (Number(clientId) > 0 && constructionId === 0) {
      let lastDate = "";
      if (dtBegin) {
        let previousMonth = 0;
        let previousYear = 0;

        const tempDate = new Date(
          dtBegin.toLocaleString("fr-CA").substring(0, 10) + " 08:00:00"
        );
        const month = tempDate.getMonth() + 1;
        const year = tempDate.getFullYear();
        if (month === 1) {
          previousMonth = 12;
          previousYear = year - 1;
        } else {
          previousMonth = month - 1;
          previousYear = year;
        }
        const lastDayOfMonth = new Date(
          previousYear,
          previousMonth,
          0
        ).getDate();

        lastDate = `${previousYear}-${String(previousMonth).padStart(
          2,
          "0"
        )}-${String(lastDayOfMonth).padStart(2, "0")}`;

        // Global identifiers
        const fetchGlobaActionsIdentifiers = async () => {
          try {
            const result = await api.post(
              `api/dashboard/global-actions-identifiers`,
              {
                clientId,
                dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
                dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
                lastDate,
              }
            );
            setGlobalActionsIdentifiers(result.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchGlobaActionsIdentifiers();

        const fetchGlobaActionsIRR = async () => {
          try {
            const result = await api.post(`api/dashboard/global-actions-irr`, {
              clientId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            });
            setGlobalActionsIRR(result.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchGlobaActionsIRR();

        const fetchGlobaActionsCriteria = async () => {
          try {
            const result = await api.post(
              `api/dashboard/global-actions-criteria`,
              {
                clientId,
                dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
                dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
              }
            );
            setGlobalActionsCriteria(result.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchGlobaActionsCriteria();

        const fetchGlobaActionsCategory = async () => {
          try {
            const result = await api.post(
              `api/dashboard/global-actions-category`,
              {
                clientId,
                dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
                dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
              }
            );
            setGlobalActionsCategory(result.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchGlobaActionsCategory();
      }
    } else if (constructionId > 0) {
      // Construction identifiers
      const fetchConstructionName = async () => {
        try {
          const response = await api.get(
            `api/constructions/info/${constructionId}`
          );
          setManagerName(response.data.managerUserId.name);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionName();

      // Chart: Status de controle das ações
      const fetchConstructionRestrictionStatusControl = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-restrictions-status-control`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionRestrictionStatusControl(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionRestrictionStatusControl();

      // Chart: Indice de remoção de restrições (IRR)
      const fetchConstructionActionsIRR = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-actions-irr`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionActionsIRR(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionActionsIRR();

      // Chart: Critérios personalizados
      const fetchConstructionCustomCriteria = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-custom-criteria`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionCustomCriteria(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionCustomCriteria();

      // Chart: Metodologia dos 4 pilares da gestão de obras
      const fetchConstructionMethodology = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-methodology`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionMethodology(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionMethodology();

      // Chart: Prioridade das ações
      const fetchConstructionPriority = async () => {
        try {
          const result = await api.post(`api/dashboard/construction-priority`, {
            constructionId,
            dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
            dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
          });
          setConstructionPriority(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionPriority();

      // Chart: Critérios prioritários
      const fetchConstructionPriorityCriteria = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-priority-criteria`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionPriorityCriteria(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionPriorityCriteria();

      // Chart: Ações atrasadas por mês
      const fetchConstructionDaysOfDelay = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-days-of-delay`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionDaysOfDelay(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionDaysOfDelay();

      // Chart: Critérios das ações atrasadas
      const fetchConstructionLateCriteria = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-late-criteria`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionLateCriteria(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionLateCriteria();

      // Chart: Evolução das ações
      const fetchConstructionActionsEvolution = async () => {
        try {
          const result = await api.post(
            `api/dashboard/construction-actions-evolution`,
            {
              constructionId,
              dtBegin: dtBegin?.toLocaleString("fr-CA").substring(0, 10),
              dtEnd: dtEnd?.toLocaleString("fr-CA").substring(0, 10),
            }
          );
          setConstructionActionsEvolution(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionActionsEvolution();
    }
  }, [clientId, constructionId, dtBegin, dtEnd]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{section}</title>
      </Helmet>

      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item xs={8} lg={8} xl={8}>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
        {Number(constructionId) > 0 && (
          <Grid item xs={4} lg={4} xl={4} textAlign="right" mt={4}>
            <Typography variant="h5" gutterBottom>
              {`Engenheiro: ${
                managerName.length > 0 ? managerName : "Não cadastrado!"
              }`}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />

      {Number(constructionId) > 0 ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Evolução das ações
                </Typography>

                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <EvolutionChart
                      title=""
                      labels={
                        constructionActionsEvolution.labels
                          ? constructionActionsEvolution.labels
                          : []
                      }
                      labelsData={[
                        "Novas ações identificadas",
                        "Concluídas no mês",
                        "Total de Ações",
                        "Concluídas",
                      ]}
                      dataOne={
                        constructionActionsEvolution.newActions !== undefined
                          ? constructionActionsEvolution.newActions
                          : []
                      }
                      dataTwo={
                        constructionActionsEvolution.concludedMonthActions !==
                        undefined
                          ? constructionActionsEvolution.concludedMonthActions
                          : []
                      }
                      dataThree={
                        constructionActionsEvolution.totalActions !== undefined
                          ? constructionActionsEvolution.totalActions
                          : []
                      }
                      dataFour={
                        constructionActionsEvolution.concludedActions !==
                        undefined
                          ? constructionActionsEvolution.concludedActions
                          : []
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Indice de remoção de restrições (IRR)
                </Typography>

                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <ConstructionIRRChart
                      title=""
                      labels={
                        constructionActionsIRR.labels
                          ? constructionActionsIRR.labels
                          : []
                      }
                      labelsData={[
                        "Total de ações",
                        "Concluídas",
                        "IRR Mensal",
                        "IRR Global",
                      ]}
                      dataOne={
                        constructionActionsIRR.totalActions !== undefined
                          ? constructionActionsIRR.totalActions
                          : []
                      }
                      dataTwo={
                        constructionActionsIRR.concludedActions !== undefined
                          ? constructionActionsIRR.concludedActions
                          : []
                      }
                      dataThree={
                        constructionActionsIRR.monthIRR !== undefined
                          ? constructionActionsIRR.monthIRR
                          : []
                      }
                      dataFour={
                        constructionActionsIRR.globalIRR !== undefined
                          ? constructionActionsIRR.globalIRR
                          : []
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardContent>
                <ActionStatusControlHorizontalContainer>
                  <Typography variant="h4" gutterBottom>
                    Status de controle das ações
                  </Typography>

                  <Typography variant="h5" gutterBottom>
                    Mês: {dtEnd?.toLocaleString().substring(3, 10)}
                  </Typography>

                  <Typography variant="h5" gutterBottom>
                    Mês: {dtEnd?.toLocaleString().substring(3, 10)}
                  </Typography>
                </ActionStatusControlHorizontalContainer>

                <div></div>

                <Grid container spacing={6}>
                  <Grid item xs={12} md={7}>
                    <RestrictionStatusControlChart
                      labels={[
                        "Total ações",
                        "Concluídas",
                        "Para o mês",
                        "Concluídas no mês",
                        "Pendentes",
                      ]}
                      values={[
                        constructionRestrictionStatusControl.totalActions !==
                        undefined
                          ? constructionRestrictionStatusControl.totalActions
                          : 0,
                        constructionRestrictionStatusControl.concludedActions !==
                        undefined
                          ? constructionRestrictionStatusControl.concludedActions
                          : 0,
                        constructionRestrictionStatusControl.monthActions !==
                        undefined
                          ? constructionRestrictionStatusControl.monthActions
                          : 0,
                        constructionRestrictionStatusControl.concludedMonthActions !==
                        undefined
                          ? constructionRestrictionStatusControl.concludedMonthActions
                          : 0,
                        constructionRestrictionStatusControl.pendingActions !==
                        undefined
                          ? constructionRestrictionStatusControl.pendingActions
                          : 0,
                      ]}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={[{ borderLeft: { xs: 0, md: 1 } }]}
                  >
                    <RestrictionStatusControlLateChart
                      labels={["Atrasadas", "No prazo", "Futuras"]}
                      values={[
                        constructionRestrictionStatusControl.lateActions !==
                        undefined
                          ? constructionRestrictionStatusControl.lateActions
                          : 0,
                        constructionRestrictionStatusControl.ontimeActions !==
                        undefined
                          ? constructionRestrictionStatusControl.ontimeActions
                          : 0,
                        constructionRestrictionStatusControl.futureActions !==
                        undefined
                          ? constructionRestrictionStatusControl.futureActions
                          : 0,
                      ]}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom marginBottom={10}>
                  Análises das Ações
                </Typography>

                <Grid container spacing={6} mb={20}>
                  <Grid item xs={12} md={6}>
                    <ActionsAnalysisChart
                      title="Critérios personalizados"
                      labels={
                        constructionCustomCriteria.labels
                          ? constructionCustomCriteria.labels
                          : []
                      }
                      data={
                        constructionCustomCriteria.totalActions !== undefined
                          ? constructionCustomCriteria.totalActions
                          : []
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MethodologyChart
                      title="Metodologia dos 4 pilares da gestão de obras"
                      labels={
                        constructionMethodology.labels
                          ? constructionMethodology.labels
                          : []
                      }
                      data={
                        constructionMethodology.totalActions !== undefined
                          ? constructionMethodology.totalActions
                          : []
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} mb={20}>
                  <Grid item xs={12} md={6}>
                    <ActionsAnalysisDaysOfDelayChart
                      labels={
                        constructionDaysOfDelay.labels
                          ? constructionDaysOfDelay.labels
                          : []
                      }
                      values={
                        constructionDaysOfDelay.totalActions !== undefined
                          ? constructionDaysOfDelay.totalActions
                          : []
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PriorityActionsChart
                      title="Prioridade das ações"
                      data={
                        constructionPriority.totalActions !== undefined
                          ? constructionPriority.totalActions
                          : []
                      }
                      labels={
                        constructionPriority.labels
                          ? constructionPriority.labels
                          : []
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6} mb={20}>
                  <Grid item xs={12} md={6}>
                    <CriteriaChart
                      title="Critérios prioritários"
                      data={
                        constructionPriorityCriteria.totalActions !== undefined
                          ? constructionPriorityCriteria.totalActions
                          : []
                      }
                      labels={
                        constructionPriorityCriteria.labels
                          ? constructionPriorityCriteria.labels
                          : []
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CriteriaChart
                      title="Critérios das ações atrasadas"
                      data={
                        constructionLateCriteria.totalActions !== undefined
                          ? constructionLateCriteria.totalActions
                          : []
                      }
                      labels={
                        constructionLateCriteria.labels
                          ? constructionLateCriteria.labels
                          : []
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : globalActionsIdentifiers.totalActions > 0 ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Indicadores globais
                </Typography>
                <Grid container spacing={6} mb={40} paddingTop={10}>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={[{ borderRight: { xs: 0, md: 1 } }]}
                  >
                    <GlobalChart
                      title="Ações Global"
                      labels={[
                        "Total de ações",
                        "Novas ações",
                        "Total de ações concluídas",
                      ]}
                      values={[
                        globalActionsIdentifiers.actionsIdentified !== undefined
                          ? globalActionsIdentifiers.actionsIdentified
                          : 0,
                        globalActionsIdentifiers.newActionsIdentified !==
                        undefined
                          ? globalActionsIdentifiers.newActionsIdentified
                          : 0,
                        globalActionsIdentifiers.totalActions !== undefined
                          ? globalActionsIdentifiers.totalActions
                          : 0,
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <EvolutionChart
                      title="IRR Global"
                      labels={globalActionsIRR.labels}
                      labelsData={[
                        "Total de ações",
                        "Concluídas",
                        "IRR Mensal",
                        "IRR Global",
                      ]}
                      dataOne={
                        globalActionsIRR.totalActions !== undefined
                          ? globalActionsIRR.totalActions
                          : []
                      }
                      dataTwo={
                        globalActionsIRR.concludedActions !== undefined
                          ? globalActionsIRR.concludedActions
                          : []
                      }
                      dataThree={
                        globalActionsIRR.monthIRR !== undefined
                          ? globalActionsIRR.monthIRR
                          : []
                      }
                      dataFour={
                        globalActionsIRR.globalIRR !== undefined
                          ? globalActionsIRR.globalIRR
                          : []
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <GlobalCriteriaChart
                      title="Critérios"
                      data={
                        globalActionsCriteria.totalActions !== undefined
                          ? globalActionsCriteria.totalActions
                          : []
                      }
                      labels={
                        globalActionsCriteria.labels
                          ? globalActionsCriteria.labels
                          : []
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GlobalObjectiveChart
                      title="Objetivos"
                      labels={
                        globalActionsCategory.labels
                          ? globalActionsCategory.labels
                          : []
                      }
                      data={globalActionsCategory.totalActions}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} lg={12} xl={12}>
          <AlertNoRecords mb={4} severity="warning">
            Por favor, escolha um cliente para acesso aos indicadores.
          </AlertNoRecords>
        </Grid>
      )}
    </React.Fragment>
  );
};

const ActionStatusControlHorizontalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
`;

export default Dashboard;
