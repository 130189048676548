import { UserResponsible } from "../../../services/UserService";

export interface IActionModel {
  id: string;
  actionDescription: string;
  beginDate: Date;
  endDate: Date;
  status: boolean | null;
  observation?: string;
  how?: string;
  responsibles?: UserResponsible[];
  priority?: number;
  generatedByEmptyObject?: boolean;
}

export class ActionModel implements IActionModel {
  id: string;
  actionDescription: string;
  beginDate: Date;
  endDate: Date;
  status: boolean | null;
  observation?: string;
  how?: string | undefined;
  responsibles?: UserResponsible[];
  priority?: number | undefined;

  constructor(
    id: string,
    actionDescription: string,
    beginDate: Date,
    endDate: Date,
    status: boolean | null,
    observation?: string,
    how?: string | undefined,
    responsibles?: UserResponsible[],
    priority?: number | undefined
  ) {
    this.id = id;
    this.actionDescription = actionDescription;
    this.beginDate = beginDate;
    this.endDate = endDate;
    this.status = status;
    this.observation = observation;
    this.how = how;
    this.responsibles = responsibles;
    this.priority = priority;
  }

  static isValid(action: IActionModel): Boolean {
    return (
      action.actionDescription !== "" &&
      action.beginDate !== null &&
      action.endDate !== null
    );
  }

  static emptyObject(): IActionModel {
    return {
      id: Math.random().toString(),
      actionDescription: "",
      beginDate: new Date(),
      endDate: new Date(),
      status: null,
      observation: "",
      how: "",
      priority: undefined,
      generatedByEmptyObject: true,
    };
  }

  static parseFromEntityArray(actions: any[]): IActionModel[] {
    return actions.map((action) => {
      return this.parseFromEntity(action);
    });
  }

  static parseFromEntity(action: any): IActionModel {
    return {
      id: action.id,
      actionDescription: action.what,
      beginDate: new Date(action.whenBegin),
      endDate: new Date(action.whenEnd),
      status: action.status === true || action.whenRealEnd !== null,
      observation: action.comments,
      responsibles: action.responsibleUsersId.map((responsible: any) => {
        const userResponsible = {
          id: responsible.responsibleUserId,
          name: "",
        } as UserResponsible;
        return userResponsible;
      }),
      how: action.how,
      priority: action.priority,
    };
  }
}
