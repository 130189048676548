import styled from "styled-components";
import { Colors } from "../../theme/variants";
import LoadingLottieJson from "../../assets/loading.json";
import { useLottie } from "lottie-react";

//import Lottie from 'react-lottie';

interface Props {
  size?: number;
  fontSize?: number;
}

const LoadingComponent: React.FC<Props> = ({ size, fontSize }) => {
  const width = size ? size : 200;
  const height = size ? size : 200;

  const options = {
    animationData: LoadingLottieJson,
    loop: true,
    autoplay: true,
    style: { width, height },
  };

  const { View } = useLottie(options);

  return (
    <Container>
      {View}
      <LoadingText style={{ fontSize: fontSize ? fontSize + "px" : "14px" }}>
        Carregando
      </LoadingText>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  justify-content: center;
  align-content: center;
`;

const LoadingText = styled.div`
  color: ${Colors.primaryColor};
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 600;
  word-wrap: break-word;
  justify-self: center;
  margin-top: -24px;
`;

export default LoadingComponent;
