import React, { createContext, useState, useEffect, useContext } from "react";
import { AuthContextData, User, authorizedRoutes } from "../config/app-info";
import * as auth from "../services/auth";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../utils/crypto";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [avatarSidebar, setAvatarSidebar] = useState("");
  const navigate = useNavigate();
  const storageUser = sessionStorage.getItem("@Auth:user");

  useEffect(() => {
    function loadStorageData() {
      if (storageUser) {
        const userInfo = JSON.parse(decrypt(storageUser));
        setUser(userInfo);
        setAvatarSidebar(userInfo.avatar);
      }

      setLoading(false);
    }

    loadStorageData();
  }, [storageUser]);

  const signIn = async (login: string, password: string) => {
    const response = await auth.signIn(login, password);
    if (response.message === "OK") {
      const routesToCheck = authorizedRoutes[response.user.role];
      setUser(response.user);
      sessionStorage.setItem(
        "@Auth:user",
        encrypt(JSON.stringify(response.user))
      );
      let canAccessDashboard = false;
      routesToCheck.forEach((route) => {
        if ("/dashboard/".indexOf(route) !== -1) {
          canAccessDashboard = true;
        }
      });
      canAccessDashboard
        ? navigate("/dashboard/")
        : navigate(`/usuarios/editar/${response.user.id}/`);
    } else {
      setMessage(response.message);
    }
  };

  const signOut = () => {
    sessionStorage.clear();
    setMessage("");
    setUser({} as User);
  };

  return (
    <AuthContext.Provider
      value={{
        signed: user.id > 0,
        user,
        loading,
        message,
        signIn,
        signOut,
        avatarSidebar,
        setAvatarSidebar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
