import React from "react";
import styled from "styled-components";

interface ReactBaseComponentProps {
  children: React.ReactNode;
}

const ReactBaseComponent: React.FC<ReactBaseComponentProps> = ({
  children,
}) => {
  return <ReactBaseComponentContainer>{children}</ReactBaseComponentContainer>;
};

const ReactBaseComponentContainer = styled.div``;

export default ReactBaseComponent;
