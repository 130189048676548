import styled from "styled-components";
import {
  Autocomplete,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useState } from "react";
import IconButtonComponent from "../Button/IconButtonComponent";
import { ReactComponent as TrashIcon } from "../../assets/trash_without_border.svg";

export enum DropDownComponentStyle {
  OUTLINED = "outlined",
  TRANSPARENT = "transparent",
}

interface DropDownComponentProps {
  key: string;
  onSelectedValueChanged: (
    key: string,
    value: string,
    valueAddedByUser: boolean
  ) => void;
  values: any[];
  defaultValue?: number;
  style?: DropDownComponentStyle;
  selectedValue?: ItemType;
  disabled?: boolean;
  onDeletedCustomType?: (customTypeId: string) => void;
}

export interface ItemType {
  type: string;
  group: string;
  color: string;
  id: number;
  addByUser?: boolean;
}

const NEW_SERVICE_TEMP_ID = -19039;

const DropDownComponent = (props: DropDownComponentProps) => {
  const groupValues = (values: any[]): ItemType[] => {
    const listItens: ItemType[] = [];
    const groupValues: ItemType[] = [];

    for (const value of values) {
      const groupName = value.name;
      const color = value.color;

      for (const serviceType of value.services) {
        listItens.push({
          type: serviceType.name,
          id: serviceType.id,
          group: groupName,
          color: color,
          addByUser: serviceType.isAddByUser === true,
        });
      }
      listItens.push({
        type: "Novo serviço",
        id: -1,
        group: groupName,
        color: color,
      });
      // groupValues.push({
      //   type:"Grupo",
      //   id: value.id,
      //   group: groupName,
      //   color: color,
      // })
    }
    return listItens;
  };
  const [text, setText] = React.useState<string>("");
  const [value, setValue] = useState<ItemType[]>([
    {
      type: "",
      group: "",
      color: "",
      id: 0,
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<ItemType>({
    group: "",
    color: "",
    type: "",
    id: -2,
    addByUser: false,
  });
  const [deletedList, setDeletedList] = useState<ItemType[]>([]);

  React.useEffect(() => {
    setValue(groupValues(props.values));
  }, [props.values]);

  React.useEffect(() => {
    if (props.selectedValue) {
      setSelectedItem({
        group: props.selectedValue?.group || "",
        type: props.selectedValue?.type || "",
        color: props.selectedValue?.color || "",
        id: props.selectedValue?.id || 0,
      });
    }
  }, [props.selectedValue]);

  const handleInputChange = (newValue: string | ItemType | null) => {
    if (!newValue) {
      setSelectedItem({
        group: "",
        color: "",
        type: "",
        id: -2,
        addByUser: false,
      });
      return;
    }

    if (typeof newValue === "string") {
      return;
    }

    if (newValue.id === -1) {
      const newItem = {
        ...newValue,
        id: NEW_SERVICE_TEMP_ID,
        type: text,
      };
      value.push(newItem);
      setValue(value);
      setSelectedItem(newItem);
      props.onSelectedValueChanged(newItem.group, newItem.type, true);
      return;
    }

    setSelectedItem(newValue);
    props.onSelectedValueChanged(newValue.group, newValue.type, false);
    return;
  };

  const onInputValueChange = (text: string) => {
    setText(text);
  };

  const _filterOptions = createFilterOptions<ItemType>();

  const filterOptions = (options: any, state: any) => {
    const results = _filterOptions(options, state);
    const newServices = (options as Array<ItemType>).filter(
      (item) => item.id === -1
    );

    for (const newService of newServices) {
      if (
        results.find(
          (result) => result.id === -1 && result.group === newService.group
        ) === undefined
      ) {
        results.push(newService);
      }
    }

    return results;
  };

  const handleCustomItemTrashOnClick = (item: any) => {
    const newValues = value.filter((value) => value.id !== item.id);
    setValue(newValues);
    setDeletedList([...deletedList, item]);
    if (props.onDeletedCustomType !== undefined) {
      props.onDeletedCustomType(item.id);
    }
  };

  return (
    <Root>
      <Autocomplete
        id="service_type_selector"
        value={selectedItem}
        disabled={props.disabled === true}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : deletedList.find((item) => item.id === option.id) !== undefined
            ? ""
            : selectedItem?.type || option.type
        }
        fullWidth
        size="small"
        onChange={(e, data) => handleInputChange(data)}
        onInputChange={(event, value) => onInputValueChange(value)}
        options={value || []}
        groupBy={(option) => option.group}
        renderGroup={(option) => (
          <li key={option.key}>
            <HeaderGroup
              style={{
                backgroundColor: value.find(
                  (item) => item.group === option.group
                )?.color,
              }}
            >
              {option.group}
            </HeaderGroup>
            <div>{option.children}</div>
          </li>
        )}
        renderOption={(props, group) => (
          <CustomMenuItem
            {...props}
            key={group.type}
            autoFocus={false}
            value={group.type}
          >
            <CustomCircleIndicator style={{ background: `${group.color}` }} />

            <CustomMenuItemContainer>
              <CustomMenuItemText
                style={{ fontWeight: group.id === -1 ? 700 : 400 }}
              >
                {group.type}
              </CustomMenuItemText>
            </CustomMenuItemContainer>
            {group.addByUser === true && (
              <div style={{ marginBottom: "0px" }}>
                <IconButtonComponent
                  border={false}
                  onClick={() => handleCustomItemTrashOnClick(group)}
                >
                  <TrashIcon />
                </IconButtonComponent>
              </div>
            )}
          </CustomMenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Descrição de Serviço"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        filterOptions={filterOptions}
        filterSelectedOptions
        noOptionsText="Nenhuma opção encontrada"
        freeSolo
      />
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const ButtonDivTittle = styled.div`
  font-size: 16px;
  color: #333;
  padding: 10px;
  text-align: left;
`;

const HeaderGroup = styled.div`
  display: grid;
  height: 40px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #f8f9fc;
  padding-left: 24px;
  align-content: center;
`;

const CustomMenuItem = styled(MenuItem)`
  display: grid;
  height: 40px;
  color: #565757;
  padding-left: 16px;
  align-content: center;
  grid-template-columns: min-content 1fr min-content;
`;

const CustomCircleIndicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const CustomMenuItemContainer = styled.div`
  width: 100%;
`;

const CustomMenuItemText = styled.div`
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 16px;
`;

export default DropDownComponent;
