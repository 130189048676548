import { useEffect, useState } from "react";
import { useDashboard } from "../../../../contexts/dashboard";
import { BaseState } from "../../../Actions/ViewActions/utils/BaseState";
import ReactPageBaseComponent from "../../../../components/BaseComponents/ReactPageBaseComponent";
import {
  ActionsContainer,
  CenterContainer,
  ContentContainer,
  CreateNewOrderMobileButtonContainer,
  Description,
  EmptyStateText,
  GroupColorSymbol,
  PageContainer,
  StepContainer,
  StepOrderColumnText,
  StepOrderContainer,
  StepOrderText,
  StepsContainer,
  TitleContainer,
} from "./OrderOfSteps.styles";
import EmptyClientAndConstructionState from "../../../../components/StateComponents/EmptyClientAndConstruction";
import LoadingComponent from "../../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../../components/StateComponents/ErrorComponent";
import {
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import OrderOfStepModel from "./models/OrderOfStepModel";
import Title from "../../../../components/Typography/Tittle";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../../components/Button/ButtonComponent";
import VerticalService from "../../../../services/VeritcalService";
import VerticalServiceGroup from "../../../../services/interfaces/VerticalServiceGroup";
import OrderOfStepsFormPopUp from "./components/OrderOfStepsFormPopUp/OrderOfStepsFormPopUp";
import StepModel from "./models/StepModel";
import { ReactComponent as EditIcon } from "../../../../assets/edit-primary-color.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/trash-primary-color.svg";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/three_dots.svg";
import ConfirmDeleteItemPopUp from "../../../../components/Dialogs/ConfirmDeleteItemPopUp";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import { MoreItemsNumber } from "./components/OrderOfStepsFormPopUp/OrderOfStepsFormPopUp.styles";
import { AxiosError } from "axios";

enum ThreeDotsIconEnum {
  EDIT = "Editar",
  DELET = "Deletar",
}

interface OrderOfStepsProps {}

const OrderOfStepsPage: React.FC<OrderOfStepsProps> = () => {
  const { constructionId } = useDashboard();
  const verticalService = new VerticalService();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [orderOfSteps, setOrderOfSteps] = useState<OrderOfStepModel[]>([]);
  const [servicesGroups, setServicesGroup] = useState<VerticalServiceGroup[]>(
    []
  );
  const [orginServicesGroupsAvailable, setOriginServicesGroupAvailable] =
    useState<VerticalServiceGroup[]>([]);

  const [servicesGroupsAvailable, setServicesGroupAvailable] = useState<
    VerticalServiceGroup[]
  >([]);

  const [isOrderOfStepsFormPopUpOpen, setIsOrderOfStepsFormPopUpOpen] =
    useState<boolean>(false);
  const [isOrderOfStepsPopUpLoading, setIsOrderOfStepsPopUpLoading] =
    useState<boolean>(false);
  const [orderOfStepToDelete, setOrderOfStepToDelete] =
    useState<OrderOfStepModel>();
  const [selectedOrderOfStep, setSelectedOrderOfStep] =
    useState<OrderOfStepModel>();
  const [isMobile, setIsMobile] = useState<boolean>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const exportImportMenuIsOpen = Boolean(anchorEl);

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const registerNewOrder = () => {
    setIsOrderOfStepsFormPopUpOpen(true);
  };

  useEffect(() => {
    loadPageData();
  }, [constructionId]);

  const loadPageData = async () => {
    try {
      setState(BaseState.loading);
      await findServiceTypes(constructionId);
      await findOrderOfSteps(constructionId);
      setState(BaseState.success);
    } catch (error) {
      setState(BaseState.error);
    }
  };

  const findOrderOfSteps = async (constructionId?: number) => {
    if (constructionId === undefined || constructionId === null) {
      return;
    }

    try {
      const response = await verticalService.getOrderOfSteps(constructionId);
      const dataBridge = response.map((order) => {
        return {
          id: order.id,
          name: order.name,
          steps: order.steps.map((step) => {
            return {
              step: step.stepNumber,
              serviceGroup: step.verticalServiceGroup,
            };
          }),
        };
      });
      setOrderOfSteps(dataBridge);
    } catch (error) {
      throw error;
    }
  };

  const findServiceTypes = async (constructionId?: number) => {
    setState(BaseState.loading);
    try {
      const response = await verticalService.getServiceTypes(constructionId);
      const serviceTypesAvailable = await verticalService.getServiceTypes(
        constructionId,
        true
      );
      setServicesGroup(response);
      setServicesGroupAvailable(serviceTypesAvailable);
      setOriginServicesGroupAvailable(serviceTypesAvailable);
    } catch (error) {
      throw error;
    }
  };

  const createOrEdit = async (
    name: string,
    steps: StepModel[],
    id?: number
  ) => {
    setIsOrderOfStepsPopUpLoading(true);
    try {
      if (selectedOrderOfStep === undefined) {
        createOrderOfSteps(name, steps);
      } else {
        editOrderOfSteps(selectedOrderOfStep.id!, name, steps);
      }
    } catch (error) {
      setSnackbarMessage("Algum erro aconteceu");
      setOpenSnackbar(true);
    }
  };

  const createOrderOfSteps = async (name: string, steps: StepModel[]) => {
    try {
      await verticalService.createOrderOfSteps(
        constructionId!,
        name,
        steps.map((step) => {
          return {
            groupId: step.serviceGroup.id,
            position: step.step,
          };
        })
      );
      setSnackbarMessage("Ordem de etapas cadastrada com sucesso");
      setIsOrderOfStepsPopUpLoading(false);
      setIsOrderOfStepsFormPopUpOpen(false);
      setOpenSnackbar(true);
      loadPageData();
    } catch (error) {
      setSnackbarMessage("Erro ao cadastrar ordem de etapas");
      setOpenSnackbar(true);
    }
  };

  const editOrderOfSteps = async (
    id: number,
    name: string,
    steps: StepModel[]
  ) => {
    verticalService
      .updateOrderOfSteps(
        constructionId!,
        id,
        name,
        steps.map((step) => {
          return {
            groupId: step.serviceGroup.id,
            position: step.step,
          };
        })
      )
      .then(() => {
        setSnackbarMessage("Ordem de etapas Atualizada com sucesso");
        setOpenSnackbar(true);
        setIsOrderOfStepsPopUpLoading(false);
        setIsOrderOfStepsFormPopUpOpen(false);
        setSelectedOrderOfStep(undefined);
        loadPageData();
      })
      .catch((error: AxiosError) => {
        setSnackbarMessage("Erro ao cadastrar ordem de etapas");
        setOpenSnackbar(true);
      });
  };

  const selectItemToDelete = (orderOfStep: OrderOfStepModel) => {
    setOrderOfStepToDelete(orderOfStep);
  };

  const deleteOrderOfSteps = async (id: number) => {
    try {
      setIsOrderOfStepsPopUpLoading(true);
      await verticalService.deleteOrderOfSteps(constructionId!, id);
      setSnackbarMessage("Ordem de etapas excluída com sucesso");
      setIsOrderOfStepsPopUpLoading(false);
      setOrderOfStepToDelete(undefined);
      setOpenSnackbar(true);
      cancelDeleteStep();
      loadPageData();
    } catch (error) {
      setSnackbarMessage("Erro ao excluir ordem de etapas");
      setOpenSnackbar(true);
    }
  };

  const cancelDeleteStep = () => {
    setOrderOfStepToDelete(undefined);
    setSelectedOrderOfStep(undefined);
  };

  const selectOrderOfStep = (orderOfStep: OrderOfStepModel) => {
    setSelectedOrderOfStep(orderOfStep);
    setIsOrderOfStepsFormPopUpOpen(true);
    const copy = [...servicesGroupsAvailable];

    orderOfStep.steps.forEach((step) => {
      copy.push(step.serviceGroup);
    });

    setServicesGroupAvailable(copy);
  };

  const cancelPopUp = () => {
    setSelectedOrderOfStep(undefined);
    setIsOrderOfStepsFormPopUpOpen(false);
    setServicesGroupAvailable(orginServicesGroupsAvailable);
  };

  const handleOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionsMenuClose = (
    option: ThreeDotsIconEnum,
    orderOfStep: OrderOfStepModel
  ) => {
    if (option === ThreeDotsIconEnum.DELET) {
      selectItemToDelete(orderOfStep);
    }

    if (option === ThreeDotsIconEnum.EDIT) {
      selectOrderOfStep(orderOfStep);
    }

    setAnchorEl(null);
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="as ordem das etapas" />{" "}
          </CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <ContentContainer>
            <TitleContainer>
              <Title style={{ marginTop: "4px" }}>Ordem de etapas</Title>
              {!isMobile && (
                <ButtonComponent
                  text="Cadastrar nova ordem"
                  type={ButtonType.PRIMARY}
                  state={
                    servicesGroupsAvailable.length === 0
                      ? ButtonState.DISABLED
                      : ButtonState.DEFAULT_ENABLED
                  }
                  onClick={registerNewOrder}
                />
              )}
            </TitleContainer>
            <Description>
              Cadastre e configure a ordem das etapas para cada situação na sua
              obra.
            </Description>
            <StepsContainer>
              <StepContainer>
                <StepOrderColumnText>Nome da ordem</StepOrderColumnText>
                <StepOrderColumnText>
                  Preview da ordem de etapas
                </StepOrderColumnText>
                <StepOrderColumnText>Ações</StepOrderColumnText>
              </StepContainer>
              {orderOfSteps.map((orderOfStep, index) => {
                return (
                  <StepContainer key={index} border>
                    <StepOrderText> {orderOfStep.name}</StepOrderText>
                    <StepOrderContainer>
                      {!isMobile &&
                        orderOfStep.steps.map((step, index) => {
                          return (
                            <GroupColorSymbol
                              key={index}
                              color={step.serviceGroup.color}
                            />
                          );
                        })}

                      {isMobile &&
                        orderOfStep.steps.slice(0, 4).map((step, index) => {
                          return (
                            <GroupColorSymbol
                              key={index}
                              color={step.serviceGroup.color}
                            />
                          );
                        })}

                      {isMobile && orderOfStep.steps.length > 4 && (
                        <MoreItemsNumber>
                          +{orderOfStep.steps.length - 4}
                        </MoreItemsNumber>
                      )}
                    </StepOrderContainer>
                    {!isMobile && (
                      <ActionsContainer>
                        <IconButton
                          sx={{ height: "40px", width: "40px" }}
                          onClick={() => selectOrderOfStep(orderOfStep)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{ height: "40px", width: "40px" }}
                          onClick={() => selectItemToDelete(orderOfStep)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ActionsContainer>
                    )}

                    {isMobile && (
                      <IconButton
                        sx={{ height: "40px", width: "40px" }}
                        onClick={(e) => handleOptionsMenuClick(e)}
                        aria-controls={
                          exportImportMenuIsOpen ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          exportImportMenuIsOpen ? "true" : undefined
                        }
                      >
                        <ThreeDotsIcon />
                      </IconButton>
                    )}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={exportImportMenuIsOpen}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          handleOptionsMenuClose(
                            ThreeDotsIconEnum.EDIT,
                            orderOfStep
                          )
                        }
                      >
                        Editar ordem de etapas
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleOptionsMenuClose(
                            ThreeDotsIconEnum.DELET,
                            orderOfStep
                          )
                        }
                      >
                        Deletar ordem de etapas
                      </MenuItem>
                    </Menu>
                  </StepContainer>
                );
              })}

              {orderOfSteps.length === 0 ? (
                // <CenterContainer>
                <EmptyStateText>
                  Não existe nenhuma ordem de etapas cadastrada
                </EmptyStateText>
              ) : // </CenterContainer>
              null}

              {isMobile && (
                <CreateNewOrderMobileButtonContainer>
                  <ButtonComponent
                    text="Cadastrar nova ordem"
                    type={ButtonType.PRIMARY}
                    state={
                      servicesGroupsAvailable.length === 0
                        ? ButtonState.DISABLED
                        : ButtonState.DEFAULT_ENABLED
                    }
                    onClick={registerNewOrder}
                  />
                </CreateNewOrderMobileButtonContainer>
              )}
            </StepsContainer>
          </ContentContainer>
        ) : null}
      </PageContainer>
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
      <OrderOfStepsFormPopUp
        isOpen={isOrderOfStepsFormPopUpOpen}
        isLoading={isOrderOfStepsPopUpLoading}
        onClose={() => cancelPopUp()}
        verticalServiceGroups={
          selectedOrderOfStep ? servicesGroups : servicesGroupsAvailable
        }
        isMobile={isMobile}
        verticalServiceGroupsAvailable={servicesGroupsAvailable}
        orderOfStep={selectedOrderOfStep}
        cancelOnClick={() => cancelPopUp()}
        onConfirm={(name, steps) => createOrEdit(name, steps)}
      />
      <ConfirmDeleteItemPopUp
        title="Excluir ordem de etapas"
        description="Deseja realmente excluir essa ordem de etapas?"
        openDeleteActionDialog={orderOfStepToDelete !== undefined}
        isLoadingDialog={isOrderOfStepsPopUpLoading}
        dialogButtonCancelOnClick={() => cancelDeleteStep()}
        dialogButtonConfirmOnClick={() =>
          deleteOrderOfSteps(orderOfStepToDelete?.id!)
        }
      />
    </ReactPageBaseComponent>
  );
};

export default OrderOfStepsPage;
