import styled from "styled-components";
import { Colors } from "../../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 143px 140px 140px 140px 140px 1fr 78px;
  height: 54px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 169px 143px 140px 140px 140px 140px 169px 78px;

    height: 48px;
  }
`;

const CellContainer = styled.div<{ isVisible?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Colors.neutralColorLight};
  background-color: ${Colors.neutralColorWhite};
  height: 54px;
  /* visibility: ${(props) => (props.isVisible ? "visible" : "hidden")}; */
  padding-left: 4px;
  padding-right: 4px;

  // set ellipsis if the text is too long
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 48px;
  }
`;

const CellText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  color: ${Colors.textColorBlack};
  text-align: center;
`;

const TagPhase = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${Colors.textColorLight};
  background-color: ${Colors.textColorGray};
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 100px;
`;

const TagStatusBase = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 100px;
`;

const TagInNegotiation = styled(TagStatusBase)`
  color: ${Colors.textColorLight};
  background-color: ${Colors.feedbackSuccess};
`;

const TagFinished = styled(TagStatusBase)`
  color: ${Colors.neutralColorWhite};
  background-color: ${Colors.primaryColor};
`;

const TagNotStarted = styled(TagStatusBase)`
  color: ${Colors.textColorGray};
  background-color: ${Colors.attentionColor};
`;

const TagOverdue = styled(TagStatusBase)`
  color: ${Colors.textColorLight};
  background-color: ${Colors.feedbackError};
`;

const TagNotCompleted = styled(TagStatusBase)`
  color: ${Colors.textColorLight};
  background-color: ${Colors.textColorGray};
`;

const MouseHoverContainer = styled.div`
  position: relative;
  margin-left: 32px;
  margin-top: -75px;
  z-index: 100;
`;

export {
  ContentContainer,
  CellContainer,
  CellText,
  TagPhase,
  TagInNegotiation,
  TagNotStarted,
  TagOverdue,
  TagNotCompleted,
  MouseHoverContainer,
  TagFinished,
};
