import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
//import { chartColors } from "../../../config/app-info";

type Props = {
  title: string;
  labels: string[];
  labelsData: string[];
  dataOne: number[];
  dataTwo: number[];
  dataThree: number[];
  dataFour: number[];
};
const ConstructionIRRChart: React.FC<Props> = ({
  title,
  labels,
  labelsData,
  dataOne,
  dataTwo,
  dataThree,
  dataFour,
}) => {
  const data = [
    {
      name: labelsData[0],
      type: "column",
      data: dataOne,
    },
    {
      name: labelsData[1],
      type: "column",
      data: dataTwo,
    },
    {
      name: labelsData[2],
      type: "line",
      data: dataThree,
    },
    {
      name: labelsData[3],
      type: "line",
      data: dataFour,
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return `${w.config.series[seriesIndex].data[dataPointIndex]}`;
      },
    },
    stroke: {
      width: [0, 0, 2, 2],
      curve: "straight",
    },
    title: {
      text: title,
      align: "center",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    fill: {
      opacity: [1, 1, 1, 1],
      /*gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [100], 100, 100, 100],
      },*/
    },
    labels,
    markers: {
      size: 0,
    },
    xaxis: {
      type: "category",
    },
    yaxis: [
      {
        title: {
          text: "",
        },
      },
      /*{
        opposite: true,
        title: {
          text: "%",
        },
        max: 100,
      },*/
    ],
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
    colors: ["#4169E1", "#32CD32", "#A9A9A9", "#FFD700"],
  };

  return <Chart options={options} series={data} type="line" height="350" />;
};

export default ConstructionIRRChart;
