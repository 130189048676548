import styled from "styled-components";
import { Colors } from "../../theme/variants";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../Button/ButtonComponent";
import { useLottie } from "lottie-react";
import LoadingLottieJson from "../../assets/loading.json";
import Dialog from "@mui/material/Dialog";

interface ConfirmDeleteItemPopUpProps {
  title: string;
  description: string;
  openDeleteActionDialog: boolean;
  isLoadingDialog: boolean;
  dialogButtonCancelOnClick?: () => void;
  dialogButtonConfirmOnClick?: () => void;
}

const options = {
  animationData: LoadingLottieJson,
  loop: true,
  autoplay: true,
  style: { width: 120, height: 120 },
};

const ConfirmDeleteItemPopUp: React.FC<ConfirmDeleteItemPopUpProps> = ({
  title,
  description,
  openDeleteActionDialog,
  isLoadingDialog,
  dialogButtonCancelOnClick,
  dialogButtonConfirmOnClick,
}) => {
  const { View } = useLottie(options);

  return (
    <BootstrapDialog open={openDeleteActionDialog}>
      {!isLoadingDialog ? (
        <DialogContainer>
          <DialogTitle>{title}</DialogTitle>

          <DialogDescription>{description}</DialogDescription>

          <ButtonContainer>
            <ButtonComponent
              text="Cancelar"
              type={ButtonType.TRANSPARENT}
              onClick={dialogButtonCancelOnClick}
            />
            <ButtonComponent
              state={ButtonState.DEFAULT_ENABLED}
              text="Sim, excluir"
              onClick={dialogButtonConfirmOnClick}
            />
          </ButtonContainer>
        </DialogContainer>
      ) : null}
      {isLoadingDialog === true ? (
        <LoadingDialogContainer>{View}</LoadingDialogContainer>
      ) : null}
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const DialogContainer = styled.div`
  display: grid;
  width: 301px;
  height: 216px;
  background: white;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  padding-bottom: 24px;
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 301px;
  height: 216px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const DialogTitle = styled.div`
  color: ${Colors.primaryColor};
  font-size: 24px;
  font-family: "Noto Sans";
  font-weight: 700;

  word-wrap: break-word;
`;

const DialogDescription = styled.div`
  color: ${Colors.primaryColor};
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  gap: 24px;
`;

export default ConfirmDeleteItemPopUp;
