import { UnitValues } from "../utils";

export class UpdateServiceProgressModel {
  // service: VerticalServiceModel;
  public constructionId?: number = undefined;
  public currentPhysicalProgress: number = 0;
  public currentFinancialProgress: number = 0;
  public progressByValue?: number = undefined;
  public progressByValueAsPercent?: number = 0;

  public materialCost?: number = undefined;
  public materialCostWithMask: string = "";
  public workforceCost?: number = undefined;
  public workforceCostWithMask: string = "";
  public unity: string = UnitValues[0];

  static isValid(data: UpdateServiceProgressModel): boolean {
    try {
      // Check if the required fields are present
      if (!data.unity) {
        return false;
      }

      if (!data.materialCost && !data.workforceCost && !data.progressByValue) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }
}
