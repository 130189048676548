import { Dialog, MenuItem, Tab } from "@mui/material";
import styled from "styled-components";
import { Colors } from "../../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import { width } from "@mui/system";

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 825px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
`;

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh);
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  position: relative;
  align-content: start;
`;

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const RootContainer = styled.div`
  min-width: 320px;
  max-width: 825px;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content min-content min-content;
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 825px;
  height: 369px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-y: auto;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding-right: 30%;
    padding-left: 32px;
    margin-top: 64px;
    max-height: calc(100vh - 64px);
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-right: 16px;
    padding-left: 16px;

    max-height: calc(100vh - 64px);
    padding-top: 80px;
    padding-bottom: 32px;
  }
`;

const FormDescriptionText = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: -20px;
  }
`;

const ChooseFormContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: max-content max-content max-content;
  gap: 16px;
  margin-top: -16px;
`;

const LocationFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const StartAndEndDateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const HeaderContainer = styled.div<{ isMobile?: boolean }>`
  display: grid;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: ${(props) =>
    props.isMobile === true ? "none" : `2px solid ${Colors.primaryColor};`};
  grid-template-columns: 1fr auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
    margin-right: 16px;
  }
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 24px;
  }
`;

const PurchasingAndContractionContainer = styled.div``;

const TabContainer = styled.div`
  margin-top: 8px;
`;

const ListPurchasingContractionContainer = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const PurchasingForm = styled.form`
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  gap: 24px;
  margin-top: 24px;
`;

const PurchasingFormRowOne = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 152px 152px;
    gap: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content max-content;
    gap: 16px;
  }
`;
const BuilderAndSupplierContainer = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 100px 1fr 1fr;
    gap: 16px;
    align-items: center;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content max-content;
    gap: 16px;
  }
`;

const BuilderAndSupplierContainerDescription = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  color: ${Colors.textColorBlack};
`;

const ContractingForm = styled.form`
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  gap: 24px;
  margin-top: 24px;
`;

const ResponsibleContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  gap: 16px;
  margin-top: 32px;
`;

const ResponsibleContainerTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  color: ${Colors.textColorBlack};
`;

const ResponsibleRow = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 206px 1fr;
    gap: 16px;
    align-items: center;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
    gap: 8px;
    margin-top: 4px;
  }
`;

const ResponsibleRowDesctipion = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 21.79px;
  color: ${Colors.textColorBlack};
`;

const AutoCompleteCustomMenuItem = styled(MenuItem)`
  display: flex;
  height: 40px;
  color: #565757;
  padding-left: 16px;
  flex-direction: row;
`;

const CustomMenuItemText = styled.div`
  font-family: "Noto Sans";
  width: 230px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 32px;
`;

const SelectedTag = styled.div`
  display: flex;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 2px;

  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const SelectedTagText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #333;
`;

export {
  BootstrapDialog,
  PageContainer,
  CenterContainer,
  RootContainer,
  LoadingDialogContainer,
  HeaderContainer,
  DialogTitle,
  ContentContainer,
  FormDescriptionText,
  ChooseFormContainer,
  LocationFormContainer,
  PurchasingAndContractionContainer,
  TabContainer,
  ListPurchasingContractionContainer,
  CustomTab,
  PurchasingForm,
  PurchasingFormRowOne,
  BuilderAndSupplierContainer,
  BuilderAndSupplierContainerDescription,
  ContractingForm,
  ResponsibleContainerTitle,
  ResponsibleContainer,
  ResponsibleRow,
  ResponsibleRowDesctipion,
  AutoCompleteCustomMenuItem,
  CustomMenuItemText,
  ButtonContainer,
  StartAndEndDateContainer,
  SelectedTag,
  SelectedTagText,
};
