import React, { useEffect, useImperativeHandle, useState } from "react";
import ReactBaseComponent from "../../../../components/BaseComponents/ReactBaseComponent";
import {
  AutoCompleteCustomMenuItem,
  BootstrapDialog,
  BuilderAndSupplierContainer,
  BuilderAndSupplierContainerDescription,
  ButtonContainer,
  CenterContainer,
  ChooseFormContainer,
  ContentContainer,
  ContractingForm,
  CustomMenuItemText,
  CustomTab,
  DialogTitle,
  FormDescriptionText,
  HeaderContainer,
  LocationFormContainer,
  PageContainer,
  PurchasingAndContractionContainer,
  PurchasingForm,
  PurchasingFormRowOne,
  ResponsibleContainer,
  ResponsibleContainerTitle,
  ResponsibleRow,
  ResponsibleRowDesctipion,
  SelectedTag,
  SelectedTagText,
  StartAndEndDateContainer,
  TabContainer,
} from "./PurchasingAndContractingPlanForm.styles";
import PopUpBaseComponent from "../../../../components/PopUps/PopUpBaseComponent";
import CustomDropDownComponent, {
  ItemType,
} from "../../../../components/InputValues/CustomDropDownComponent";
import InputDateComponent from "../../../../components/InputValues/input-date/InputDateComponent";
import {
  Autocomplete,
  Checkbox,
  Snackbar,
  Tabs,
  TextField,
} from "@mui/material";
import { PurchasingContractingENUM } from "../../models/PurchasingContractingENUM";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import InputValueComponent from "../../../../components/InputValues/InputValueComponent";
import { ReactComponent as InfoIcon } from "../../../../assets/info-icon.svg";
import ReactPageBaseComponent from "../../../../components/BaseComponents/ReactPageBaseComponent";
import { BaseState } from "../../../Actions/ViewActions/utils/BaseState";
import { useDashboard } from "../../../../contexts/dashboard";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import EmptyClientAndConstructionState from "../../../../components/StateComponents/EmptyClientAndConstruction";
import LoadingComponent from "../../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../../components/StateComponents/ErrorComponent";
import VerticalService from "../../../../services/VeritcalService";
import { set } from "date-fns";
import VerticalServiceModel from "../../../../services/interfaces/VerticalServiceModel";
import DropDownComponent from "../../../../components/InputValues/DropDownComponent";
import UserService, { UserResponsible } from "../../../../services/UserService";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../../components/Button/ButtonComponent";
import PurchasingContractingPlanService from "../../../../services/purchasingContractingPlan/PurchasingContractingPlanService";
import PurchasingModel, {
  parsePurchasingEntityToModel,
} from "../../models/PurchasingModel";
import ContractingModel, {
  parseContractingEntityToModel,
} from "../../models/ContractingMode";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PURCHASING_CONTRACTING_PLAN_PATH,
  User,
} from "../../../../config/app-info";

interface PurchasingAndContractingPlanFormProps {}

export interface PurchasingAndContractingPlanFormRef {
  cleanData: () => void;
}

export enum PurchasingContractingPlanFormType {
  CREATE = "CREATE",
  EDIT_PURCHASING = "EDIT_PURCHASING",
  EDIT_CONTRACTING = "EDIT_CONTRACTING",
}

/*
  const AddServicePopUp = React.forwardRef<
  AddServicePopUpRef | undefined,
  AddServicePopUpProps
>(({ ...props }, ref) => {
*/

const yupSchema = Yup.object().shape({
  product: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.PURCHASING,
    then: Yup.string()
      .required("Campo obrigatório")
      .min(2, "Mínimo de 2 caracteres"),
  }),
  quantity: Yup.number().when("selectedTab", {
    is: PurchasingContractingENUM.PURCHASING,
    then: Yup.number()
      .required("Campo obrigatório")
      .required("Campo obrigatório"),
  }),
  unit: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.PURCHASING,
    then: Yup.string()
      .required("Campo obrigatório")
      .min(1, "Mínimo de 1 caractere"),
  }),
  purchasingSpecifications: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.PURCHASING,
    then: Yup.string()
      .required("Campo obrigatório")
      .min(1, "Mínimo de 1 caractere"),
  }),
  service: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.CONTRACTING,
    then: Yup.string().min(1, "Mínimo de 1 caractere"),
  }),
  contractingSpecifications: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.CONTRACTING,
    then: Yup.string().min(1, "Mínimo de 1 caractere"),
  }),
});

const PurchasingAndContractingPlanForm = React.forwardRef<
  PurchasingAndContractingPlanFormRef | undefined,
  PurchasingAndContractingPlanFormProps
>(({ ...props }, ref) => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { constructionId } = useDashboard();
  const verticalService = new VerticalService();
  const userService = new UserService();
  const purchasingContractinService = new PurchasingContractingPlanService();

  const [state, setState] = useState<BaseState>(BaseState.success);
  const [formType, setFormType] = useState<PurchasingContractingPlanFormType>(
    searchParams.get("type") === null || searchParams.get("type") === undefined
      ? PurchasingContractingPlanFormType.CREATE
      : searchParams.get("type") === PurchasingContractingENUM.PURCHASING
      ? PurchasingContractingPlanFormType.EDIT_PURCHASING
      : PurchasingContractingPlanFormType.EDIT_CONTRACTING
  );

  const [purchasingSelectedPlan, setPurchasingSelectedPlan] = useState<
    PurchasingModel | undefined
  >();

  const [contractingSelectedPlan, setContractingSelectedPlan] = useState<
    ContractingModel | undefined
  >();

  const [selectedServiceType, setSelectedServiceType] = useState<ItemType>();

  const [servicesTypes, setServicesTypes] = useState<any[]>([]);
  const [verticalServices, setVerticalServices] = useState<
    VerticalServiceModel[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<
    VerticalServiceModel[]
  >([]);
  const [responsibleUsers, setResponsibleUsers] = useState<UserResponsible[]>(
    []
  );
  const [cotationResponsible, setCotationResponsible] = useState<string>("");
  const [approvalResponsible, setApprovalResponsible] = useState<string>("");
  const [purchasingResponsible, setPurchasingResponsible] =
    useState<string>("");
  const [approveReceiptResponsible, setApproveReceiptResponsible] =
    useState<string>("");

  const [contractingCotationResponsible, setContractingCotationResponsible] =
    useState<string>("");
  const [contractingApprovalResponsible, setContractingApprovalResponsible] =
    useState<string>("");
  const [
    contractingPurchasingResponsible,
    setContractingPurchasingResponsible,
  ] = useState<string>("");
  const [
    contractingApproveReceiptResponsible,
    setContractingApproveReceiptResponsible,
  ] = useState<string>("");

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [firstContructionId, setFirstContructionId] = useState<number>();

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {}, []);

  const goToList = () => {
    navigation(PURCHASING_CONTRACTING_PLAN_PATH);
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  useImperativeHandle(ref, () => ({
    cleanData: () => {
      cleanData();
    },
  }));

  useEffect(() => {
    // if (currentConstructionId === undefined) {
    //   setCurrentConstructionId(constructionId);
    // }

    try {
      setState(BaseState.loading);

      const id = searchParams.get("id");
      const type = searchParams.get("type");

      setFirstContructionId(constructionId);
      if (constructionId !== undefined && constructionId !== null) {
        setFirstContructionId(constructionId);
      }

      if (isConstructionIdChanged()) {
        goToList();
      }

      function isConstructionIdChanged() {
        return (
          constructionId !== undefined &&
          firstContructionId !== undefined &&
          constructionId !== firstContructionId
        );
      }

      loadPageData(id, type);
    } catch (error) {
      setState(BaseState.error);
    }
  }, [constructionId]);

  const cleanData = () => {};

  const loadPageData = async (id: string | null, type: string | null) => {
    const verticalServiceId = searchParams.get("verticalServiceId");
    let selectedPlan: PurchasingModel | ContractingModel | undefined;
    if (id && type) {
      selectedPlan = await findPlanById(Number(id), type || "");
    }

    let selectedVerticalService = undefined;

    if (verticalServiceId) {
      selectedVerticalService = await verticalService.getVerticalServiceById(
        constructionId,
        Number(verticalServiceId)
      );
      setSelectedServices([selectedVerticalService]);
    }

    findServiceTypes(
      constructionId,
      selectedPlan?.services[0]?.verticalServiceTypeId ||
        selectedVerticalService?.verticalServiceTypeId
    );
    findUserResponsibles(constructionId);

    setState(BaseState.success);
  };

  const findPlanById = async (
    id: number,
    type: string
  ): Promise<PurchasingModel | ContractingModel | undefined> => {
    try {
      if (id !== undefined && type === PurchasingContractingENUM.PURCHASING) {
        setFormType(PurchasingContractingPlanFormType.EDIT_PURCHASING);
        const response =
          await purchasingContractinService.getPurchasingPlanById(id);
        const model = parsePurchasingEntityToModel(response);
        setPurchasingSelectedPlan(model);
        setSelectedServices(model.services);
        setCotationResponsible(model.supplierQuotatioResponsable?.name || "");
        setApprovalResponsible(model.approveResponsable?.name || "");
        setPurchasingResponsible(model.purchasingResponsable?.name || "");
        setApproveReceiptResponsible(model.finishPlanResponsable?.name || "");
        return model;
      }

      if (id !== undefined && type === PurchasingContractingENUM.CONTRACTING) {
        setFormType(PurchasingContractingPlanFormType.EDIT_CONTRACTING);
        const response =
          await purchasingContractinService.getContractingPlanById(id);
        const model = parseContractingEntityToModel(response);
        setContractingSelectedPlan(model);
        setSelectedServices(model.services);
        setContractingCotationResponsible(
          model.supplierQuotatioResponsable?.name || ""
        );
        setContractingApprovalResponsible(model.approveResponsable?.name || "");
        setContractingPurchasingResponsible(
          model.purchasingResponsable?.name || ""
        );
        setContractingApproveReceiptResponsible(
          model.finishPlanResponsable?.name || ""
        );
        return model;
      }

      return undefined;
    } catch (error) {
      setState(BaseState.error);
      return undefined;
    }
  };

  const findServiceTypes = async (
    constructionId?: number,
    selectedServicesTypeId?: number
  ) => {
    const response = await verticalService.getServiceTypes(
      constructionId,
      false,
      true
    );
    setServicesTypes(response);

    if (selectedServicesTypeId) {
      response.find((serviceGroup) => {
        const service = serviceGroup.services.find(
          (serviceType: any) => serviceType.id === selectedServicesTypeId
        );

        if (service) {
          setSelectedServiceType({
            group: serviceGroup.name,
            color: serviceGroup.color,
            id: service.id,
            type: service.name,
            addByUser: service.isAddByUser === true,
          });
        }
      });
    }
  };

  const findUserResponsibles = async (constructionId: number) => {
    userService
      .fetchResponsibleUsers(constructionId.toString())
      .then((data) => {
        setResponsibleUsers(data);
      });
  };

  const findVerticalServicesByServiceType = async (serviceTypeId: number) => {
    try {
      const response = await verticalService.findVerticalServicesByFilters(
        constructionId,
        serviceTypeId
      );
      setVerticalServices(response);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelectedService = (key: any, value: any) => {
    try {
      const serviceGroup = servicesTypes.find(
        (service) => service.name === key
      );
      const serviceType = serviceGroup.services.find(
        (service: any) => service.name === value
      );
      setSelectedServices([]);
      setVerticalServices([]);
      findVerticalServicesByServiceType(serviceType.id);
    } catch (error) {
      setSnackbarMessage("Erro ao selecionar o serviço");
      setOpenSnackbar(true);
    }
  };

  const onSelectedVerticalService = (
    verticalService: VerticalServiceModel[] | null
  ) => {
    if (!verticalService) {
      return;
    }

    verticalService.sort((a, b) => {
      if (!a.beginDate) {
        return 0;
      }

      if (!b.beginDate) {
        return 0;
      }

      if (a.beginDate < b.beginDate) {
        return -1;
      }
      if (a.beginDate > b.beginDate) {
        return 1;
      }

      return 0;
    });
    setSelectedServices(verticalService);
  };

  const handleSubmit = async (values: any) => {
    if (formType === PurchasingContractingPlanFormType.CREATE) {
      createNewPurchasingOrContractingPlan(values);
    } else {
      editPurchasingOrContractingPlan(values);
    }
  };

  async function createNewPurchasingOrContractingPlan(values: any) {
    try {
      setState(BaseState.loading);
      const ids: number[] = [];

      selectedServices.forEach((service) => {
        if (service.id !== undefined) {
          ids.push(service.id);
        }
      });

      if (values.product !== "") {
        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === cotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approvalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === purchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approveReceiptResponsible
        )?.id;

        await purchasingContractinService.savePurchasingPlan(constructionId, {
          name: values.product,
          quantity: values.quantity,
          unit: values.unit,
          specifications: values.purchasingSpecifications,
          supplierQuotationDays: values.purchasingSupplierQuotationDays,
          purchasingLimitDays: values.purchasingDays,
          deliveryLimitDays: values.purchasingDeliveryDays,
          safetyDays: values.purchasingSecurityDays,
          serviceIds: ids,
          supplierQuotatioResponsableId: supplierQuotatioResponsableId
            ? Number(supplierQuotatioResponsableId)
            : undefined,
          approveResponsableId: approvalResponsibleId
            ? Number(approvalResponsibleId)
            : undefined,
          purchasingResponsableId: purchasingResponsableId
            ? Number(purchasingResponsableId)
            : undefined,
          approveReceiptResponsibleId: approveReceiptResponsibleId
            ? Number(approveReceiptResponsibleId)
            : undefined,
        });
      }

      if (values.service !== "") {
        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingCotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApprovalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingPurchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApproveReceiptResponsible
        )?.id;

        await purchasingContractinService.saveContractingPlan(constructionId, {
          name: values.service,
          specifications: values.contractingSpecifications,
          supplierQuotationDays: values.contractingSupplierQuotationDays,
          contractingLimitDays: values.contractingDays,
          deliveryLimitDays: values.contractingDeliveryDays,
          safetyDays: values.contractingSecurityDays,
          serviceIds: ids,
          supplierQuotatioResponsableId: supplierQuotatioResponsableId
            ? Number(supplierQuotatioResponsableId)
            : undefined,
          approveResponsableId: approvalResponsibleId
            ? Number(approvalResponsibleId)
            : undefined,
          contractingResponsableId: purchasingResponsableId
            ? Number(purchasingResponsableId)
            : undefined,
          approveReceiptResponsibleId: approveReceiptResponsibleId
            ? Number(approveReceiptResponsibleId)
            : undefined,
        });
      }
      setState(BaseState.success);
      setSnackbarMessage("Plano de compras e contratação salvo com sucesso");
      setOpenSnackbar(true);
    } catch (error) {
      // setState(BaseState.error);
      setState(BaseState.success);
      setSnackbarMessage("Erro ao salvar o plano de compras e contratação");
      setOpenSnackbar(true);
    }
  }

  async function editPurchasingOrContractingPlan(values: any) {
    try {
      setState(BaseState.loading);
      const ids: number[] = [];

      selectedServices.forEach((service) => {
        if (service.id !== undefined) {
          ids.push(service.id);
        }
      });

      if (values.selectedTab === PurchasingContractingENUM.PURCHASING) {
        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === cotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approvalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === purchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approveReceiptResponsible
        )?.id;

        await purchasingContractinService.updatePurchasePlan(
          purchasingSelectedPlan?.id || 0,
          {
            name: values.product,
            quantity: values.quantity,
            unit: values.unit,
            specifications: values.purchasingSpecifications,
            supplierQuotationDays: values.purchasingSupplierQuotationDays,
            purchasingLimitDays: values.purchasingDays,
            deliveryLimitDays: values.purchasingDeliveryDays,
            safetyDays: values.purchasingSecurityDays,
            serviceIds: ids,
            supplierQuotatioResponsableId: supplierQuotatioResponsableId
              ? Number(supplierQuotatioResponsableId)
              : undefined,
            approveResponsableId: approvalResponsibleId
              ? Number(approvalResponsibleId)
              : undefined,
            purchasingResponsableId: purchasingResponsableId
              ? Number(purchasingResponsableId)
              : undefined,
            approveReceiptResponsibleId: approveReceiptResponsibleId
              ? Number(approveReceiptResponsibleId)
              : undefined,
          }
        );
      }

      if (values.selectedTab === PurchasingContractingENUM.CONTRACTING) {
        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingCotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApprovalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingPurchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApproveReceiptResponsible
        )?.id;

        await purchasingContractinService.updateContractingPlan(
          contractingSelectedPlan?.id || 0,
          {
            name: values.service,
            specifications: values.contractingSpecifications,
            supplierQuotationDays: values.contractingSupplierQuotationDays,
            contractingLimitDays: values.contractingDays,
            deliveryLimitDays: values.contractingDeliveryDays,
            safetyDays: values.contractingSecurityDays,
            serviceIds: ids,
            supplierQuotatioResponsableId: supplierQuotatioResponsableId
              ? Number(supplierQuotatioResponsableId)
              : undefined,
            approveResponsableId: approvalResponsibleId
              ? Number(approvalResponsibleId)
              : undefined,
            contractingResponsableId: purchasingResponsableId
              ? Number(purchasingResponsableId)
              : undefined,
            approveReceiptResponsibleId: approveReceiptResponsibleId
              ? Number(approveReceiptResponsibleId)
              : undefined,
          }
        );
      }

      const id = searchParams.get("id");
      await loadPageData(id, values.selectedTab)

      setState(BaseState.success);
      setSnackbarMessage("Plano de compras e contratação salvo com sucesso");
      setOpenSnackbar(true);
    } catch (error) {
      setState(BaseState.error);
      setSnackbarMessage("Erro ao salvar o plano de compras e contratação");
      setOpenSnackbar(true);
    }
  }

  const cancelOnClick = () => {
    goToList();
  };

  const isButtonEnabled = (isValid: boolean, values: any): boolean => {
    return isValid && selectedServices.length > 0;
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {state === BaseState.success && !isConstructionNotSelected() && (
          <Formik
            initialValues={{
              service: contractingSelectedPlan?.name || "",
              quantity: purchasingSelectedPlan?.quantity?.toString() || "",
              unit: purchasingSelectedPlan?.unit || "",
              purchasingSpecifications:
                purchasingSelectedPlan?.specifications || "",
              product: purchasingSelectedPlan?.name || "",
              purchasingSupplierQuotationDays:
                purchasingSelectedPlan?.supplierQuotationDays || 0,
              purchasingDays: purchasingSelectedPlan?.purchasingLimitDays || 0,
              purchasingDeliveryDays:
                purchasingSelectedPlan?.deliveryLimitDays || 0,
              purchasingSecurityDays: purchasingSelectedPlan?.securityDays || 0,
              selectedTab:
                searchParams.get("type") === undefined ||
                searchParams.get("type") === null
                  ? PurchasingContractingENUM.PURCHASING
                  : searchParams.get("type") ===
                    PurchasingContractingENUM.PURCHASING
                  ? PurchasingContractingENUM.PURCHASING
                  : PurchasingContractingENUM.CONTRACTING,
              contractingSpecifications:
                contractingSelectedPlan?.specifications || "",
              contractingSupplierQuotationDays:
                contractingSelectedPlan?.supplierQuotationDays || 0,
              contractingDays:
                contractingSelectedPlan?.purchasingLimitDays || 0,
              contractingDeliveryDays:
                contractingSelectedPlan?.deliveryLimitDays || 0,
              contractingSecurityDays:
                contractingSelectedPlan?.securityDays || 0,
              selectedServices: purchasingSelectedPlan?.services || [],
            }}
            validationSchema={yupSchema}
            isInitialValid={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, handleSubmit, setFieldValue, isValid, errors }) => (
              <ContentContainer>
                <ChooseFormContainer>
                  <HeaderContainer isMobile={isMobile}>
                    <DialogTitle>Vincular serviços</DialogTitle>
                  </HeaderContainer>
                  <FormDescriptionText>
                    {formType === PurchasingContractingPlanFormType.CREATE
                      ? "Selecione um serviço para vincular o novo plano "
                      : "Edite os serviços vinculados"}
                  </FormDescriptionText>
                  <CustomDropDownComponent
                    values={servicesTypes}
                    key="CustomDropDownComponent"
                    onSelectedValueChanged={(key, value) =>
                      onChangeSelectedService(key, value)
                    }
                    selectedValue={selectedServiceType}
                    disabled={selectedServiceType !== undefined}
                  />
                  <LocationFormContainer>
                    <Autocomplete
                      id="verticalService"
                      value={selectedServices}
                      options={verticalServices}
                      fullWidth
                      size="small"
                      multiple
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.location
                      }
                      renderOption={(props, verticalService) => (
                        <AutoCompleteCustomMenuItem
                          {...props}
                          key={verticalService.id}
                          autoFocus={false}
                          value={verticalService.location}
                        >
                          <Checkbox
                            checked={
                              selectedServices.find(
                                (service) => service.id === verticalService.id
                              ) !== undefined
                            }
                          />
                          <CustomMenuItemText>
                            {verticalService.location}
                          </CustomMenuItemText>
                        </AutoCompleteCustomMenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Localização"
                          select={false}
                        />
                      )}
                      renderTags={(value, getTagProps) => {
                        return value
                          .slice(0, 1)
                          .map((option, index) => (
                            <SelectedTag {...getTagProps({ index })}>
                              {
                                <SelectedTagText>
                                  {`${value.length} serviços selecionado(s)`}
                                </SelectedTagText>
                              }
                            </SelectedTag>
                          ));
                      }}
                      disableCloseOnSelect
                      onChange={(event, value) =>
                        onSelectedVerticalService(value)
                      }
                      disableClearable
                    />

                    {!isMobile && (
                      <InputDateComponent
                        label="Início"
                        value={selectedServices[0]?.beginDate || undefined}
                        format="DD/MM/YYYY"
                        enable={false}
                        onChange={() => {}}
                      />
                    )}
                    {!isMobile && (
                      <InputDateComponent
                        label="Término"
                        value={
                          selectedServices[selectedServices.length - 1]
                            ?.endDate || undefined
                        }
                        enable={false}
                        format="DD/MM/YYYY"
                        onChange={() => {}}
                      />
                    )}

                    {isMobile && (
                      <StartAndEndDateContainer>
                        <InputDateComponent
                          label="Início"
                          value={selectedServices[0]?.beginDate || undefined}
                          format="DD/MM/YYYY"
                          enable={false}
                          onChange={() => {}}
                        />
                        <InputDateComponent
                          label="Término"
                          value={
                            selectedServices[selectedServices.length - 1]
                              ?.endDate || undefined
                          }
                          enable={false}
                          format="DD/MM/YYYY"
                          onChange={() => {}}
                        />
                      </StartAndEndDateContainer>
                    )}
                  </LocationFormContainer>
                </ChooseFormContainer>
                <PurchasingAndContractionContainer>
                  <HeaderContainer isMobile={isMobile}>
                    <DialogTitle>
                      {formType === PurchasingContractingPlanFormType.CREATE
                        ? "Compras e contratações"
                        : formType ===
                          PurchasingContractingPlanFormType.EDIT_PURCHASING
                        ? "Compras"
                        : "Contratações"}
                    </DialogTitle>
                  </HeaderContainer>
                  {formType === PurchasingContractingPlanFormType.CREATE && (
                    <TabContainer>
                      <Tabs
                        value={values.selectedTab}
                        onChange={(event, value) =>
                          setFieldValue("selectedTab", value)
                        }
                        style={{ width: "100%" }}
                      >
                        <CustomTab
                          value={PurchasingContractingENUM.PURCHASING}
                          label="Compras"
                        />
                        <CustomTab
                          value={PurchasingContractingENUM.CONTRACTING}
                          label="Contratação"
                        />
                      </Tabs>
                    </TabContainer>
                  )}

                  {values.selectedTab ===
                    PurchasingContractingENUM.PURCHASING && (
                    <PurchasingForm>
                      <PurchasingFormRowOne>
                        <Field
                          name="product"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Produto"
                                value={values.product}
                                onChange={(value: string) => {
                                  setFieldValue("product", value);
                                }}
                              />
                            );
                          }}
                        />

                        <Field
                          name="quantity"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Quantidade"
                                type="number"
                                mask=""
                                value={values.quantity}
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue("quantity", newValue);
                                }}
                              />
                            );
                          }}
                        />

                        <Field
                          name="unit"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Unidade"
                                value={values.unit}
                                onChange={(value: string) => {
                                  setFieldValue("unit", value);
                                }}
                              />
                            );
                          }}
                        />
                      </PurchasingFormRowOne>
                      <Field
                        name="specifications"
                        render={() => {
                          return (
                            <InputValueComponent
                              label="Especificações"
                              value={values.purchasingSpecifications}
                              onChange={(value: string) => {
                                setFieldValue(
                                  "purchasingSpecifications",
                                  value
                                );
                              }}
                            />
                          );
                        }}
                      />
                      <BuilderAndSupplierContainer>
                        <BuilderAndSupplierContainerDescription>
                          Construtora
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="supplierQuotationDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Cotação"
                                value={
                                  values.purchasingSupplierQuotationDays?.toString() ||
                                  ""
                                }
                                type="number"
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue(
                                    "purchasingSupplierQuotationDays",
                                    newValue
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="contractingDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Compra"
                                value={values.purchasingDays?.toString() || ""}
                                type="number"
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue("purchasingDays", newValue);
                                }}
                              />
                            );
                          }}
                        />
                      </BuilderAndSupplierContainer>
                      <BuilderAndSupplierContainer>
                        <BuilderAndSupplierContainerDescription>
                          Fornecedor
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="deliveryDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Fornecimento"
                                type="number"
                                value={
                                  values.purchasingDeliveryDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue(
                                    "purchasingDeliveryDays",
                                    newValue
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="securityDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Margem de segurança"
                                type="number"
                                value={
                                  values.purchasingSecurityDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue(
                                    "purchasingSecurityDays",
                                    newValue
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </BuilderAndSupplierContainer>
                    </PurchasingForm>
                  )}

                  {values.selectedTab ===
                    PurchasingContractingENUM.CONTRACTING && (
                    <ContractingForm>
                      <Field
                        name="specifications"
                        render={() => {
                          return (
                            <InputValueComponent
                              label="Serviço"
                              value={values.service}
                              onChange={(value: string) => {
                                setFieldValue("service", value);
                              }}
                            />
                          );
                        }}
                      />
                      <Field
                        name="specifications"
                        render={() => {
                          return (
                            <InputValueComponent
                              label="Especificações"
                              value={values.contractingSpecifications}
                              onChange={(value: string) => {
                                setFieldValue(
                                  "contractingSpecifications",
                                  value
                                );
                              }}
                            />
                          );
                        }}
                      />
                      <BuilderAndSupplierContainer>
                        <BuilderAndSupplierContainerDescription>
                          Construtora
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="supplierQuotationDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Cotação"
                                value={
                                  values.contractingSupplierQuotationDays.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  setFieldValue(
                                    "contractingSupplierQuotationDays",
                                    value
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="contractingDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Contratação"
                                value={values.contractingDays?.toString() || ""}
                                onChange={(value: string) => {
                                  setFieldValue("contractingDays", value);
                                }}
                              />
                            );
                          }}
                        />
                      </BuilderAndSupplierContainer>
                      <BuilderAndSupplierContainer>
                        <BuilderAndSupplierContainerDescription>
                          Fornecedor
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="deliveryDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Fornecimento"
                                value={
                                  values.contractingDeliveryDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  setFieldValue(
                                    "contractingDeliveryDays",
                                    value
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="securityDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                label="Margem de segurança"
                                value={
                                  values.contractingSecurityDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  setFieldValue(
                                    "contractingSecurityDays",
                                    value
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </BuilderAndSupplierContainer>
                    </ContractingForm>
                  )}
                </PurchasingAndContractionContainer>
                <ResponsibleContainer>
                  <ResponsibleContainerTitle>
                    Defina os responsáveis pelas etapas
                  </ResponsibleContainerTitle>
                  <ResponsibleRow>
                    <ResponsibleRowDesctipion>
                      1 - Cotação
                    </ResponsibleRowDesctipion>
                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? cotationResponsible
                          : contractingCotationResponsible
                      }
                      label="Nome usuário"
                      defaultValue="Nome usuário"
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setCotationResponsible(value)
                          : setContractingCotationResponsible(value);
                      }}
                    />
                  </ResponsibleRow>

                  <ResponsibleRow>
                    <ResponsibleRowDesctipion>
                      2 - Aprovar pedido
                    </ResponsibleRowDesctipion>
                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      label="Nome usuário"
                      defaultValue="Nome usuário"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? approvalResponsible
                          : contractingApprovalResponsible
                      }
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setApprovalResponsible(value)
                          : setContractingApprovalResponsible(value);
                      }}
                    />
                  </ResponsibleRow>
                  <ResponsibleRow>
                    <ResponsibleRowDesctipion>
                      3 -{" "}
                      {values.selectedTab ===
                      PurchasingContractingENUM.PURCHASING
                        ? "Fazer compra"
                        : "Contratar serviço"}
                    </ResponsibleRowDesctipion>
                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? purchasingResponsible
                          : contractingPurchasingResponsible
                      }
                      label="Nome usuário"
                      defaultValue="Nome usuário"
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setPurchasingResponsible(value)
                          : setContractingPurchasingResponsible(value);
                      }}
                    />
                  </ResponsibleRow>
                  <ResponsibleRow>
                    <ResponsibleRowDesctipion>
                      {`4 - ${
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? "Aprovar recebimento"
                          : "Aprovar contratado"
                      }`}
                    </ResponsibleRowDesctipion>
                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      label="Nome usuário"
                      defaultValue="Nome usuário"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? approveReceiptResponsible
                          : contractingApproveReceiptResponsible
                      }
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setApproveReceiptResponsible(value)
                          : setContractingApproveReceiptResponsible(value);
                      }}
                    />
                  </ResponsibleRow>
                </ResponsibleContainer>
                <ButtonContainer>
                  <ButtonComponent
                    text={"Voltar"}
                    type={ButtonType.TRANSPARENT}
                    onClick={() => cancelOnClick?.()}
                  />
                  <ButtonComponent
                    state={
                      isButtonEnabled(isValid, values)
                        ? ButtonState.DEFAULT_ENABLED
                        : ButtonState.DISABLED
                    }
                    text={"Confirmar"}
                    onClick={() => handleSubmit()}
                  />
                </ButtonContainer>
              </ContentContainer>
            )}
          </Formik>
        )}

        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o plano de compras e contratação" />{" "}
          </CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}

        <Snackbar
          message={snackbarMessage}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => {
            setOpenSnackbar(false);
          }}
        />
      </PageContainer>
    </ReactPageBaseComponent>
  );
});

export default PurchasingAndContractingPlanForm;
