import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

interface SubtitleProps {
  color: string;
  text: string;
  percentage?: string;
  minWidth?: number;
}

const Subtitle: React.FC<SubtitleProps> = ({ color, text, percentage,minWidth }) => {
  return (
    <SubtitleContainer minWidth={minWidth}>
      <Circle style={{ backgroundColor: color }} />
      <SubtitleText>{text}</SubtitleText>
      {percentage ? <Percentage>{percentage}</Percentage> : null}
    </SubtitleContainer>
  );
};

const SubtitleContainer = styled.div<{minWidth: number | undefined}>`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  padding: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-template-columns: max-content ${(props) => props.minWidth ? props.minWidth : 120}px max-content;
  }
`;

const Circle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 20px;
`;

const SubtitleText = styled.div`
  color: ${[Colors.primaryColorDark]};
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  margin-left: 8px;
`;

const Percentage = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
`;

export default Subtitle;
