import styled from "styled-components";
import { Colors } from "../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import Tab from "@mui/material/Tab";
import { IconButton } from "@mui/material";
import React from "react";

export const GroupByTimeText = styled.span`
    font-size: 14px;
    font-family: "Noto Sans";
    font-weight: 600;
    color: ${Colors.textColorBlack};
`;

export const FullItemContainer = styled.div<{ columnQuantity: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnQuantity}, 1fr);
  max-width: 100%;
  background: #dddddd;
`;

export const PageContainer = styled.div<{ isNavBarHide: boolean }>`
  display: grid;
  width: 100%;
  height: 100vh;
  padding-top: ${(props) => (!props.isNavBarHide ? "84px" : "20px")};
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  overflow-y: auto;
  align-content: start;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  max-width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    align-content: start;
    margin-top: 8px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
  }
`;

export const AboutContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  align-content: start;
`;

export const PrintStatePageDescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content;
  align-content: start;
  margin-top: 16px;
  margin-left: 32px;
  margin-right: 32px;
  row-gap: 12px;
  justify-self: flex-end;
`;

export const PrintStatePageDescriptionText = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const AdvancementContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 324px) minmax(0px, 324px);
  column-gap: 80px;
  margin-top: 48px;
  margin-left: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ButtonActionsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-template-rows: max-content max-content max-content;
  margin-right: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 24px;
  }
`;

export const PageDescription = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.primaryColor};
  margin-left: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
  }
`;

export const TableContainer = styled.div`
  display: grid;
  width: fit-content;
  height: max-content;
  margin-left: 34px;
  margin-right: 32px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  margin-top: 54px;
  padding-bottom: 32px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 124px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const VerticalListRoot = styled.div<{ columnQuantity: number }>`
  display: grid;
  grid-template-columns: 190px repeat(${(props) => props.columnQuantity}, 1fr);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: minmax(0px, 135px) repeat(
        ${(props) => props.columnQuantity},
        1fr
      );

    overflow-x: auto;
  }
`;

export const HorizontalListPrimaryContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  overflow-x: scroll;
`;

export const TimePeriodFilterDropDownContainer = styled.div`
  width: 149px;
  margin-top: 4px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 799px;
    justify-self: end;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
    justify-self: end;
  }
`;

export const TableControllerComponent = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: end;
  column-gap: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: max-content max-content max-content;
  }
`;

export const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

// eslint-disable-next-line prettier/prettier
export const HorizontalListContainer = styled.div<{gridTemplate: string;}>`
  display: grid;
  width: 100%;
  grid-template-rows: max-content max-content;
  grid-template-columns: 1fr ${(props) => props.gridTemplate};
`;

export const EmptyGridItem = styled.div<{ isPrintState: boolean }>`
  ${({ isPrintState }) =>
  !isPrintState &&
  `
    width: 183px;
  `}

  ${({ isPrintState }) =>
  isPrintState &&
  `
    max-width: 183px;
  `}

  height: 48px;
  background: transparent;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
  }
`;

export const ItemHeaderGridContainerEmpty = styled.div<{ isPrintState: boolean }>`
  ${({ isPrintState }) =>
  !isPrintState &&
  `
    width: 183px;
  `}
  ${({ isPrintState }) =>
  isPrintState &&
  `
    max-width: 183px;
  `}

  height: 52px;
  background: #dddddd;
  border-left: 1px #7f90c9 solid;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
    height: 48px;
  }
`;

export const ItemHeadTypeIndicator = styled.div`
  width: 190px;
  height: 52px;
  background: #dddddd;
  display: grid;
  justify-content: left;
  align-content: center;
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  padding-left: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
    height: 48px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const ItemHeadServiceIndicator = styled.div`
  width: 190px;
  height: 52px;
  background: #f8f9fc;
  border-bottom: 1px #7f90c9 solid;
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  padding-left: 16px;
  display: grid;
  justify-content: left;
  align-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
    height: 48px;
    font-size: 10px;
  }
`;

export const ItemVerticalListHeader = styled.div`
  width: 100%;
  height: 52px;
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 600;
  word-wrap: break-word;
  border-bottom: 1px #7f90c9 solid;
  display: grid;
  justify-content: center;
  align-content: center;
`;

export const FullItemEmptyContainer = styled.div``;

// eslint-disable-next-line prettier/prettier
export const FullItemEmptyContainerCustomGrid = styled(FullItemEmptyContainer)<{
  gridTemplate: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate};
  background: #f8f9fc;
  max-width: 100%;
  height: 52px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 48px;
  }
`;

export const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

export const ExportPdfAndFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 144px 144px 121px;
  column-gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: max-content max-content max-content;
    place-self: end;
  }
`;

export const AddNewServiceButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: end;
  gap: 24px;
`;

export const QuadroAcompanhamento = styled.div`
  width: 540px;
  text-align: center;
  align-self: center;
  justify-self: center;
  margin-top: 0px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const Texto = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  font-family: "Noto Sans";
  word-wrap: break-word;
`;

export const PrintStateButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: grid;
  justify-content: flex-end;
  align-items: center;
  grid-template-columns: 110px 110px;
  column-gap: 24px;
  height: 98px;
  width: 100%;
  padding-right: 128px;
  background-color: ${Colors.neutralColorWhite};
`;

export const LastUpdateText = styled.span`
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 400;
  color: ${Colors.textColorBlack};
`;

export const LastUpdateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-left: 24px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-top: 16px;
  }
`;

export const GroupByTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 24px;
  align-items: center;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-top: 16px;
  }
`;

export const IconButtonActionMenu = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: solid;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${Colors.primaryColor};
`;