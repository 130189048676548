import React from "react";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
//import { chartColors } from "../../../config/app-info";

type Props = {
  title: string;
  data: number[];
  labels: string[];
};
const PriorityActionsChart: React.FC<Props> = ({ title, data, labels }) => {
  const options: ApexOptions = {
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#111"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderWidth: 0,
      },
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return `${w.config.labels[seriesIndex]}: ${
          w.config.series[seriesIndex]
        }; ${Number(value).toFixed(2)}%`;
      },
    },
    labels,
    title: {
      text: title,
      align: "center",
    },
    colors: ["#ADD8E6", "#00BFFF", "#FFD700", "#FF8C00", "#FF0000"],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 3,
    },
    fill: {
      type: "image",
      opacity: 0.85,
      image: {
        src: [
          "/static/img/charts/01.jpg",
          "/static/img/charts/02.png",
          "/static/img/charts/03.jpg",
          "/static/img/charts/04.jpg",
          "/static/img/charts/05.jpg",
        ],
      },
    },
  };

  return <Chart options={options} series={data} type="pie" height="300" />;
};

export default withTheme(PriorityActionsChart);
