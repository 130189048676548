import styled from "styled-components";
import { TableItemModel } from "../models/TableItemModel";
import { TableActionsENUM } from "../models/TableActionsENUM";
import { TableItemTypeENUM } from "../models/TableItemENUM";
import { Colors } from "../../../theme/variants";
import { ReactComponent as FilterIcon } from "../../../assets/arrow-down.svg";

interface TableItemProps {
  name: string;
  type: TableItemTypeENUM | undefined;
  isHeaderFilterEnabled?: boolean;
  filterOnClick?: (name: string) => void;
}

const TableItemHeader: React.FC<TableItemProps> = ({
  name,
  type,
  isHeaderFilterEnabled,
  filterOnClick,
}) => {
  return type === TableItemTypeENUM.action ? (
    <TableActionContainer>
      <StyledText>{name}</StyledText>
    </TableActionContainer>
  ) : (
    <TableItemContainer
      isHeaderFilterEnabled={isHeaderFilterEnabled || false}
      onClick={() => filterOnClick?.(name)}
    >
      <StyledText>{name}</StyledText>
      {isHeaderFilterEnabled && <FilterIcon />}
    </TableItemContainer>
  );
};

const TableActionContainer = styled.div`
  width: 96px;
  height: 56px;
  background-color: ${Colors.neutralColorLight};
  padding: 20px;
  align-items: center;
`;

const TableItemContainer = styled.div<{ isHeaderFilterEnabled: boolean }>`
  width: 100%;
  display: grid;
  background-color: ${Colors.neutralColorLight};
  grid-template-columns: max-content 24px;
  padding: 20px;
  height: 56px;
  align-items: center;
`;

const StyledText = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
`;

export default TableItemHeader;
