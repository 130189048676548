import { StyleSheet } from "@react-pdf/renderer";

const stylesPdf = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px",
  },
  section: {
    margin: 0,
    padding: 0,
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "60vh",
  },
  title: {
    fontSize: "10px",
    textAlign: "center",
    marginTop: "-20px",
  },
  construction: {
    fontSize: "8px",
    textAlign: "center",
    marginBottom: "5px",
  },
  logo: {
    marginTop: "10px",
    height: "20px",
    width: "95px",
  },
  updateText: {
    fontSize: "7px",
    marginBottom: "4px",
  },
  criteriaName: {
    fontSize: "11px",
    marginBottom: "3px",
    marginTop: "7px",
  },
  table: {
    marginTop: "20px",
  },
  tr: {
    padding: "3px",
    fontSize: "8px",
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },
  td: {
    fontSize: "8px",
    padding: "3px",
    alignItems: "center",
    justifyContent: "center",
  },
  tdCenter: {
    fontSize: "8px",
    padding: "3px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  pagination: {
    marginTop: "20px",
    marginRight: "20px",
    fontSize: "8px",
    textAlign: "right",
  },
});

export { stylesPdf };
