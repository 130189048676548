import styled from "styled-components";
import DropDownChecklistComponent, {
  DropDownComponentRef,
} from "../../../components/InputValues/DropDownChecklist";
import { Button, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

interface FilterSideBarProps {
  responsibleList: string[];
  locationList: string[];
  serviceList: string[];
  beginDate?: Date;
  endDate?: Date;
  onCloseFilterSideBar: () => void;
  onApplyFilter: (selectedValues: SelectedValues) => void;
}

interface SelectedValues {
  responsibleList: string[];
  locationList: string[];
  serviceList: string[];
  beginDate?: Date;
  endDate?: Date;
}

const FilterSideBar: React.FC<FilterSideBarProps> = ({
  responsibleList,
  locationList,
  serviceList,
  onCloseFilterSideBar,
  onApplyFilter,
  beginDate,
  endDate,
}) => {
  const refOne = React.useRef<DropDownComponentRef>();
  const refTwo = React.useRef<DropDownComponentRef>();
  const refThree = React.useRef<DropDownComponentRef>();

  const [selectedValues, setSelectedValues] = React.useState<SelectedValues>({
    responsibleList: [],
    locationList: [],
    serviceList: [],
    beginDate: beginDate ? new Date(beginDate) : beginDate,
    endDate: endDate ? new Date(endDate) : endDate,
  });

  useEffect(() => {
    if (beginDate === undefined || endDate === undefined) {
      return;
    }

    setSelectedValues({
      ...selectedValues,
      beginDate: new Date(beginDate),
      endDate: new Date(endDate),
    });
  }, [beginDate, endDate]);

  const cleanFilterSideBar = () => {
    setSelectedValues({
      responsibleList: [],
      locationList: [],
      serviceList: [],
      beginDate: beginDate,
      endDate: endDate,
    });
    (refOne.current as any).cleanData();
    (refTwo.current as any).cleanData();
    (refThree.current as any).cleanData();
  };

  const aplyFilter = () => {
    onApplyFilter(selectedValues);
    onCloseFilterSideBar();
  };

  const handleStartDateChange = (date: any) => {
    if (
      selectedValues.endDate === undefined ||
      selectedValues.endDate === null
    ) {
      return;
    }

    if (date > selectedValues.endDate) {
      const endDateAfterBeginDate = new Date(date);
      endDateAfterBeginDate.setDate(endDateAfterBeginDate.getDate() + 7);
      setSelectedValues({
        ...selectedValues,
        beginDate: date,
        endDate: endDateAfterBeginDate,
      });
      return;
    }

    setSelectedValues({ ...selectedValues, beginDate: date });
  };

  const handleEndDateChange = (date: any) => {
    if (
      selectedValues.beginDate === undefined ||
      selectedValues.beginDate === null
    ) {
      return;
    }

    if (date < selectedValues.beginDate) {
      const beginDateAfterEndDate = new Date(selectedValues.beginDate);
      beginDateAfterEndDate.setDate(beginDateAfterEndDate.getDate() + 7);
      setSelectedValues({ ...selectedValues, endDate: beginDateAfterEndDate });
      return;
    }

    setSelectedValues({ ...selectedValues, endDate: date });
  };

  const handleResponsibleChange = (selectedResponsibleValues: string[]) => {
    setSelectedValues({
      ...selectedValues,
      responsibleList: selectedResponsibleValues,
    });
  };

  const handleLocationChange = (selectedLocationValues: string[]) => {
    setSelectedValues({
      ...selectedValues,
      locationList: selectedLocationValues,
    });
  };

  const handleServiceChange = (selectedServiceValues: string[]) => {
    setSelectedValues({
      ...selectedValues,
      serviceList: selectedServiceValues,
    });
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>Filtrar por</Title>
        <Button
          variant="text"
          style={{ color: "#000" }}
          startIcon={<Close />}
          onClick={() => onCloseFilterSideBar()}
        />
      </HeaderContainer>
      <DropDownChecklistComponent
        ref={refOne}
        key="responsibleDropdown"
        onSelectedValueChanged={handleResponsibleChange}
        values={responsibleList}
        placeholder="Responsável"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <DesktopDatePicker
          label="Início"
          value={selectedValues.beginDate}
          onChange={(date) => handleStartDateChange(date)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
        <DesktopDatePicker
          label="Data fim"
          value={selectedValues.endDate}
          onChange={(date) => handleEndDateChange(date)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
      <DropDownChecklistComponent
        ref={refTwo}
        key="Localização"
        onSelectedValueChanged={(selectedList) =>
          handleLocationChange(selectedList)
        }
        values={locationList}
        placeholder="Localização"
      />
      <DropDownChecklistComponent
        key="service"
        ref={refThree}
        onSelectedValueChanged={(selectedList) => {
          handleServiceChange(selectedList);
        }}
        values={serviceList}
        placeholder="Serviço"
      />
      <ButtonContainer>
        <ButtonComponent
          text="Limpar filtros"
          type={ButtonType.OUTLINE}
          onClick={cleanFilterSideBar}
        />
        <ButtonComponent
          state={ButtonState.DEFAULT_ENABLED}
          text="Filtrar"
          onClick={aplyFilter}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  width: 358px;
  height: 100%;
  grid-template-rows: auto auto auto auto auto auto;
  align-content: start;
  row-gap: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: start;
  align-content: center;
  column-gap: 8px;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  gap: 24px;
  margin-top: 32px;
`;

export default FilterSideBar;
