import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import { PurchasingPlanEntity } from "../../../services/purchasingContractingPlan/entity/PurchasingPlanEntity";
import { PurchasingPhaseENUM } from "./PurchasingPhaseENUM";
import { PurchasingPlanStatusENUM } from "./PurchasingPlanStatusENUM";

interface IResponsibleUser {
  id: number;
  name: string;
}

interface PurchasingModel {
  id?: number;
  name: string;
  specifications?: string;
  unit?: string;
  quantity?: number;
  supplierQuotationDate?: Date;
  purchasingDateLimit?: Date;
  deliveryDateLimit?: Date;
  supplierQuotationDays?: number;
  purchasingLimitDays?: number;
  purchaseLimitDate?: string;
  deliveryLimitDays?: number;
  deliveryLimitDate?: string;
  securityDays?: number;
  status?: PurchasingPlanStatusENUM;
  phase?: PurchasingPhaseENUM;
  isPhaseInitiated?: boolean;
  responsisbleName?: string;
  responsibleId?: number;
  responsibleRole?: number;
  supplierQuotatioResponsableId?: number;
  supplierQuotatioResponsable?: IResponsibleUser;
  approveResponsableId?: number;
  approveResponsable?: IResponsibleUser;
  purchasingResponsableId?: number;
  purchasingResponsable?: IResponsibleUser;
  finishPlanResponsableId?: number;
  finishPlanResponsable?: IResponsibleUser;
  services: VerticalServiceModel[];
}

export const parsePurchasingEntityToModel = (
  item: PurchasingPlanEntity
): PurchasingModel => {
  return {
    id: item.id || 0,
    name: item.name,
    unit: item.unit,
    quantity: item.quantity,
    specifications: item.specifications,
    supplierQuotationDate: item.supplierQuotationDate
      ? new Date(item.supplierQuotationDate)
      : undefined,
    purchasingDateLimit: item.purchasingLimitDate
      ? new Date(item.purchasingLimitDate)
      : undefined,
    deliveryDateLimit: item.deliveryLimitDate
      ? new Date(item.deliveryLimitDate)
      : undefined,
    securityDays: item.safetyDays,
    supplierQuotationDays: item.supplierQuotationDays,
    purchasingLimitDays: item.purchasingLimitDays,
    deliveryLimitDays: item.deliveryLimitDays,
    status: item.status,
    phase: item.phase,
    isPhaseInitiated: item.isPhaseInitiated === true,
    responsisbleName: item.currentResponsible?.name || undefined,
    responsibleId: item.currentResponsible?.id || undefined,
    responsibleRole: item.currentResponsible?.role || undefined,
    services: item.verticalServices,
    supplierQuotatioResponsable: item.supplierQuotatioResponsable,
    supplierQuotatioResponsableId: item.supplierQuotatioResponsableId,
    approveResponsable: item.approveResponsable,
    approveResponsableId: item.approveResponsableId,
    purchasingResponsable: item.purchasingResponsable,
    purchasingResponsableId: item.purchasingResponsableId,
    finishPlanResponsable: item.approveReceiptResponsible,
    finishPlanResponsableId: item.approveReceiptResponsibleId,
  } as PurchasingModel;
};

export default PurchasingModel;
