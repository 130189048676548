import styled from "styled-components";
import DropDownComponent from "../../../../components/InputValues/DropDownComponent";
import InputValueComponent from "../../../../components/InputValues/InputValueComponent";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar-check.svg";
import { IActionModel } from "../../../Actions/models/ActionModel";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import DropDownChecklistComponent from "../../../../components/InputValues/DropDownChecklist";
import { UserResponsible } from "../../../../services/UserService";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../../components/Button/ButtonComponent";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-primary-color.svg";
import { ReactComponent as EditIcon } from "../../../../assets/edit-primary-color.svg";
import { Colors } from "../../../../theme/variants";
import { ActionStatus } from "../../../Actions/models/ActionStatus";
import { restrictionPriorities } from "../../../../config/app-info";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

interface NewRestrictionProps {
  action: IActionModel;
  responsibles: UserResponsible[];
  isDisabled?: boolean;
  isAddState?: boolean;
  index: number;
  onActionChange: (action: IActionModel) => void;
  onDeletedAction: (action: IActionModel) => void;
}

const NewActionComponent: React.FC<NewRestrictionProps> = ({
  action,
  responsibles,
  onActionChange,
  isDisabled,
  isAddState,
  onDeletedAction,
  index,
}) => {
  function statusColor(status: string): { color: string; title: string } {
    let color = null;
    let title = null;
    switch (status) {
      case ActionStatus.PENDING:
        color = Colors.primaryColor;
        title = "A fazer";
        break;
      case ActionStatus.IN_PROGRESS:
        color = Colors.secondaryColor;
        title = "Em andamento";
        break;
      case ActionStatus.DONE:
        color = Colors.feedbackSuccess;
        title = "Concluído";
        break;
      case ActionStatus.DELAYED:
        color = Colors.feedbackError;
        title = "Em atraso";
        break;
      default: {
        color = Colors.primaryColor;
        title = "A fazer";
      }
    }
    return { color: color, title: title };
  }

  const beginDateOnChange = (date: any) => {
    action.beginDate = new Date(date);
    if (action.endDate < action.beginDate) {
      action.endDate = new Date(date);
    }
    onActionChange(action);
  };

  const endDateOnChange = (date: any) => {
    action.endDate = new Date(date);
    if (action.beginDate > action.endDate) {
      action.beginDate = new Date(date);
    }
    onActionChange(action);
  };

  const onActionDescriptionChange = (value: string) => {
    action.actionDescription = value;
    onActionChange(action);
  };

  const onObservationChange = (value: string) => {
    action.how = value;
    onActionChange(action);
  };

  const onResponsibleChange = (_value: string[]) => {
    const selectedResponsibles = responsibles.filter((responsible) =>
      _value.includes(responsible.name)
    );
    action.responsibles = selectedResponsibles;
    onActionChange(action);
  };

  const onPriorityChange = (value: string) => {
    action.priority = restrictionPriorities.indexOf(value);
    onActionChange(action);
  };

  return (
    <Container>
      <RowZero>
        <StatusContainer>
          {action.status ? (
            <StatusIndicator
              style={{ backgroundColor: Colors.feedbackSuccess }}
            />
          ) : null}
          {action.status ? <SucessText>Concluída</SucessText> : null}
        </StatusContainer>
        {isDisabled === true ||
        (action.generatedByEmptyObject && index == 0) ? null : (
          <ButtonComponent
            type={ButtonType.OUTLINE}
            startIcon={<TrashIcon />}
            text="Excluir"
            onClick={() => onDeletedAction(action)}
          />
        )}
      </RowZero>
      <RowOne>
        <InputValueComponent
          key="Ação"
          label="Ação"
          onChange={(value) => onActionDescriptionChange(value)}
          value={action.actionDescription}
          isDisabled={isDisabled}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DesktopDatePicker
            value={action.beginDate}
            onChange={beginDateOnChange}
            renderInput={(params) => (
              <TextField
                size="small"
                placeholder="Data de início"
                {...params}
                disabled={isDisabled}
              />
            )}
          />
          <DesktopDatePicker
            value={action.endDate}
            onChange={endDateOnChange}
            renderInput={(params) => (
              <TextField
                size="small"
                placeholder="Data de término"
                {...params}
                disabled={isDisabled}
              />
            )}
          />
        </LocalizationProvider>
        <DropDownChecklistComponent
          key="Responsável"
          defaultValue="Status"
          placeholder="Responsáveis"
          size="small"
          isDisabled={isDisabled}
          selectedValues={
            responsibles
              .filter((responsible) =>
                action.responsibles?.find(
                  (actionResponsible) => responsible.id === actionResponsible.id
                )
              )
              .map((responsible) => responsible.name) || []
          }
          values={responsibles.map((responsible) => responsible.name)}
          onSelectedValueChanged={(value) => onResponsibleChange(value)}
        />
      </RowOne>
      <RowTwo>
        <DropDownComponent
          key="Prioridade"
          label="Prioridade"
          defaultValue="Prioridade"
          selectedValue={
            action.priority !== undefined
              ? restrictionPriorities[action.priority || 0]
              : undefined
          }
          disabled={isDisabled}
          values={restrictionPriorities}
          onSelectedValueChanged={(key, value) => onPriorityChange(value)}
        />
        <InputValueComponent
          key="Observação"
          label="Observação"
          isDisabled={isDisabled}
          onChange={onObservationChange}
          value={action.how || action.observation || ""}
        />
      </RowTwo>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
`;

const RowOne = styled.div`
  display: grid;
  margin-top: 16px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-column-gap: 16px;
    grid-template-columns: 50% 1fr 1fr 1fr;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-row-gap: 16px;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const RowTwo = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
  column-gap: 16px;
  margin-top: 16px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const RowZero = styled.div`
  display: grid;
  width: 100%;
  justify-content: end;
  grid-template-columns: max-content minmax(0px, 110px);
  column-gap: 16px;
`;

const StatusIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const SucessText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
`;

export default NewActionComponent;
