import React, { createContext, useContext, useState } from "react";
import { ActionContextData, ActionItem } from "../config/app-info";

const actionDefaultValue: ActionContextData = {
  actions: [],
  setActions: () => {},
};

const ActionContext = createContext<ActionContextData>(actionDefaultValue);
type Props = {
  children: React.ReactNode;
};

export const ActionProvider: React.FC<Props> = ({ children }) => {
  const [actions, setActions] = useState<ActionItem[]>([]);

  return (
    <ActionContext.Provider
      value={{
        actions,
        setActions,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};

export const useAction = () => {
  const context = useContext(ActionContext);

  return context;
};
