import React, { useEffect, useState, KeyboardEvent } from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Field, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  TextField,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import styled from "styled-components/macro";
import InputMask from "react-input-mask";
import { spacing } from "@mui/system";
import { DoneAll as DoneAllIcon } from "@mui/icons-material";
import Alert from "../../../components/Alert";
import { Client, User, userRoles } from "../../../config/app-info";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { encrypt } from "../../../utils/crypto";
import { AxiosError } from "axios";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "O nome deve ter no mínimo 3 letras")
    .required("Nome é obrigatório"),
  email: yup
    .string()
    .email("Insira um e-mail válido!")
    .required("E-mail é obrigatório"),
  login: yup
    .string()
    .min(3, "O login deve ter no mínimo 3 letras")
    .required("Login é obrigatório"),
  password: yup
    .string()
    .min(6, "Sua senha deve ter no mínimo 6 carateres")
    .required("Senha é obrigatório"),
  password2: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Confirmar senha é obrigatório"),
  role: yup.string().required("Perfil é obrigatório"),
});

type Props = {
  section: string;
};

const UsersForm: React.FC<Props> = ({ section }) => {
  const [user, setUser] = useState<User>({} as User);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  const [clients, setClients] = useState<Client[]>([]);
  const { user: userLogged, setAvatarSidebar } = useAuth();
  let { id: userId } = useParams();
  const passwordFake = "3AP@App";

  useEffect(() => {
    if (userId) {
      const fetchUserData = async () => {
        const response = await api.get(`api/users/${userId}`);
        response.data.password = passwordFake;
        response.data.password2 = passwordFake;
        setUser(response.data);
      };
      fetchUserData();
    } else {
      setUser({
        id: 0,
        name: "",
        email: "",
        login: "",
        phone: "",
        password: "",
        password2: "",
        role: -1,
        status: true,
        avatar: "",
        removeAvatar: false,
        responsibleClientId: 0,
      } as User);
    }

    const fetchClients = async () => {
      const response = await api.get("api/clients");
      setClients(response.data);
    };
    fetchClients();
  }, [userId]);

  const onSubmit = async (
    values: User,
    { setSubmitting, resetForm }: FormikHelpers<User>
  ) => {
    setSubmitting(true);

    if (values.role < 3) {
      values.responsibleClientId = 0;
    }

    if (!values.responsibleClientId) {
      values.responsibleClientId = 0;
    }

    if (userId) {
      try {
        if (values.removeAvatar) {
          await api.get(`api/users/remove-avatar/${userId}`);
          values.avatar = "";
          setAvatarSidebar("");
        }
        if (!values.removeAvatar && avatar !== null && avatar.size > 0) {
          const avatarName = await sendFile(userId, avatar, "avatar");
          setAvatarSidebar(avatarName);
          values.avatar = avatarName;
        }
        delete values.removeAvatar;

        const response = await api.put(`api/users/${userId}/`, values);

        if (response.status === 200 || response.status === 204) {
          if (Number(userId) === userLogged.id) {
            sessionStorage.setItem(
              "@Auth:user",
              encrypt(JSON.stringify(response.data))
            );
          }

          setAlert({
            title: "Alteração concluída!",
            message: "Usuário alterado com sucesso",
            isOpened: true,
          });
        }
      } catch (error) {
        setAlert({
          title: "Erro na alteração",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        if (!values.status) values.status = false;
        values.avatar = "";
        const response = await api.post("api/authentication/register", values);
        const userId = String(response.data.id);

        if (
          !values.removeAvatar &&
          userId &&
          avatar !== null &&
          avatar.size > 0
        ) {
          sendFile(userId, avatar, "avatar");
        }

        if (response.status === 201) {
          setAlert({
            title: "Cadastro concluído!",
            message: "Usuário cadastrado com sucesso",
            isOpened: true,
          });
          resetForm();
        }
      } catch (e) {
        let errorMessage = "Algo deu errado. Tente novamente mais tarde.";
        if (e instanceof AxiosError) {
          const response = e.response;
          if (response?.data?.message) {
            errorMessage = response?.data?.message
          }
        }
        setAlert({
          title: "Erro no cadastro",
          message: errorMessage,
          isOpened: true,
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const sendFile = async (
    userId: string,
    file: File,
    fileName: string
  ): Promise<string> => {
    const formData = new FormData();

    if (userId && file) {
      formData.append("userId", userId);
      formData.append(fileName, file);
      try {
        const response = await api.post(
          "api/users/upload-" + fileName,
          formData
        );
        return response.data;
      } catch (err) {
        console.log(err);
        return "";
      }
    } else {
      return "";
    }
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          {user && (
            <Formik
              initialValues={user}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate
                  onKeyDown={(e) => checkKeyDown(e)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={5} mb={4}>
                      <Field
                        component={TextField}
                        label="Nome"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        value={values.name || ""}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={5} mb={4}>
                      <Field
                        component={TextField}
                        label="E-mail"
                        id="email"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        type="email"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        name="phone"
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                      >
                        {({ field }: any) => (
                          <InputMask
                            {...field}
                            mask="(99) 99999-9999"
                            value={values.phone || ""}
                          >
                            <TextField
                              {...field}
                              name="phone"
                              id="phone"
                              variant="outlined"
                              size="small"
                              fullWidth
                              label="Telefone"
                              onChange={handleChange}
                              type="text"
                            />
                          </InputMask>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={3} mb={2}>
                      <Field
                        component={TextField}
                        label="Login"
                        id="login"
                        name="login"
                        value={values.login || ""}
                        onChange={handleChange}
                        error={Boolean(errors.login)}
                        helperText={errors.login}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={3} mb={2}>
                      <Field
                        component={TextField}
                        label="Senha"
                        id="password"
                        name="password"
                        value={values.password || ""}
                        onChange={handleChange}
                        error={Boolean(errors.password)}
                        helperText={errors.password}
                        type="password"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={3} mb={2}>
                      <Field
                        component={TextField}
                        label="Confirme sua senha"
                        id="password2"
                        name="password2"
                        value={values.password2 || ""}
                        onChange={handleChange}
                        error={Boolean(errors.password2)}
                        helperText={errors.password2}
                        type="password"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={3} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="role">Perfil</InputLabel>
                        <Field
                          component={Select}
                          native
                          disabled={userLogged.role >= 2}
                          value={values.role >= 0 ? values.role : -1}
                          onChange={handleChange}
                          error={Boolean(errors.role)}
                          label="Perfil"
                          inputProps={{
                            name: "role",
                            id: "role",
                          }}
                        >
                          <option
                            aria-label="Escolha um perfil"
                            value=""
                          ></option>
                          {userRoles.map((roleName, index) => {
                            return (
                              <option key={index} value={index}>
                                {roleName}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.role)}>
                          {errors.role}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {userId && (
                      <Grid item xs={3} mb={2}>
                        <label
                          htmlFor="avatar"
                          style={{ width: "100%", position: "relative" }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="avatar"
                            name="avatar"
                            type="file"
                            style={{ width: "100%" }}
                            onChange={(event) => {
                              if (event.target.files) {
                                setAvatar(event.target.files[0]);
                              }
                            }}
                          />
                          <Button
                            variant="contained"
                            color="info"
                            component="span"
                            style={{
                              position: "absolute",
                              left: "0",
                              width: "100%",
                            }}
                          >
                            Imagem para usuário...
                          </Button>
                        </label>
                      </Grid>
                    )}

                    {userId && (
                      <Grid item xs={3} mb={2}>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              checked={values.removeAvatar || false}
                              onChange={handleChange}
                              color="secondary"
                              id="removeAvatar"
                              name="removeAvatar"
                              value={values.removeAvatar || false}
                              inputProps={{ "aria-label": "removeAvatar" }}
                            />
                          }
                          label="Remover imagem?"
                        />
                      </Grid>
                    )}

                    <Grid item xs={4} mb={2}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!values.role || values.role < 3}
                      >
                        <InputLabel htmlFor="client">Cliente</InputLabel>
                        <Field
                          component={Select}
                          native
                          value={values.responsibleClientId || -1}
                          onChange={handleChange}
                          error={Boolean(errors.responsibleClientId)}
                          label="Cliente"
                          inputProps={{
                            name: "responsibleClientId",
                            id: "responsibleClientId",
                          }}
                        >
                          <option aria-label="Cliente" value=""></option>
                          {clients.map((client) => {
                            return (
                              <option key={client.id} value={client.id}>
                                {client.name}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText
                          error={Boolean(errors.responsibleClientId)}
                        >
                          {errors.responsibleClientId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={1} mb={2}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            checked={values.status || false}
                            onChange={handleChange}
                            color="primary"
                            id="status"
                            name="status"
                            value={values.status || false}
                            default={true}
                            inputProps={{ "aria-label": "status" }}
                          />
                        }
                        label="Ativo?"
                      />
                    </Grid>
                  </Grid>
                  {((userLogged && Number(userLogged.id) === Number(userId)) ||
                    userLogged.role <= 2) && (
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      endIcon={<DoneAllIcon />}
                    >
                      Salvar
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default UsersForm;
