import React from "react";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { chartColors } from "../../../config/app-info";

type Props = {
  title: string;
  data: number[];
  labels: string[];
};
const GlobalCriteriaChart: React.FC<Props> = ({ title, data, labels }) => {
  const options: ApexOptions = {
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return `${w.config.labels[seriesIndex]}: ${w.config.series[seriesIndex]}`;
      },
    },
    labels,
    title: {
      text: title,
      align: "center",
    },
    colors: chartColors,
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Chart options={options} series={data} type="polarArea" height="300" />
  );
};

export default withTheme(GlobalCriteriaChart);
