import React, { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { Navigate } from "react-router-dom";

const Logout: React.FC = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <Navigate to="/" />;
};

export default Logout;
