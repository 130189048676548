import { Colors } from "../../../theme/variants";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import React, { useEffect, useRef, useState } from "react";
import { useDashboard } from "../../../contexts/dashboard";
import VerticalServiceGroup from "../../../services/interfaces/VerticalServiceGroup";
import Tabs from "@mui/material/Tabs";
import AdvancementIndexComponent, { AdvancementIndexType } from "./AdvancementIndexComponent";
import {
  VerticalServiceProgressModel
} from "../../../services/interfaces/VerticalServiceProgressModel";
import { Client, Construction } from "../../../config/app-info";
import TableItem from "./TableItem";
import * as Styles from "./VerticalServicePageToPdf.styles";
import moment from "moment";
import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";
import { jsPDF } from "jspdf";


interface Props {
  constructionDates: { beginningDate: Date, endDate: Date, constructionDays: number, constructionWeekDays: number } | undefined
  tableContent:  VerticalServiceGroup[]
  selectedTab: string
  timePeriodListState: any[]
  showItemsSameDataByGroup: boolean,
  progressByConstruction: VerticalServiceProgressModel | undefined
}

const TabsEnum = {
  semanal: "semanal",
  mensal: "mensal",
  trimestral: "trimestral",
  semestral: "semestral",
  anual: "anual",
};

const VerticalServicePageToPdf = ({
  tableContent,
  constructionDates,
  showItemsSameDataByGroup,
  progressByConstruction,
  selectedTab = "Semanal",
  timePeriodListState = []
}: Props) => {
  const { constructionId, constructions, clientId, clients } = useDashboard();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [hoveredItem, setHoveredItem] = useState<any>(null);
  const [clickedItem, setClickedItem] = useState<any>(null);

  const pdfRef = useRef(null);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [selectedConstruction, setSelectedConstruction] =
    useState<Construction>();

  const [generating, setGenerating] = useState(false);
  const pdfRefPageHeader = useRef(null);
  const pdfRefTableHeader = useRef(null);
  const pdfRefTableContent = useRef(null);

  useEffect(() => {
    setSelectedConstruction(constructions.find((c) => c.id === constructionId));
    setSelectedClient(clients.find((c) => c.id === clientId));
  }, [constructionId]);

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const getPeriodBySelectedTab = (date: Date, endDate?: Date) => {
    let dateFormated = "";
    switch (selectedTab) {
      case TabsEnum.semanal:
        dateFormated = date
          .toLocaleDateString("pt-BR", {
            weekday: "short",
            day: "2-digit",
            month: "2-digit",
          })
          .replace(".,", "");
        break;
      case TabsEnum.mensal:
        const startDate = date.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        });
        const endDateFormatted =
          endDate?.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          }) || "";
        dateFormated = `${startDate} à ${endDateFormatted}`;
        break;
      case TabsEnum.trimestral:
        const startDateTrimester = date.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        });
        const endDateTrimester = endDate?.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        });
        dateFormated = `${startDateTrimester} à ${endDateTrimester}`;
        break;
      case TabsEnum.semestral:
        dateFormated = date.toLocaleDateString("pt-BR", {
          month: "long",
        });
        break;
      case TabsEnum.anual:
        dateFormated = date.toLocaleDateString("pt-BR", {
          month: "short",
        });
        dateFormated +=
          "/" +
          date.toLocaleDateString("pt-BR", {
            year: "numeric",
          });
        dateFormated = dateFormated.replace(".", "");
        break;
      default:
        break;
    }

    return dateFormated;
  };

  const getPeriodBySelectedTabForPrintedState = (
    dateAsString: string,
    endDateAsString: string
  ) => {
    let dateFormated = "";

    const date = new Date(dateAsString);
    const endDate = new Date(endDateAsString);

    if (!date || !endDate) {
      return "";
    }

    switch (selectedTab) {
      case TabsEnum.semanal:
        const firstDay = date
          .toLocaleDateString("pt-BR", {
            day: "2-digit",
          })
          .replace(".,", "");
        const secoundDay = endDate?.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        });
        dateFormated = `${firstDay} à ${secoundDay}`;
        break;
      case TabsEnum.mensal:
        const startDate = date.toLocaleDateString("pt-BR", {
          month: "long",
        });
        dateFormated = startDate;
        break;
      case TabsEnum.trimestral:
        const startDateTrimester = date.toLocaleDateString("pt-BR", {
          month: "short",
        });
        const endDateTrimester = endDate?.toLocaleDateString("pt-BR", {
          month: "short",
        });
        dateFormated =
          `Trimestral - ${startDateTrimester} à ${endDateTrimester}`.replaceAll(
            ".",
            ""
          );
        break;
      case TabsEnum.semestral:
        const startDateFormated = date.toLocaleDateString("pt-BR", {
          month: "short",
        });
        const endDateFormated = endDate?.toLocaleDateString("pt-BR", {
          month: "short",
        });
        dateFormated =
          `Semestral - ${startDateFormated} à ${endDateFormated}`.replaceAll(
            ".",
            ""
          );
        break;
      case TabsEnum.anual:
        dateFormated +=
          "Anual " +
          date.toLocaleDateString("pt-BR", {
            year: "numeric",
          });
        dateFormated = dateFormated.replace(".", "");
        break;
      default:
        break;
    }

    return dateFormated;
  };

  const reportFileName = `vertical-de-servicos_.pdf`;

  const addPdfRowRecursively = (doc: any, header: any, rows: any, i = 0) => {
    if (i == 0) {
      doc.html(header, {
        autoPaging: "text",
        y: 103 + ((i + 1) * 22),
        x: 13,
        html2canvas: {
          scale: 0.42
        },
        callback: function(doc: any) {
          doc.html(rows[i], {
            autoPaging: "text",
            y: 125 + ((i + 1) * 22),
            x: 13,
            html2canvas: {
              scale: 0.42
            },
            callback: function(doc: any) {
              if (i == rows.length - 1) {
                doc.save(reportFileName);
              } else {
                addPdfRowRecursively(doc, header, rows, i + 1)
              }
            }
          })
        }
      })
    } else if (i < 13) {
      doc.html(rows[i], {
        autoPaging: "text",
        y: 125 + ((i + 1) * 22),
        x: 13,
        html2canvas: {
          scale: 0.42
        },
        callback: function (doc: any) {
          if (i == rows.length - 1) {
            setGenerating(false);
            doc.save(reportFileName);
          } else {
            addPdfRowRecursively(doc, header, rows, i + 1)
          }
        }
      })
    } else {
      const mod = i % 13

      if (mod == 0) {
        doc.html(header, {
          autoPaging: "text",
          y: (doc.internal.pageSize.height * Math.floor(i / 13)) + ((mod + 1) * 22),
          x: 13,
          html2canvas: {
            scale: 0.42
          },
          callback: function(doc: any) {
            doc.html(rows[i], {
              autoPaging: "text",
              y: (doc.internal.pageSize.height * Math.floor(i / 13)) + 22 + ((mod + 1) * 22),
              x: 13,
              html2canvas: {
                scale: 0.42
              },
              callback: function (doc: any) {
                if (i == rows.length - 1) {
                  setGenerating(false);
                  doc.save(reportFileName);
                } else {
                  addPdfRowRecursively(doc, header, rows, i + 1)
                }
              }
            })
          }
        })
      }  else {
        doc.html(rows[i], {
          autoPaging: "text",
          y: (doc.internal.pageSize.height * Math.floor(i / 13)) + 22 + ((mod + 1) * 22),
          x: 13,
          html2canvas: {
            scale: 0.42
          },
          callback: function (doc: any) {
            if (i == rows.length - 1) {
              setGenerating(false);
              doc.save(reportFileName);
            } else {
              addPdfRowRecursively(doc, header, rows, i + 1)
            }
          }
        })
      }
    }
  }

  const shareOnClick = async () => {
    setGenerating(true);

    const inputPagePageHeader = pdfRefPageHeader.current;
    const inputTableHeader = pdfRefTableHeader.current;
    const inputTableContent: HTMLElement | null = pdfRefTableContent.current as HTMLElement | null;

    if (!inputPagePageHeader) return;
    if (!inputTableHeader) return;
    if (!inputTableContent) return;

    const rows = inputTableContent?.children[0]?.children[1].children
    const rowsHeader = inputTableContent?.children[0]?.children[0]

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
    });

    // doc.addFileToVFS('NotoSans-Regular.ttf', NotoSansRegular);
    // doc.addFileToVFS('NotoSans-Bold.ttf', NotoSansBold);
    //
    // doc.addFont('NotoSans-Regular.ttf', 'NotoSans-Regular', 'normal');
    // doc.addFont('NotoSans-Bold.ttf', 'NotoSans-Bold', 'bold');
    //
    // console.log(doc.getFontList())

    // doc.setFont('Helvetica')

    doc.html(inputPagePageHeader, {
      margin: [15, 0, 15, 0],
      autoPaging: "text",
      html2canvas: {
        scale: 0.42
      },
      callback: function (doc) {
        doc.html(inputTableHeader, {
          autoPaging: "text",
          y: 100,
          html2canvas: {
            scale: 0.42
          },
          callback: function (doc) {
            addPdfRowRecursively(doc, rowsHeader, rows)
          }
        })
      }
    })
  };

  const pdfModel = (
    <Container>
      <Styles.PageContainer>
        {state === BaseState.success && !isConstructionNotSelected() ? (
          <Styles.ContentContainer>
            <Styles.HeaderContainer ref={pdfRefPageHeader}>
              <Styles.AboutContainer>
                <Styles.Title>Vertical de Serviços</Styles.Title>
                {tableContent.length > 0 ? (
                  <Styles.AdvancementContainer>
                    <AdvancementIndexComponent
                      title="Índice de avanços físicos"
                      value={progressByConstruction?.physicalProgress || 0}
                      type={AdvancementIndexType.PHYSICAL}
                      budgetUsed={
                        progressByConstruction?.currentFinancialCoast || 0
                      }
                      showMaterialUserDescription={false}
                      showBudgetDescription={false}
                    />
                    <AdvancementIndexComponent
                      title="Índice de avanços financeiros"
                      value={progressByConstruction?.financialProgress || 0}
                      type={AdvancementIndexType.FINANCIAL}
                      budgetUsed={
                        progressByConstruction?.currentFinancialCoast || 0
                      }
                      showMaterialUserDescription={false}
                      showBudgetDescription={false}
                    />
                  </Styles.AdvancementContainer>
                ) : null}
              </Styles.AboutContainer>
              <Styles.PrintStatePageDescriptionContainer>
                <Styles.PrintStatePageDescriptionText>
                  {`Empresa: ${selectedClient?.name}`}
                </Styles.PrintStatePageDescriptionText>
                <Styles.PrintStatePageDescriptionText>
                  {`Obra: ${selectedConstruction?.name}`}
                </Styles.PrintStatePageDescriptionText>

                <Styles.PrintStatePageDescriptionText>
                  {`Período: ${getPeriodBySelectedTabForPrintedState(
                    timePeriodListState[0]?.startDateAsDate,
                    timePeriodListState[timePeriodListState.length - 1]
                      ?.endDateAsDate
                  )}`}
                </Styles.PrintStatePageDescriptionText>
              </Styles.PrintStatePageDescriptionContainer>
            </Styles.HeaderContainer>

            <Styles.TableContainer ref={pdfRefTableHeader}>
              <Styles.TableHeader>
                <span>
                   { (moment(constructionDates?.beginningDate).isValid() && moment(constructionDates?.endDate).isValid()) && (
                     `
                        Período obra: 
                        ${moment(constructionDates?.beginningDate).format('DD/MM/YYYY')} - ${moment(constructionDates?.endDate).format('DD/MM/YYYY')} 
                        (${constructionDates?.constructionDays} dias / ${constructionDates?.constructionWeekDays} úteis)
                      `
                   )}
                </span>
                <Styles.TabContainer>
                  <Tabs
                    value={selectedTab}
                    onChange={() => null}
                    style={{ width: "100%" }}
                  >
                    <Styles.CustomTab value={TabsEnum.semanal} label="Semanal" />
                    <Styles.CustomTab value={TabsEnum.mensal} label="Mensal" />
                    <Styles.CustomTab value={TabsEnum.trimestral} label="Trimestral" />
                    <Styles.CustomTab value={TabsEnum.semestral} label="Semestral" />
                    <Styles.CustomTab value={TabsEnum.anual} label="Anual" />
                  </Tabs>
                </Styles.TabContainer>
              </Styles.TableHeader>
            </Styles.TableContainer>

            <Styles.TableContainer ref={pdfRefTableContent}>
              <Styles.HorizontalListPrimaryContainer>
                <Styles.VerticalListRoot columnQuantity={timePeriodListState.length}>
                  {tableContent.length === 0 ? null : (
                    <Styles.EmptyGridItem isPrintState={true} />
                  )}

                  {timePeriodListState.map((item) => {
                    return (
                      <Styles.ItemVerticalListHeader
                        key={item.space}
                        style={{
                          minWidth:
                            tableContent.length === 0
                              ? "180px"
                              : "auto",
                          maxWidth: "100%",
                          borderTopLeftRadius:
                            timePeriodListState.indexOf(item) === 0
                              ? "16px"
                              : "0px",
                          borderTopRightRadius:
                            timePeriodListState.indexOf(item) ===
                            timePeriodListState.length - 1
                              ? "16px"
                              : "0px",
                        }}
                      >
                        {getPeriodBySelectedTab(
                          new Date(item.startDateAsDate),
                          new Date(item.endDateAsDate)
                        )}
                      </Styles.ItemVerticalListHeader>
                    );
                  })}
                </Styles.VerticalListRoot>

                <Styles.HorizontalListContainer>
                  {tableContent.map((serviceType) => {
                    return (
                      <React.Fragment key={serviceType.id}>
                        <Styles.FullItemContainer
                          columnQuantity={timePeriodListState.length + 1}
                        >
                          <Styles.ItemHeadTypeIndicator>
                            {serviceType.name}
                          </Styles.ItemHeadTypeIndicator>
                          {timePeriodListState.map((value, index) => {
                            return (
                              <Styles.ItemHeaderGridContainerEmpty
                                key={"timePeriodList - " + index}
                                isPrintState={true}
                              />
                            );
                          })}
                        </Styles.FullItemContainer>

                        {serviceType.services.map((item) => {
                          return (
                            <React.Fragment key={item.uniqueApplicationId}>
                              {
                                <Styles.FullItemEmptyContainerCustomGrid
                                  gridTemplate={`190px ${(item as any).gridTemplate}`}
                                >
                                  <Styles.ItemHeadServiceIndicator>
                                    {item.name}
                                  </Styles.ItemHeadServiceIndicator>
                                  {(item as any).gridItems.map(
                                    (gridItem: any, index: any) => {
                                      return (
                                        <TableItem
                                          gridItem={gridItem}
                                          index={index}
                                          serviceType={serviceType}
                                          item={item}
                                          onMouseOver={() => null}
                                          onMouseLeave={() => null}
                                          serviceOnClick={() => null}
                                          hoveredItem={hoveredItem}
                                          clickedItem={clickedItem}
                                          moreItemsOnClick={() => null}
                                          showOnlyOneService={
                                            showItemsSameDataByGroup
                                          }
                                          serviceClickEditActionCallback={() => null}
                                          serviceClickDeleteActionCallback={() => null}
                                          openUploadImageDialog={() => null}
                                          openUpdateServiceProgressDialog={() => null}
                                          addPurchasingContractingPlanOnClick={() => null}
                                        />
                                      );
                                    }
                                  )}
                                </Styles.FullItemEmptyContainerCustomGrid>
                              }
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </Styles.HorizontalListContainer>
              </Styles.HorizontalListPrimaryContainer>
            </Styles.TableContainer>
          </Styles.ContentContainer>
        ) : null}
      </Styles.PageContainer>
    </Container>
  )

  return (
    <ComponentWrapper>
      <LoadingButton
        variant="outlined"
        loadingPosition={"start"}
        loading={generating}
        disabled={generating}
        style={{
          color: "#fff",
          backgroundColor: Colors.primaryColor,
          height: "40px",
          borderRadius: "20px",
          width: "160px"
        }}
        startIcon={null}
        onClick={() => shareOnClick()}
      >
        Exportar PDF
      </LoadingButton>
      {pdfModel}
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div`
    width: 100vw
`

const Container = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 97%;
`;

export default VerticalServicePageToPdf;
