import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import { differenceInDays } from "date-fns";
import { DataGantt, restrictionPriorities } from "../../../config/app-info";

type Props = {
  callback?: (src: string) => void;
  constructionName: string;
  dataGanttVeryLow: DataGantt[];
  dataGanttLow: DataGantt[];
  dataGanttMiddle: DataGantt[];
  dataGanttHigh: DataGantt[];
  dataGanttVeryHigh: DataGantt[];
};

export const GanttChart: React.FC<Props> = ({
  callback,
  constructionName,
  dataGanttVeryLow,
  dataGanttLow,
  dataGanttMiddle,
  dataGanttHigh,
  dataGanttVeryHigh,
}) => {
  const dataVeryHigh = [];
  if (dataGanttVeryHigh !== undefined && dataGanttVeryHigh.length > 0) {
    for (let i = 0; i < dataGanttVeryHigh.length; i++) {
      const object = {
        x: dataGanttVeryHigh[i].actionName,
        y: [
          dataGanttVeryHigh[i].dtBegin.getTime(),
          dataGanttVeryHigh[i].dtEnd.getTime(),
        ],
        fillColor: "#B22222",
      };
      dataVeryHigh.push(object);
    }
  }

  const dataHigh = [];
  if (dataGanttHigh !== undefined && dataGanttHigh.length > 0) {
    for (let i = 0; i < dataGanttHigh.length; i++) {
      const object = {
        x: dataGanttHigh[i].actionName,
        y: [
          dataGanttHigh[i].dtBegin.getTime(),
          dataGanttHigh[i].dtEnd.getTime(),
        ],
        fillColor: "#FF8C00",
      };
      dataHigh.push(object);
    }
  }

  const dataMiddle = [];
  if (dataGanttMiddle !== undefined && dataGanttMiddle.length > 0) {
    for (let i = 0; i < dataGanttMiddle.length; i++) {
      const object = {
        x: dataGanttMiddle[i].actionName,
        y: [
          dataGanttMiddle[i].dtBegin.getTime(),
          dataGanttMiddle[i].dtEnd.getTime(),
        ],
        fillColor: "#F0E68C",
      };
      dataMiddle.push(object);
    }
  }

  const dataLow = [];
  if (dataGanttLow !== undefined && dataGanttLow.length > 0) {
    for (let i = 0; i < dataGanttLow.length; i++) {
      const object = {
        x: dataGanttLow[i].actionName,
        y: [dataGanttLow[i].dtBegin.getTime(), dataGanttLow[i].dtEnd.getTime()],
        fillColor: "#1E90FF",
      };
      dataLow.push(object);
    }
  }

  const dataVeryLow = [];
  if (dataGanttVeryLow !== undefined && dataGanttVeryLow.length > 0) {
    for (let i = 0; i < dataGanttVeryLow.length; i++) {
      const object = {
        x: dataGanttVeryLow[i].actionName,
        y: [
          dataGanttVeryLow[i].dtBegin.getTime(),
          dataGanttVeryLow[i].dtEnd.getTime(),
        ],
        fillColor: "#00FFFF",
      };
      dataVeryLow.push(object);
    }
  }

  const series = [
    {
      name: restrictionPriorities[4],
      data: dataVeryHigh,
    },
    {
      name: restrictionPriorities[3],
      data: dataHigh,
    },
    {
      name: restrictionPriorities[2],
      data: dataMiddle,
    },
    {
      name: restrictionPriorities[1],
      data: dataLow,
    },
    {
      name: restrictionPriorities[0],
      data: dataVeryLow,
    },
  ];

  const options: ApexOptions = {
    chart: {
      height: "100%",
      events: {
        mounted: async function (chartContext) {
          if (callback) {
            const dataURI = await chartContext.exports.dataURI();
            callback(dataURI.imgURI);
          }
        },
      },
      animations: {
        enabled: false,
      },
      defaultLocale: "br",
      locales: [
        {
          name: "br",
          options: {
            months: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ],
            shortMonths: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez",
            ],
            days: [
              "Domingo",
              "Segunda",
              "Terça",
              "Quarta",
              "Quinta",
              "Sexta",
              "Sábado",
            ],
            shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            toolbar: {
              download: "Baixar SVG",
              selection: "Seleção",
              selectionZoom: "Seleção Zoom",
              zoomIn: "Zoom +",
              zoomOut: "Zoom -",
              pan: "Panorâmica",
              reset: "Redefinir Zoom",
            },
          },
        },
      ],
    },
    title: {
      text: `Obra: ${constructionName}`,
      align: "center",
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        barHeight: "100%",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number[], opts) {
        //var label = opts.w.globals.labels[opts.dataPointIndex];

        const dtBegin = new Date(val[0] + 3 * 60 * 60 * 1000);
        const dtEnd = new Date(val[1] + 3 * 60 * 60 * 1000);

        const diff = differenceInDays(dtEnd, dtBegin);
        return diff + (diff > 1 ? " dias" : " dia");
      },
      style: {
        colors: ["#fff", "#0b3980", "#0b3980", "#fff"],
      },
    },
    colors: ["#B22222", "#FF8C00", "#F0E68C", "#1E90FF", "#00FFFF"],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      seriesName: "Ações",
      show: true,
      axisTicks: {
        show: true,
      },
    },
    grid: {
      row: {
        colors: ["#f3f4f5", "#fff"],
        opacity: 1,
      },
    },
  };

  return (
    <Chart options={options} series={series} type="rangeBar" height="700" />
  );
};
