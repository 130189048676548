import { User } from "../config/app-info";
import { api } from "./api";

type Response = {
  message: string;
  user: User;
};

export async function signIn(
  login: string,
  password: string
): Promise<Response> {
  try {
    const response = await api.post("api/authentication/login", {
      login,
      password,
    });

    if (response.status === 200) {
      return {
        message: "OK",
        user: response.data,
      };
    }

    return {
      message: String(response.status),
      user: {} as User,
    };
  } catch (error) {
    return {
      message: "Login ou senha inválidos",
      user: {} as User,
    };
  }
}

export async function checkAuth(): Promise<boolean> {
  try {
    const response = await api.get("api/authentication");
    return response.status === 200;
  } catch (error) {
    return false;
  }
}
