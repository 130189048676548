import React from "react";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
//import { chartColors } from "../../../config/app-info";

type Props = {
  title: string;
  data: number[];
  labels: string[];
};
const ActionsAnalysisChart: React.FC<Props> = ({ title, data, labels }) => {
  const options: ApexOptions = {
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return `${w.config.labels[seriesIndex]}: ${w.config.series[seriesIndex]}`;
      },
    },
    labels,
    title: {
      text: title,
      align: "center",
    },
    colors: [
      "#4169E1",
      "#FF8C00",
      "#A9A9A9",
      "#FFD700",
      "#1E90FF",
      "#32CD32",
      "#483D8B",
      "#A0522D",
      "#778899",
      "#9370DB",
      "#CD853F",
      "#008B8B",
      "#9ACD32",
      "#DC143C",
    ],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Chart options={options} series={data} type="polarArea" height="300" />
  );
};

export default withTheme(ActionsAnalysisChart);
