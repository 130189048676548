import styled from "styled-components";
import { Colors } from "../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { Tab } from "@mui/material";

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh);
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  position: relative;
  align-content: start;
`;

const TitleContainer = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr max-content max-content;
  margin-top: 82px;
  column-gap: 24px;
  align-items: center;
  margin-right: 32px;
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.primaryColor};
  margin-top: 12px;
  padding-left: 32px;
  max-width: 100%;
  height: fit-content;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 32px;
    // go to next-line if the text is too long
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

const TabContainer = styled.div`
  margin-left: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const ListPurchasingContractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 8px;
  overflow: hidden;
  height: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 100%;
    overflow-x: auto;
  }
`;

const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const MobileMainButtonContainer = styled.div`
  width: 101px;
  place-self: end;
  margin-top: 24px;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const EmptyListContainer = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.textColorBlack};
  width: 454px;
  place-self: center;
  margin-top: 146px;
  text-align: center;
`;

export {
  CenterContainer,
  PageContainer,
  TitleContainer,
  Description,
  ContentContainer,
  TabContainer,
  CustomTab,
  ListPurchasingContractionContainer,
  MobileMainButtonContainer,
  EmptyListContainer,
  ListContainer,
};
