export enum ProgressUpdateType {
  PHYSICAL = "PHYSICAL",
  FINANCIAL_WORKFORCE = "FINANCIAL_WORKFORCE",
  FINANCIAL_MATERIAL = "FINANCIAL_MATERIAL",
}

export interface ProgressUpdate {
  value: number;
  accumulatedPercentageValue: number;
  date: string;
  id: number;
  progressType: ProgressUpdateType;
}

export interface VerticalServiceProgressModel {
  physicalProgress: number;
  financialProgress: number;
  currentFinancialCoast?: number;
  financialProgressAsCurrency?: number;
  physicalProgressAsTotalValue?: number;
  isEmpty?: boolean;
  remainPhyscalValue?: number;
  totalPhysicalValue?: number;
  totalFinancialValue?: number;
  workForceCostRealized?: number;
  materialCostRealized?: number;
  allPhysicalUpdates?: ProgressUpdate[];
  allFinancialUpdates?: ProgressUpdate[];
}
