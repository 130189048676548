import CryptoJS from "crypto-js";

const appSecretKey = "eapengenharia-key@1980";

export const encrypt = (data: string): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), appSecretKey).toString();
};

export const decrypt = (cipherText: string): string => {
  const bytes = CryptoJS.AES.decrypt(cipherText, appSecretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
