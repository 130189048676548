import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  //Button,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { GanttChart } from "../../components/charts/GanttChart";
import { Construction, DataGantt } from "../../config/app-info";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

type Props = {
  section: string;
};

const ActionsPlanningGantt: React.FC<Props> = ({ section }) => {
  const [constructions, setConstructions] = useState<Construction[]>([]);
  const [constructionId, setConstructionId] = useState(0);
  const [constructionName, setConstructionName] = useState("");
  const [dataGanttVeryHigh, setDataGanttVeryHigh] = useState<DataGantt[]>([]);
  const [dataGanttHigh, setDataGanttHigh] = useState<DataGantt[]>([]);
  const [dataGanttMiddle, setDataGanttMiddle] = useState<DataGantt[]>([]);
  const [dataGanttLow, setDataGanttLow] = useState<DataGantt[]>([]);
  const [dataGanttVeryLow, setDataGanttVeryLow] = useState<DataGantt[]>([]);
  const [dtBeginDate, setDtBeginDate] = useState("");
  const [dtEndDate, setDtEndDate] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    if (user.id) {
      const responsibleClientId = user.responsibleClientId
        ? Number(user.responsibleClientId)
        : 0;

      let endpoint = `api/constructions/active`;
      if (responsibleClientId > 0) {
        endpoint = `api/constructions/client/${responsibleClientId}`;
      }

      const fetchConstructions = async () => {
        const response = await api.get(endpoint);
        setConstructions(response.data);
      };
      fetchConstructions();

      if (constructionId > 0) {
        const fetchBeginDateByConstructionId = async () => {
          const responseFirstBeginDate = await api.get(
            `api/actions/first-begin-date/construction/${constructionId}`
          );
          if (responseFirstBeginDate.data) {
            setDtBeginDate(responseFirstBeginDate.data);
            const today = new Date();
            const month = today.getMonth() + 1;
            const year = today.getFullYear();

            setDtEndDate(`${year}-${String(month + 1).padStart(2, "0")}-15`);
          }
        };
        fetchBeginDateByConstructionId();

        const fetchConstructionName = async () => {
          const responseConstructionName = await api.get(
            `api/constructions/${constructionId}`
          );
          if (responseConstructionName.data) {
            setConstructionName(responseConstructionName.data.name);
          }
        };
        fetchConstructionName();

        if (dtBeginDate && dtEndDate) {
          const tempDataGanttVeryHigh: DataGantt[] = [];
          const fetchActionsVeryHigh = async () => {
            const responseVeryHigh = await api.post(`api/actions/gantt`, {
              constructionId,
              priority: 4,
              dtBegin: dtBeginDate,
              dtEnd: dtEndDate,
            });
            if (responseVeryHigh.data.length) {
              for (const row of responseVeryHigh.data) {
                tempDataGanttVeryHigh.push({
                  criteriaName: row.criteriaName,
                  restrictionName: row.restrictionName,
                  actionName: row.actionName,
                  dtBegin: new Date(row.whenBegin),
                  dtEnd: new Date(row.whenEnd),
                  responsibleUsers: row.responsibleUsers,
                });
              }
            }
            setDataGanttVeryHigh(tempDataGanttVeryHigh);
          };
          fetchActionsVeryHigh();

          const tempDataGanttHigh: DataGantt[] = [];
          const fetchActionsHigh = async () => {
            const responseHigh = await api.post(`api/actions/gantt`, {
              constructionId,
              priority: 3,
              dtBegin: dtBeginDate,
              dtEnd: dtEndDate,
            });
            if (responseHigh.data.length) {
              for (const row of responseHigh.data) {
                tempDataGanttHigh.push({
                  criteriaName: row.criteriaName,
                  restrictionName: row.restrictionName,
                  actionName: row.actionName,
                  dtBegin: new Date(row.whenBegin),
                  dtEnd: new Date(row.whenEnd),
                  responsibleUsers: row.responsibleUsers,
                });
              }
            }
            setDataGanttHigh(tempDataGanttHigh);
          };
          fetchActionsHigh();

          const tempDataGanttMiddle: DataGantt[] = [];
          const fetchActionsMiddle = async () => {
            const responseMiddle = await api.post(`api/actions/gantt`, {
              constructionId,
              priority: 2,
              dtBegin: dtBeginDate,
              dtEnd: dtEndDate,
            });
            if (responseMiddle.data.length) {
              for (const row of responseMiddle.data) {
                tempDataGanttMiddle.push({
                  criteriaName: row.criteriaName,
                  restrictionName: row.restrictionName,
                  actionName: row.actionName,
                  dtBegin: new Date(row.whenBegin),
                  dtEnd: new Date(row.whenEnd),
                  responsibleUsers: row.responsibleUsers,
                });
              }
            }
            setDataGanttMiddle(tempDataGanttMiddle);
          };
          fetchActionsMiddle();

          const tempDataGanttLow: DataGantt[] = [];
          const fetchActionsLow = async () => {
            const responseLow = await api.post(`api/actions/gantt`, {
              constructionId,
              priority: 1,
              dtBegin: dtBeginDate,
              dtEnd: dtEndDate,
            });
            if (responseLow.data.length) {
              for (const row of responseLow.data) {
                tempDataGanttLow.push({
                  criteriaName: row.criteriaName,
                  restrictionName: row.restrictionName,
                  actionName: row.actionName,
                  dtBegin: new Date(row.whenBegin),
                  dtEnd: new Date(row.whenEnd),
                  responsibleUsers: row.responsibleUsers,
                });
              }
            }
            setDataGanttLow(tempDataGanttLow);
          };
          fetchActionsLow();

          const tempDataGanttVeryLow: DataGantt[] = [];
          const fetchActionsVeryLow = async () => {
            const responseVeryLow = await api.post(`api/actions/gantt`, {
              constructionId,
              priority: 0,
              dtBegin: dtBeginDate,
              dtEnd: dtEndDate,
            });
            if (responseVeryLow.data.length) {
              for (const row of responseVeryLow.data) {
                tempDataGanttVeryLow.push({
                  criteriaName: row.criteriaName,
                  restrictionName: row.restrictionName,
                  actionName: row.actionName,
                  dtBegin: new Date(row.whenBegin),
                  dtEnd: new Date(row.whenEnd),
                  responsibleUsers: row.responsibleUsers,
                });
              }
            }
            setDataGanttVeryLow(tempDataGanttVeryLow);
          };
          fetchActionsVeryLow();
        }
      }
    }
  }, [user, constructionId, dtBeginDate, dtEndDate]);

  const handleChangeConstruction = async (construction: number) => {
    setConstructionId(construction);
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item xs={8} lg={8} xl={8}>
          <Typography variant="h3" gutterBottom>
            {`${section}`}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <Grid container alignItems="center" spacing={6} p={5}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="construction">Obra</InputLabel>
                <Select
                  defaultValue={String(constructionId)}
                  labelId="construction"
                  id="construction"
                  value={constructionId ? String(constructionId) : "0"}
                  onChange={(event: SelectChangeEvent) =>
                    handleChangeConstruction(Number(event.target.value))
                  }
                  label="Obra"
                >
                  <MenuItem key="0" value="0" disabled>
                    <em>Escolha uma obra</em>
                  </MenuItem>
                  {constructions.map((construction) => {
                    return (
                      <MenuItem key={construction.id} value={construction.id}>
                        {construction.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {(dataGanttVeryHigh.length > 0 ||
            dataGanttHigh.length > 0 ||
            dataGanttMiddle.length > 0 ||
            dataGanttLow.length > 0 ||
            dataGanttVeryLow.length > 0) && (
            <GanttChart
              constructionName={constructionName}
              dataGanttVeryLow={dataGanttVeryLow}
              dataGanttLow={dataGanttLow}
              dataGanttMiddle={dataGanttMiddle}
              dataGanttHigh={dataGanttHigh}
              dataGanttVeryHigh={dataGanttVeryHigh}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ActionsPlanningGantt;
