import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

interface Props {
    pageDescription: string;
}

const EmptyClientAndConstructionState: React.FC<Props> = ({pageDescription}) => {
  return (
    <QuadroAcompanhamento>
      <Texto color="#00030C" style={{ fontWeight: 400 }}>
        Para visualizar {pageDescription}, selecione o{" "}
      </Texto>
      <Texto color="#855000" style={{ fontWeight: 700 }}>
        cliente
      </Texto>
      <Texto color="#00030C" style={{ fontWeight: 400 }}>
        {" "}
        e a{" "}
      </Texto>
      <Texto color="#855000" style={{ fontWeight: 700 }}>
        obra
      </Texto>
      <Texto color="#00030C" style={{ fontWeight: 400 }}>
        {" "}
        correspondente na barra de ações no topo da tela.
      </Texto>
    </QuadroAcompanhamento>
  );
};


const QuadroAcompanhamento = styled.div`
  width: 508px;
  text-align: center;
  align-self: center;
  justify-self: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Texto = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  font-family: 'Noto Sans';
  word-wrap: break-word;
`;

export default EmptyClientAndConstructionState;
