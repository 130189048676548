import styled from "styled-components";
import ReactBaseComponent from "../BaseComponents/ReactBaseComponent";
import Dialog from "@mui/material/Dialog";
import VerticalServiceModel from "../../services/interfaces/VerticalServiceModel";
import { Colors } from "../../theme/variants";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../Button/ButtonComponent";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { width } from "@mui/system";

interface PopUpBaseComponentProps {
  titte?: string;
  isOpen: boolean;
  isLoading?: boolean;
  isMobile?: boolean;
  cancelOnClick: () => void;
  confirmOnClick: () => void;
  closeOnClick?: () => void;
  positiveButtonText?: string;
  negativeButtonText?: string;
  enableConfirmButton?: boolean;
  children?: React.ReactNode;
  isButtonContainerEnabled?: boolean;
  width?: number;
}

const PopUpBaseComponent: React.FC<PopUpBaseComponentProps> = ({
  titte,
  isOpen,
  isLoading,
  cancelOnClick,
  confirmOnClick,
  closeOnClick,
  positiveButtonText,
  negativeButtonText,
  enableConfirmButton,
  children,
  isButtonContainerEnabled,
  width,
  isMobile,
}) => {
  return (
    <BootstrapDialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root":
            width && isMobile !== true
              ? {
                  width: `${width + 64}px`,
                  maxWidth: `${width + 64}px`,
                }
              : {
                  margin: "16px",
                  maxHeight: "none",
                },
        },
      }}
      open={isOpen}
      disableEscapeKeyDown={false}
    >
      <RootContainer>
        <HeaderContainer isMobile={isMobile}>
          <DialogTitle>{titte}</DialogTitle>
          {isMobile !== true && (
            <Button
              variant="text"
              style={{ color: "#000" }}
              startIcon={<Close />}
              onClick={() => closeOnClick?.()}
            />
          )}
        </HeaderContainer>
        <ContentContainer>{children}</ContentContainer>
        {isButtonContainerEnabled !== false ? (
          <ButtonsContainer>
            <ButtonComponent
              text={negativeButtonText || "Cancelar"}
              type={ButtonType.TRANSPARENT}
              onClick={() => cancelOnClick?.()}
            />
            <ButtonComponent
              state={
                enableConfirmButton
                  ? ButtonState.DEFAULT_ENABLED
                  : ButtonState.DISABLED
              }
              text={positiveButtonText || "Confirmar"}
              onClick={() => confirmOnClick?.()}
            />
          </ButtonsContainer>
        ) : null}
      </RootContainer>
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    overflow-y: hidden;
    overflow-x: hidden;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 100%;
    }
  }
`;

const RootContainer = styled.div`
  height: fit-content;
  display: grid;
  grid-template-rows: min-content min-content min-content;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 825px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  max-width: 825px;
  height: 369px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const HeaderContainer = styled.div<{ isMobile?: boolean }>`
  display: grid;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: ${(props) =>
    props.isMobile === true ? "none" : `2px solid ${Colors.primaryColor};`};
  grid-template-columns: 1fr auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  display: grid;

  row-gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 16px;
    grid-template-rows: min-content min-content min-content;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 16px;
    width: 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  padding-left: 32px;
  padding-right: 24px;
  padding-bottom: 32px;
  place-self: end;
  column-gap: 16px;
  margin-top: 24px;
`;

export default PopUpBaseComponent;
