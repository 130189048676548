import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  ptBR,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Button,
} from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { PictureAsPdf } from "@mui/icons-material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import Alert from "../../../components/Alert";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { useParams } from "react-router-dom";
import { ActionItem } from "../../../config/app-info";
import { Check as CheckIcon } from "@mui/icons-material";
import SearchBar from "../../../components/SearchBar";
import ActionsPlanPdf from "./pdf";
import { stylesPdf } from "./style";

if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WrapperDataGrid = styled.div`
  width: 100%;
`;

const ContentDataGrid = styled.div`
  height: 48vh;
  overflow-y: auto;
`;

type Props = {
  section: string;
};

const checkboxColDef: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  renderHeader: () => "Conc.",
  type: "string",
};

const ActionsPlanEdit: React.FC<Props> = ({ section }) => {
  const [constructionName, setConstructionName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [actions, setActions] = useState<ActionItem[]>([]);
  const [actionsByCriteria, setActionsByCriteria] = useState<ActionItem[][]>(
    []
  );
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [labelDtBegin, setLabelDtBegin] = useState("");
  const [labelDtEnd, setLabelDtEnd] = useState("");
  const apiRef = useGridApiRef();
  const { user } = useAuth();
  let { id: constructionEditId } = useParams();

  useEffect(() => {
    if (user.id) {
      const fetchConstructionName = async () => {
        try {
          const response = await api.get(
            `api/constructions/info/${constructionEditId}`
          );
          setConstructionName(response.data.name);
          setManagerName(response.data.managerUserId.name);
        } catch (error) {
          console.log(error);
        }
      };
      fetchConstructionName();

      const fetchActionsItems = async () => {
        try {
          const response = await api.post(`api/actions/items`, {
            constructionId: constructionEditId,
            dtBegin: "",
            dtEnd: "",
            dateType: "0",
            criteria: [],
            responsibleUser: [],
            status: [],
          });

          if (response.status === 201) {
            setActions(response.data);

            const rowIds = [];
            const arrayActionsByCriteria = [];
            let tempActions = [];
            let criteriaName =
              response.data.length > 0 ? response.data[0].criteria : "";

            if (response.data.length > 0) {
              for (const row of response.data) {
                if (row.status) {
                  rowIds.push(row.id);
                }

                if (criteriaName !== row.criteria) {
                  arrayActionsByCriteria.push(tempActions);
                  criteriaName = row.criteria;
                  tempActions = [];
                }

                tempActions.push(row);
              }
              arrayActionsByCriteria.push(tempActions);
            }

            setActionsByCriteria(arrayActionsByCriteria);

            if (rowIds.length) {
              apiRef.current.selectRows(rowIds);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchActionsItems();
    }
  }, [apiRef, constructionEditId, user.id]);

  const columns: GridColDef[] = [
    {
      field: "criteria",
      headerName: "Critério",
      width: 220,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "what",
      headerName: "Ação",
      width: 330,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "whenBegin",
      headerName: "Início previsto",
      width: 110,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(String(params.value));
          return `${date.toLocaleDateString("pt-br")}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "whenEnd",
      headerName: "Fim previsto",
      width: 110,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(String(params.value));
          return `${date.toLocaleDateString("pt-br")}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "whenRealBegin",
      headerName: "Início real",
      width: 110,
      type: "date",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(String(params.value));
          return `${date.toLocaleDateString("pt-br")}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "whenRealEnd",
      headerName: "Fim real",
      width: 110,
      type: "date",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(String(params.value));
          return `${date.toLocaleDateString("pt-br")}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "responsibleUsers",
      headerName: "Responsáveis",
      width: 180,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
  ];

  const handleSubmit = async () => {
    const mapSelected = apiRef.current.getSelectedRows();
    const arrayIds = [];
    for (const value of mapSelected.values()) {
      if (value.id) {
        arrayIds.push(Number(value.id));
      }
    }

    try {
      const response = await api.put(`api/actions/finish`, {
        ids: arrayIds,
        userId: user.id,
        constructionId: constructionEditId,
      });
      if (response.status === 200) {
        setAlert({
          title: "Plano de ações concluído!",
          message: "Planos de ações salvos com sucesso",
          isOpened: true,
        });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        title: "Erro ao salvar o plano de ações",
        message: "Algo deu errado. Tente novamente mais tarde.",
        isOpened: true,
      });
    }
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["criteria"],
      },
    },
  });

  const fetchRecords = async (
    dtBegin: string,
    dtEnd: string,
    filterDateType: string,
    filterCriteria: string[],
    filterResponsibleUser: string[],
    filterStatus: string[]
  ) => {
    if (!submitting) {
      if (dtBegin) {
        setLabelDtBegin(
          new Date(dtBegin + " 08:00:00").toLocaleDateString("pt-BR")
        );
      } else {
        setLabelDtBegin("");
      }

      if (dtEnd) {
        setLabelDtEnd(
          new Date(dtEnd + " 08:00:00").toLocaleDateString("pt-br")
        );
      } else {
        setLabelDtEnd("");
      }
      setSubmitting(true);
      try {
        const response = await api.post(`api/actions/items`, {
          constructionId: constructionEditId,
          dtBegin,
          dtEnd,
          dateType: undefined,
          criteria: filterCriteria,
          responsibleUser: filterResponsibleUser,
          status: filterStatus,
        });

        if (response.status === 201) {
          setActions(response.data);
          const rowIds = [];
          const arrayActionsByCriteria = [];
          let tempActions = [];
          let criteriaName =
            response.data.length > 0 ? response.data[0].criteria : "";

          if (response.data.length > 0) {
            for (const row of response.data) {
              if (row.status) {
                rowIds.push(row.id);
              }

              if (criteriaName !== row.criteria) {
                arrayActionsByCriteria.push(tempActions);
                criteriaName = row.criteria;
                tempActions = [];
              }

              tempActions.push(row);
            }
            arrayActionsByCriteria.push(tempActions);
          }

          setActionsByCriteria(arrayActionsByCriteria);

          if (rowIds.length) {
            apiRef.current.selectRows(rowIds);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item xs={8} lg={8} xl={8}>
          <Typography variant="h3" gutterBottom>
            {`${section} - Obra: ${constructionName}`}
          </Typography>
        </Grid>
        <Grid item xs={4} lg={4} xl={4} textAlign="right" mt={4}>
          <Typography variant="h5" gutterBottom>
            {`Gerente: ${
              managerName.length > 0 ? managerName : "Não cadastrado!"
            }`}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <SearchBar
            hasFilterCriteria
            hasFilterResponsibleUser
            hasFilterStatus
            callReport={fetchRecords}
          />

          <WrapperDataGrid>
            <Grid container mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Por favor, selecione as ações que deseja concluir na tabela
                  abaixo:
                </Typography>
              </Grid>

              {actionsByCriteria.length > 0 && (
                <Grid item xs={12} sm={6} textAlign={"right"}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    endIcon={<PictureAsPdf />}
                    onClick={() => setShowPdf(!showPdf)}
                  >
                    {showPdf ? "Fechar" : "Abrir"}
                  </Button>
                </Grid>
              )}
            </Grid>
            <ContentDataGrid>
              <DataGridPremium
                rows={actions}
                pagination
                getRowHeight={() => "auto"}
                columns={[...columns, checkboxColDef]}
                checkboxSelection
                disableSelectionOnClick
                apiRef={apiRef}
                rowGroupingColumnMode="single"
                groupingColDef={{
                  hideDescendantCount: true,
                }}
                initialState={initialState}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowId={(row) => row.id}
                //isRowSelectable={(params: GridRowParams) => params.row.id}
                checkboxSelectionVisibleOnly
              />
            </ContentDataGrid>
          </WrapperDataGrid>

          <Grid item xs={6} lg={6} xl={6} mt={6}>
            <Button
              variant="contained"
              color="info"
              endIcon={<CheckIcon />}
              onClick={handleSubmit}
            >
              Concluir ações selecionadas
            </Button>
          </Grid>
        </CardContent>
      </Card>

      {showPdf && actionsByCriteria.length > 0 && (
        <Card>
          <CardContent>
            <PDFViewer style={stylesPdf.viewer}>
              <Document title={`${section.toUpperCase()} - Global`}>
                <Page
                  size="A4"
                  style={stylesPdf.page}
                  orientation="landscape"
                  wrap
                >
                  <View style={stylesPdf.section}>
                    <Image src="/logo.png" style={stylesPdf.logo} />
                    <Text
                      style={stylesPdf.title}
                    >{`${section.toUpperCase()} - GLOBAL`}</Text>
                    <Text style={stylesPdf.construction}>
                      {constructionName.toUpperCase()}
                    </Text>
                  </View>

                  <Text style={stylesPdf.updateText}>
                    {labelDtBegin
                      ? `ATUALIZAÇÃO: ${labelDtBegin} à ${labelDtEnd}`
                      : ""}
                  </Text>

                  {actionsByCriteria.length > 0 &&
                    actionsByCriteria.map((itemActions, index) => {
                      return (
                        <ActionsPlanPdf
                          key={index}
                          criteriaName={itemActions[0].criteria!}
                          actions={itemActions}
                          labelDtEnd={labelDtEnd}
                        />
                      );
                    })}

                  {actionsByCriteria.length > 0 && (
                    <Text
                      style={stylesPdf.pagination}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                      }
                      fixed
                    />
                  )}
                </Page>
              </Document>
            </PDFViewer>
          </CardContent>
        </Card>
      )}

      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default ActionsPlanEdit;
