import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/variants";
import PopUpBaseComponent from "../../../components/PopUps/PopUpBaseComponent";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import VerticalServiceImage from "../../../services/interfaces/VerticalServiceImages";
import React from "react";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import Viewer from "react-viewer";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { ReactComponent as DeleteIcon } from "../../../assets/image_delete_icon.svg";
import { IconButton } from "@mui/material";

interface AddImagesPopUpProps {
  verticalServiceModel: any;
  images: VerticalServiceImage[];
  isOpen: boolean;
  isLoading: boolean;
  cancelOnClick: () => void;
  confirmOnClick: (images: any[]) => void;
  deleteImageOnClick: (image: VerticalServiceImage) => void;
}

const AddImagesPopUp: React.FC<AddImagesPopUpProps> = ({
  isOpen,
  isLoading,
  cancelOnClick,
  confirmOnClick,
  verticalServiceModel,
  images,
  deleteImageOnClick,
}) => {
  const [selectedImage, setSelectedImage] =
    React.useState<VerticalServiceImage>();

  const [isMobile, setIsMobile] = useState<boolean>();

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
    confirmOnClick(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 5,
  });

  function bytesToMb(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  const confirmOnClickCallback = () => {};

  const selectImage = (image: VerticalServiceImage) => {
    setSelectedImage(image);
  };

  const onPressDeleteIcon = (image: VerticalServiceImage) => {
    deleteImageOnClick(image);
  };

  return (
    <ReactBaseComponent>
      {!selectedImage ? (
        <PopUpBaseComponent
          isOpen={isOpen}
          cancelOnClick={cancelOnClick}
          confirmOnClick={() => confirmOnClick}
          closeOnClick={() => cancelOnClick()}
          titte="Adicionar imagens"
          isButtonContainerEnabled={false}
          width={750}
        >
          <ContentContainer>
            {!isLoading ? (
              <DropArea {...getRootProps()}>
                <DropAreaTitulo>Arraste imagens ou clique aqui</DropAreaTitulo>
                <InputFile type="file" {...getInputProps()} />
              </DropArea>
            ) : null}
            {isLoading ? (
              <LoadingContainer>
                <LoadingComponent size={140} />
              </LoadingContainer>
            ) : null}
            <ImageList>
              {images.map((file) => (
                <ImageTagContainer key={file.id}>
                  <IconButton onClick={() => onPressDeleteIcon(file)}>
                    <DeleteIcon />
                  </IconButton>
                  <ImageName onClick={() => selectImage(file)}>
                    {file.imageName + " ." + file.imageType}
                  </ImageName>
                  {!isMobile && (
                    <ImageSize onClick={() => selectImage(file)}>
                      {bytesToMb(file.imageSize) + "MB"}
                    </ImageSize>
                  )}
                </ImageTagContainer>
              ))}
            </ImageList>
          </ContentContainer>
        </PopUpBaseComponent>
      ) : null}
      {selectedImage !== undefined ? (
        <Viewer
          disableKeyboardSupport={true}
          noImgDetails={true}
          noNavbar={true}
          noFooter={true}
          onClose={() => setSelectedImage(undefined)}
          visible={selectedImage !== undefined}
          images={[
            { src: selectedImage.imageUrl, alt: selectedImage.imageName },
          ]}
        />
      ) : null}
    </ReactBaseComponent>
  );
};

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    max-height: 400px;
    padding-bottom: 16px;
    width: 750px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 100%;
    max-height: 400px;
    width: 90%;
  }
`;

const DropArea = styled.div`
  display: grid;
  height: 216px;
  border-radius: 12px;
  border: 2px dashed ${Colors.secondaryColor};
  align-content: center;
  justify-content: center;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 746px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 240px;
    max-width: 100%;
  }
`;

const LoadingContainer = styled.div`
  display: grid;
  height: 216px;
  border: 2px dashed ${Colors.secondaryColor};
  align-content: center;
  justify-content: center;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 761px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 240px;
  }
`;

const DropAreaTitulo = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

const DropAreaSubTitulo = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorGray};
`;

const InputFile = styled.input`
  height: 40px;
  background-color: ${Colors.primaryColor};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 170px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 170px;
  }
`;

const ImageList = styled.div`
  display: grid;
  grid-gap: 12px;
  align-content: start;
  justify-content: start;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, max-content));
    width: 100%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 760px;
    grid-template-columns: repeat(auto-fill, minmax(80px, max-content));
  }
`;

const ImageTagContainer = styled.div`
  display: grid;
  height: 36px;
  grid-template-columns: max-content auto min-content;
  padding-left: 8px;
  padding-right: 16px;
  align-items: center;
  border: 1px solid var(--color-neutral-light-gray, #ddd);
  border-radius: 36px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 250px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 80px;
    max-width: 230px;
  }
`;

const ImageName = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;

  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 4px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 120px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 80px;
  }
`;

const ImageSize = styled.div`
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

export default AddImagesPopUp;
