export enum ViewActionsByENUM {
  // seeAll = "seeAll",
  // byDay = "byDay",
  byWeek = "byWeek",
  byMonth = "byMonth",
}

export const ViewActionsByENUMLabel = {
  // [ViewActionsByENUM.seeAll]: "Ver todas",
  // [ViewActionsByENUM.byDay]: "Dia vigente",
  [ViewActionsByENUM.byWeek]: "Semana vigente",
  [ViewActionsByENUM.byMonth]: "Mês vigente",
};

export function viewTypeActionValues(): string[] {
  return Object.values(ViewActionsByENUMLabel);
}

export function viewTypeActionByValue(value: string) {
  const array = Object.keys(ViewActionsByENUM);
  for (let index = 0; index < array.length; index++) {
    const keyAction = array[index];
    if ((ViewActionsByENUMLabel as any)[keyAction] === value) {
      return keyAction as ViewActionsByENUM;
    }
  }

  return ViewActionsByENUM.byMonth;
}
