import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Outlet, useLocation } from "react-router-dom";

import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import { useAuth } from "../contexts/auth";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";

import { SidebarItemsType } from "../types/sidebar";
import { Users, UserPlus, LogOut, BarChart2 } from "react-feather";
import {
  ViewTimeline as ViewTimelineIcon,
  AccountBox as AccountBoxIcon,
  AddBusiness as AddBusinessIcon,
  DomainAdd as DomainAddIcon,
  Store as StoreIcon,
  Domain as DomainIcon,
  AssignmentLate as AssignmentLateIcon,
  NoteAdd as NoteAddIcon,
  Plagiarism as PlagiarismIcon,
  Description as DescriptionIcon,
  NoteAlt as NoteAltIcon,
  PendingActions as PendingActionsIcon,
  ManageHistory as ManageHistoryIcon,
  Task as TaskIcon,
  Rule as RuleIcon,
} from "@mui/icons-material";
import {
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
  authorizedRoutes,
} from "../config/app-info";
import { DashboardProvider } from "../contexts/dashboard";
import { NavbarVisibilityProvider } from "../contexts/navbar";
import { useNavbarVisibility } from "../contexts/navbar";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

// ${(props) => props.theme.breakpoints.up("md")} {
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("xlg")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardItems = {
  title: string;
  pages: SidebarItemsType[];
};

type Props = {
  children?: React.ReactNode;
};

const Default: React.FC<Props> = ({ children }) => {
  const pathname = useLocation().pathname;
  console.log(pathname);
  const isViewActions =
    pathname === "/acompanhar-acoes" ||
    pathname === "/dashboard/" ||
    pathname === "/vertical-servicos" ||
    pathname === "/vertical-servicos-relatorio" ||
    pathname.includes("/mapeamento-restricoes/listar") ||
    pathname.includes("mapeamento-restricoes/cadastrar") ||
    pathname === "/restricoes/cadastrar/" ||
    pathname.indexOf("/mapeamento-restricoes/editar/") !== -1 ||
    pathname.includes(ORDER_OF_STEPS_PATH) ||
    pathname.includes(PURCHASING_CONTRACTING_PLAN_PATH);
  // pathname.includes("/restricoes/editar/");

  const [mobileOpen, setMobileOpen] = useState(false);
  const [dashboardItems, setDashboardItems] = useState<DashboardItems[]>([]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const { user } = useAuth();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const { isNavbarVisible } = useNavbarVisibility();

  useEffect(() => {
    if (user.role !== undefined) {
      const pathsAuthorized = authorizedRoutes[user.role];
      const navItems = [];
      let contentSection: SidebarItemsType[] = [];
      let clientSection: SidebarItemsType[] = [];
      let verticalServiceSection: SidebarItemsType[] = [];
      let restrictionMappingSection: SidebarItemsType[] = [];
      let planningActionSection: SidebarItemsType[] = [];
      let executionActionSection: SidebarItemsType[] = [];

      // Dashboard
      let dashboardSection: SidebarItemsType[] = [];
      if (pathsAuthorized.includes("/dashboard/")) {
        dashboardSection.push({
          href: "/dashboard/",
          icon: BarChart2,
          title: "Painel de Indicadores",
        } as SidebarItemsType);
      }

      // Client's section
      if (pathsAuthorized.includes("/empresas/cadastrar/")) {
        clientSection.push({
          href: "/empresas/cadastrar/",
          icon: AddBusinessIcon,
          title: "Cadastrar empresa",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/usuarios/cadastrar/")) {
        clientSection.push({
          href: "/usuarios/cadastrar/",
          icon: UserPlus,
          title: "Cadastrar usuário",
        } as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/obras/cadastrar/")) {
        clientSection.push({
          href: "/obras/cadastrar/",
          icon: DomainAddIcon,
          title: "Cadastrar obra",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/empresas/listar/")) {
        clientSection.push({
          href: "/empresas/listar/",
          icon: StoreIcon,
          title: "Lista de empresas",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/usuarios/listar/")) {
        clientSection.push({
          href: "/usuarios/listar/",
          icon: Users,
          title: "Lista de usuários",
        } as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/obras/listar/")) {
        clientSection.push({
          href: "/obras/listar/",
          icon: DomainIcon,
          title: "Lista de obras",
        } as unknown as SidebarItemsType);
      }
      contentSection.push({
        href: "",
        icon: AccountBoxIcon,
        title: "Cadastros",
        children: clientSection,
      });

      // Restrictions Mappings' Section
      if (pathsAuthorized.includes("/restricoes/listar/")) {
        restrictionMappingSection.push({
          href: "/restricoes/listar/",
          icon: DescriptionIcon,
          title: "Lista de restrições",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/mapeamento-restricoes/listar/")) {
        restrictionMappingSection.push({
          href: "/mapeamento-restricoes/listar/",
          icon: NoteAltIcon,
          title: "Mapeamento de restrições",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/mapeamento-restricoes/cadastrar/")) {
        restrictionMappingSection.push({
          href: "/mapeamento-restricoes/cadastrar/",
          icon: NoteAddIcon,
          title: "Inserir novo mapeamentos",
        } as unknown as SidebarItemsType);
      }
      contentSection.push({
        href: "",
        icon: AssignmentLateIcon,
        title: "Mapeamento das restrições",
        children: restrictionMappingSection,
      });

      if (pathsAuthorized.includes("/planos-acoes/listar/")) {
        executionActionSection.push({
          href: "/planos-acoes/listar/",
          icon: RuleIcon,
          title: "Acompanhar os planos de ações",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/acompanhar-acoes")) {
        executionActionSection.push({
          href: "/acompanhar-acoes",
          icon: RuleIcon,
          title: "Acompanhamento de ações",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/planos-acoes/gantt/")) {
        executionActionSection.push({
          href: "/planos-acoes/gantt/",
          icon: ViewTimelineIcon,
          title: "Gantt",
        } as unknown as SidebarItemsType);
      }
      contentSection.push({
        href: "",
        icon: TaskIcon,
        title: "Executar ações",
        children: executionActionSection,
      });

      if (pathsAuthorized.includes(ORDER_OF_STEPS_PATH)) {
        verticalServiceSection.push({
          href: ORDER_OF_STEPS_PATH,
          icon: RuleIcon,
          title: "Ordem de etapas",
        } as unknown as SidebarItemsType);
      }

      if (pathsAuthorized.includes("/vertical-servicos")) {
        verticalServiceSection.push({
          href: "/vertical-servicos",
          icon: RuleIcon,
          title: "Vertical de serviços",
        } as unknown as SidebarItemsType);
      }

      if (pathsAuthorized.includes("/vertical-servicos-relatorio")) {
        verticalServiceSection.push({
          href: "/vertical-servicos-relatorio",
          icon: RuleIcon,
          title: "Relatório de serviços",
        } as unknown as SidebarItemsType);
      }

      if (pathsAuthorized.includes(PURCHASING_CONTRACTING_PLAN_PATH)) {
        verticalServiceSection.push({
          href: PURCHASING_CONTRACTING_PLAN_PATH,
          icon: RuleIcon,
          title: "Plano de compras e contratação",
        } as unknown as SidebarItemsType);
      }

      let systemSection: SidebarItemsType[] = [];
      systemSection.push({
        href: "/sair/",
        icon: LogOut,
        title: "Sair",
      } as SidebarItemsType);

      navItems.push({
        title: "Dashboard",
        pages: dashboardSection,
      });

      navItems.push({
        title: "Planejamento Estratégico",
        pages: contentSection,
      });

      navItems.push({
        title: "Vertical de serviços",
        pages: verticalServiceSection,
      });

      navItems.push({
        title: "Sistema",
        pages: systemSection,
      });

      setDashboardItems(navItems);
    } else {
      setDashboardItems([]);
    }
  }, [user]);

  return (
    <DashboardProvider>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
            onDrawerToggle={handleDrawerToggle}
          />
        </Drawer>
        <AppContent>
          {isNavbarVisible && <Navbar onDrawerToggle={handleDrawerToggle} />}
          <MainContent
            p={isLgUp ? 12 : 5}
            style={{
              padding: isViewActions ? "0px" : "16px",
              overflowX: isViewActions ? "hidden" : "auto",
              overflowY: isViewActions ? "hidden" : "auto",
            }}
          >
            {children}
            <Outlet />
          </MainContent>
        </AppContent>
      </Root>
    </DashboardProvider>
  );
};

export default Default;
