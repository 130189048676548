import React, { createContext, useContext, useState } from "react";
import { Client, Construction, DashboardContextData } from "../config/app-info";

const dashboardDefaultValue: DashboardContextData = {
  constructionId: 0,
  selectedConstruction: undefined,
  setConstructionId: () => {},
  clientId: 0,
  setClientId: () => {},
  dtBegin: new Date(),
  setDtBegin: () => {},
  dtEnd: new Date(),
  setDtEnd: () => {},
  isDashboard: false,
  setIsDashboard: () => {},
  constructions: [],
  setConstructions: () => {},
  clients: [],
  setClients: () => {},
};

const DashboardContext = createContext<DashboardContextData>(
  dashboardDefaultValue
);
type Props = {
  children: React.ReactNode;
};

export const DashboardProvider: React.FC<Props> = ({ children }) => {
  const [constructionId, setConstructionId] = useState(0);
  const [selectedConstruction, setSelectedConstruction] = useState<Construction>();
  const [clientId, setClientId] = useState(0);
  const [dtBegin, setDtBegin] = useState<Date | null>(null);
  const [dtEnd, setDtEnd] = useState<Date | null>(null);
  const [isDashboard, setIsDashboard] = useState(false);
  const [constructions, setConstructions] = useState<Construction[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  return (
    <DashboardContext.Provider
      value={{
        constructionId,
        setConstructionId,
        clientId,
        setClientId,
        dtBegin,
        setDtBegin,
        dtEnd,
        setDtEnd,
        isDashboard,
        setIsDashboard,
        constructions,
        setConstructions,
        clients,
        setClients,
        selectedConstruction,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);

  return context;
};
