import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/variants";

interface MouseHoverComponentProps {
  serviceType?: string;
  timePeriod?: { startDate: Date; endDate: Date };
  responsibleName?: string;
  status?: any;
  serviceGroupColor: string;
  totalDays?: number
}

const MouseHoverDetailsComponent: React.FC<MouseHoverComponentProps> = ({
  serviceType,
  timePeriod,
  responsibleName,
  status,
  serviceGroupColor,
  totalDays
}) => {
  const colorByStatus = (status?: any) => {
    switch (status?.value || "") {
      case 1:
        return Colors.attentionColor;
      case 2:
        return Colors.feedbackSuccess;
      case 3:
        return Colors.feedbackError;
      case 4:
        return Colors.warningColor;
    }
  };

  const timePeriodString = () => {
    if (timePeriod && totalDays !== undefined) {
      const startDateString = timePeriod.startDate.toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "numeric",
      });
      const endDateString = timePeriod.endDate.toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "numeric",
      });

      if (totalDays === 0 || totalDays === 1) {
        return `${startDateString} | 1 dia`;
      }

      return `${startDateString} > ${endDateString} | ${totalDays} dias`;
    } else {
      return "Sem período";
    }
  };

  return (
    <ReactBaseComponent>
      <Container>
        <InfoContainer>
          <ServiceType style={{ color: `${serviceGroupColor}` }}>
            {serviceType}
          </ServiceType>
          <TimePeriod>{timePeriodString()}</TimePeriod>
          <Responsible>Responsável: {responsibleName}</Responsible>
          <StatusContainer>
            <StatusSymbol style={{ backgroundColor: colorByStatus(status) }} />
            <StatusText>{status.name}</StatusText>
          </StatusContainer>
        </InfoContainer>
        <SymbolContainer />
      </Container>
    </ReactBaseComponent>
  );
};

const Container = styled.div`
  height: 180px;
  width: 179px;
  background-color: transparent;
  display: grid;
  grid-template-rows: min-content min-content;
  margin-bottom: 48px;
  position: absolute;
`;

const InfoContainer = styled.div`
  width: 100%;
  background-color: ${Colors.neutralColorSurface};
  display: grid;
  grid-template-rows: min-content min-content min-content min-content;
  row-gap: 8px;
  padding: 8px;
  align-content: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const ServiceType = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #000;
`;

const TimePeriod = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

const Responsible = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: center;
  align-content: center;
  column-gap: 4px;
`;

const StatusText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: -3px;
  color: #000;
`;

const StatusSymbol = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
`;

const SymbolContainer = styled.div`
  width: 20px;
  height: 20px;
  rotate: 45deg;
  background-color: ${Colors.neutralColorSurface};
  margin-top: -10px;
  place-self: center;
`;

export default MouseHoverDetailsComponent;
