import React from "react";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
//import { chartColors } from "../../../config/app-info";

type Props = {
  labels: string[];
  values: number[];
};

const RestrictionStatusControlChart: React.FC<Props> = ({ labels, values }) => {
  const data: ApexAxisChartSeries = [];

  if (values !== undefined && values.length > 0) {
    for (let i = 0; i < values.length; i++) {
      const object = {
        name: labels[i] ? labels[i] : "",
        data: [values[i]],
      };

      data.push(object);
    }
  }

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
      },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 40,
      colors: ["transparent"],
    },
    yaxis: {
      show: false,
      min: 1,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [""],
    },
    fill: {
      opacity: 1,
    },
    colors: ["#4169E1", "#32CD32", "#87CEEB", "#98FB98", "#FF8C00"],
  };

  return <Chart options={options} series={data} type="bar" height="250" />;
};

export default withTheme(RestrictionStatusControlChart);
