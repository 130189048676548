import { AxiosResponse } from "axios";
import { ActionItem, MappingItem } from "../../config/app-info";
import { api } from "../api";

export default class RestrictionMappingService {
  async createActions(
    actions: ActionItem[],
    restrictionsMappings: MappingItem[]
  ): Promise<AxiosResponse> {
    try {
      const actionData = {
        actions,
        restrictionsMappings,
      };

      const response = await api.post("api/actions/create", actionData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createRestrictionsMapping(
    mappingItem: MappingItem[]
  ): Promise<MappingItem[]> {
    const response = await api.post("api/restrictions-mappings/", mappingItem);
    return response.data;
  }

  async updateRestrictionsMapping(
    mappingItem: MappingItem[]
  ): Promise<MappingItem[]> {
    const response = await api.put("api/restrictions-mappings/", mappingItem);
    return response.data;
  }

  async getRestrictionsMappingByConstructionIdAndRestrictionid(
    constructionId: number,
    restrictionId: number
  ): Promise<MappingItem[]> {
    const response = await api.get(
      `api/restrictions-mappings/construction/${constructionId}/restrictions/${restrictionId}`
    );
    return response.data;
  }

  async deleteRestrictionMappingAndActions(restrictionMappingId: string) {
    try {
      const response = await api.delete(
        "api/restrictions-mappings/" + restrictionMappingId
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
