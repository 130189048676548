import styled from "styled-components";
import { Colors } from "../../../../theme/variants";

interface PlanTooltipProps {
  name: string;
  quantity: number;
  unit: string;
  specifications: string;
}

const PlanTooltip: React.FC<PlanTooltipProps> = ({
  name,
  quantity,
  unit,
  specifications,
}) => {
  return (
    <Container>
      <Title>{name}</Title>
      <DescriptionText style={{ marginTop: "8px" }}>
        {<b>Qtde:</b>}
        {quantity + " " + unit}
      </DescriptionText>
      <DescriptionText style={{ marginTop: "-6px" }}>
        {<b>Especificações: </b>}
        {specifications}
      </DescriptionText>
    </Container>
  );
};

const Container = styled.div`
  width: 176px;
  background-color: ${Colors.neutralColorSurface};
  display: grid;
  grid-template-rows: max-content max-content max-content;
  margin-bottom: 48px;
  position: absolute;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
`;

const DescriptionContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
`;

const DescriptionText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  color: ${Colors.textColorBlack};
`;

const Title = styled.div`
  font-family: "Noto Sans";
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  color: #451674;
  text-align: center;
  place-self: center;
`;

export default PlanTooltip;
