// @ts-nocheck
import React from "react";
import { Text, View } from "@react-pdf/renderer";
import {
  Table as TablePdf,
  TableHeader as TableHeaderPdf,
  TableCell as TableCellPdf,
  DataTableCell as DataTableCellPdf,
  TableBody as TableBodyPdf,
} from "@david.kucsai/react-pdf-table";
import {
  ActionItem,
  restrictionCategories,
  restrictionPriorities,
} from "../../../config/app-info";
import { stylesPdf } from "./style";

type Props = {
  criteriaName: string;
  actions: ActionItem[];
  labelDtEnd: string;
};
const ActionsPlanPdf: React.FC<Props> = ({
  criteriaName,
  actions,
  labelDtEnd,
}) => {
  return (
    <View>
      <Text style={stylesPdf.criteriaName}>{criteriaName}</Text>

      <TablePdf data={actions} style={stylesPdf.table}>
        <TableHeaderPdf textAlign="center">
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Restrição
          </TableCellPdf>
          <TableCellPdf weighting={0.3} style={stylesPdf.tr}>
            Ação
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Responsáveis
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Status
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Início previsto
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Fim previsto
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Início real
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Fim real
          </TableCellPdf>

          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Categoria
          </TableCellPdf>
          <TableCellPdf weighting={0.1} style={stylesPdf.tr}>
            Prioridade
          </TableCellPdf>
          <TableCellPdf weighting={0.2} style={stylesPdf.tr}>
            Comentários
          </TableCellPdf>
        </TableHeaderPdf>

        <TableBodyPdf>
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.td}
            getContent={(r) => r.restrictionName}
          />
          <DataTableCellPdf
            weighting={0.3}
            style={stylesPdf.td}
            getContent={(r) => r.what}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.td}
            getContent={(r) => r.responsibleUsers}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            getContent={(r) => {
              
              return r.statusAsString;
            }}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            textAlign="center"
            getContent={(r) => {
              if (r.whenBegin) {
                const date = new Date(String(r.whenBegin));
                return `${date.toLocaleDateString("pt-br")}`;
              } else {
                return "";
              }
            }}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            getContent={(r) => {
              if (r.whenEnd) {
                const date = new Date(String(r.whenEnd));
                return `${date.toLocaleDateString("pt-br")}`;
              } else {
                return "";
              }
            }}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            getContent={(r) => {
              if (r.whenRealBegin) {
                const date = new Date(String(r.whenRealBegin));
                return `${date.toLocaleDateString("pt-br")}`;
              } else {
                return "";
              }
            }}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            getContent={(r) => {
              if (r.whenRealEnd) {
                const date = new Date(String(r.whenRealEnd));
                return `${date.toLocaleDateString("pt-br")}`;
              } else {
                return "";
              }
            }}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            getContent={(r) => restrictionCategories[r.category]}
          />
          <DataTableCellPdf
            weighting={0.1}
            style={stylesPdf.tdCenter}
            getContent={(r) => restrictionPriorities[r.priority]}
          />
          <DataTableCellPdf
            weighting={0.2}
            style={stylesPdf.td}
            getContent={(r) => r.how}
          />
        </TableBodyPdf>
      </TablePdf>
    </View>
  );
};

export default ActionsPlanPdf;
