import styled from "styled-components";
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useState } from "react";

export enum DropDownComponentStyle {
  OUTLINED = "outlined",
  TRANSPARENT = "transparent",
}

interface ServiceGroupChecklistDownComponentProps {
  key: string;
  onSelectedValueChanged: (items: ItemType[]) => void;
  values: any[];
  defaultValue?: number;
  style?: DropDownComponentStyle;
  selectedValue?: number;
}

interface ItemType {
  type: string;
  group: string;
  color: string;
  id: number;
  addByUser?: boolean;
}

const ServiceGroupChecklistDownComponent = (
  props: ServiceGroupChecklistDownComponentProps
) => {
  const groupValues = (values: any[]): ItemType[] => {
    const listItens: ItemType[] = [];

    for (const value of values) {
      const groupName = value.name;
      const color = value.color;

      for (const serviceType of value.services) {
        listItens.push({
          type: serviceType.name,
          id: serviceType.id,
          group: groupName,
          color: color,
          addByUser: serviceType.isAddByUser === true,
        });
      }
    }
    return listItens;
  };
  const [value, setValue] = useState<ItemType[]>([
    {
      type: "",
      group: "",
      color: "",
      id: 0,
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<ItemType[]>([]);

  React.useEffect(() => {
    setValue(groupValues(props.values));
  }, [props.values]);

  const handleInputChange = (newValue: (string | ItemType | null)[]) => {
    if (!newValue) {
      return;
    }

    if (typeof newValue === "string") {
      return;
    }

    const itemTypeList = newValue
      .map((item) => {
        if (isItemType(item)) {
          return item;
        }
      })
      .filter((item) => item !== undefined) as ItemType[];

    setSelectedItem(itemTypeList);
    props.onSelectedValueChanged(itemTypeList);
    return;
  };

  const isItemType = (item: any): item is ItemType => {
    return "type" in item && "group" in item && "color" in item && "id" in item;
  };

  const _filterOptions = createFilterOptions<ItemType>();
  const filterOptions = (options: any, state: any) => {
    const results = _filterOptions(options, state);
    const newServices = (options as Array<ItemType>).filter(
      (item) => item.id === -1
    );

    for (const newService of newServices) {
      if (
        results.find(
          (result) => result.id === -1 && result.group === newService.group
        ) === undefined
      ) {
        results.push(newService);
      }
    }

    return results;
  };

  const headerCheckListClicked = (group: string) => {
    const itemsByGroup = value.filter((item) => item.group === group);
    const selectedItemsByGroupLength = selectedItem.filter(
      (item) => item.group === group
    ).length;

    if (selectedItemsByGroupLength === itemsByGroup.length) {
      const selectedList = selectedItem.filter((item) => item.group !== group);
      setSelectedItem(selectedList);
      props.onSelectedValueChanged(selectedList);
      return;
    }

    if (selectedItemsByGroupLength > 0) {
      const notSelectedItems = selectedItem.filter(
        (item) => item.group !== group
      );
      notSelectedItems.push(...itemsByGroup);
      setSelectedItem(notSelectedItems);
      props.onSelectedValueChanged(notSelectedItems);
      return;
    }

    if (selectedItem.length === 0) {
      setSelectedItem(itemsByGroup);
      props.onSelectedValueChanged(itemsByGroup);
      return;
    }

    const selectedItemsCopy = selectedItem.slice();
    selectedItemsCopy.push(...itemsByGroup);
    setSelectedItem(selectedItemsCopy);
    props.onSelectedValueChanged(selectedItemsCopy);
  };

  return (
    <Root>
      <Autocomplete
        id="service_type_selector"
        value={selectedItem}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.type
        }
        fullWidth
        size="small"
        onChange={(e, data) => handleInputChange(data)}
        options={value || []}
        groupBy={(option) => option.group}
        renderGroup={(option) => (
          <li key={option.key}>
            <HeaderGroup
              style={{
                backgroundColor: value.find(
                  (item) => item.group === option.group
                )?.color,
              }}
            >
              <Checkbox
                sx={{
                  color: "#fff",
                  marginLeft: "-24px",
                  "&.Mui-checked": {
                    color: "#fff",
                  },
                }}
                checked={
                  value.filter((item) => item.group === option.group).length ===
                  selectedItem.filter((item) => item.group === option.group)
                    .length
                }
                onClick={() => headerCheckListClicked(option.group)}
              />
              {option.group}
            </HeaderGroup>
            <div>{option.children}</div>
          </li>
        )}
        renderOption={(props, group) => (
          <CustomMenuItem
            {...props}
            key={group.type}
            autoFocus={false}
            value={group.type}
          >
            <Checkbox
              checked={
                selectedItem.find((itemType: ItemType) => {
                  return itemType.id === group.id;
                }) !== undefined
              }
            />
            <CustomCircleIndicator style={{ background: `${group.color}` }} />
            <CustomMenuItemContainer>
              <CustomMenuItemText
                style={{ fontWeight: group.id === -1 ? 700 : 400 }}
              >
                {group.type}
              </CustomMenuItemText>
            </CustomMenuItemContainer>
          </CustomMenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled
            label="Descrição de Serviço"
            select={false}
          />
        )}
        renderTags={(value, getTagProps) => {
          return value
            .slice(0, 1)
            .map((option, index) => (
              <SelectedTag {...getTagProps({ index })}>
                {
                  <SelectedTagText>
                    {`${value.length} serviços selecionado(s)`}
                  </SelectedTagText>
                }
              </SelectedTag>
            ));
        }}
        disableClearable
        disableCloseOnSelect
        noOptionsText="Nenhuma opção encontrada"
        filterOptions={filterOptions}
        freeSolo
        multiple
        unselectable="on"
      />
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const HeaderGroup = styled.div`
  display: grid;
  height: 40px;
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #f8f9fc;
  padding-left: 24px;
  grid-template-columns: max-content max-content;
  align-items: center;
`;

const CustomMenuItem = styled(MenuItem)`
  display: flex;
  height: 40px;
  color: #565757;
  padding-left: 16px;
  flex-direction: row;
`;

const CustomCircleIndicator = styled.div`
  width: 20px;
  height: 20px;
`;

const CustomMenuItemContainer = styled.div`
  width: 100%;
`;

const CustomMenuItemText = styled.div`
  font-family: "Noto Sans";
  width: 230px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedTag = styled.div`
  display: flex;
  padding: 6px;
  margin: 2px;
  background: #f8f9fc;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const SelectedTagText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #333;
`;

export default ServiceGroupChecklistDownComponent;
