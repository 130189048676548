import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/variants";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import InputValueComponent from "../../../components/InputValues/InputValueComponent";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  IconButton,
  TextField as MuiTextField,
  Tab,
  Tabs, Checkbox, FormControlLabel
} from "@mui/material";
import { spacing } from "@mui/system";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import React, { useEffect, useImperativeHandle } from "react";
import CustomDropDownComponent, {
  ItemType,
} from "../../../components/InputValues/CustomDropDownComponent";
import { UserResponsible } from "../../../services/UserService";
import { useLottie } from "lottie-react";
import LoadingLottieJson from "../../../assets/loading.json";
import { moneyMask } from "../../../utils/mask";
import { UnitValues } from "../utils";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { ReactComponent as ArrowDownIcon } from "../../../assets/arrow-down.svg";
import { Field, Formik } from "formik";
import { PurchasingContractingENUM } from "../../PurchasingContractingPlan/models/PurchasingContractingENUM";
import * as Yup from "yup";
import PurchasingModel from "../../PurchasingContractingPlan/models/PurchasingModel";
import ContractingModel from "../../PurchasingContractingPlan/models/ContractingMode";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

export interface NewServiceType {
  name: string;
  groupId: number;
}
interface AddServicePopUpProps {
  isOpen: boolean;
  serviceTypes: any[];
  onClose?: () => void;
  onConfirm?: (
    model: VerticalServiceModel,
    newServiceType?: NewServiceType,
    purchasingModel?: PurchasingModel,
    contractingModel?: ContractingModel
  ) => void;
  userResponsibles?: UserResponsible[];
  isLoadingDialog?: boolean;
  model?: VerticalServiceModel;

  ref: any;
  onDeleteCustomService?: (id: string) => void;
}

const options = {
  animationData: LoadingLottieJson,
  loop: true,
  autoplay: true,
  style: { width: 120, height: 120 },
};

export interface AddServicePopUpRef {
  cleanData: () => void;
}

const yupSchema = Yup.object().shape({
  product: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.PURCHASING,
    then: Yup.string().required("Campo obrigatório"),
  }),
  service: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.CONTRACTING,
    then: Yup.string().required("Campo obrigatório"),
  }),
  quantity: Yup.number()
    .required("Campo obrigatório")
    .when("selectedTab", {
      is: PurchasingContractingENUM.PURCHASING,
      then: Yup.number().required("Campo obrigatório"),
    }),
  unit: Yup.string()
    .required("Campo obrigatório")
    .when("selectedTab", {
      is: PurchasingContractingENUM.PURCHASING,
      then: Yup.string().required("Campo obrigatório"),
    }),
  specifications: Yup.string().required("Campo obrigatório"),
});

const AddServicePopUp = React.forwardRef<
  AddServicePopUpRef | undefined,
  AddServicePopUpProps
>(({ ...props }, ref) => {
  const {
    isOpen,
    onClose,
    onConfirm,
    serviceTypes,
    userResponsibles,
    isLoadingDialog,
    model,
    onDeleteCustomService,
  } = props;
  const { View } = useLottie(options);
  const [data, setData] = React.useState<VerticalServiceModel>(
    model ? model : new VerticalServiceModel()
  );
  const [newServiceType, setNewServiceType] = React.useState<NewServiceType>();
  const [selecedServiceType, setSelectedServiceType] =
    React.useState<ItemType>();
  const [selectedPlan, setSelectedPlan] = React.useState<
    PurchasingModel | undefined
  >();

  const [purchaseContractingIsOpen, setPurchaseContractingIsOpen] =
    React.useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    cleanData: () => {
      cleanData();
    },
  }));

  useEffect(() => {
    setData(
      model
        ? {
            ...model,
            beginDate: model.beginDate ? new Date(model.beginDate) : undefined,
            endDate: model.endDate ? new Date(model.endDate) : undefined,
          }
        : new VerticalServiceModel()
    );

    if (model) {
      serviceTypes.find((serviceGroup) => {
        const service = serviceGroup.services.find(
          (serviceType: any) => serviceType.id === model.verticalServiceTypeId
        );
        if (service) {
          setSelectedServiceType({
            group: serviceGroup.name,
            color: serviceGroup.color,
            id: service.id,
            type: service.name,
            addByUser: service.isAddByUser === true,
          });
        }
      });
    }
    /*
    listItens.push({
          type: serviceType.name,
          id: serviceType.id,
          group: groupName,
          color: color,
          addByUser: serviceType.isAddByUser === true,
        });
    */
  }, [model]);

  const handleStatusChange = (key: string, value: string) => {
    data.status = value;
    setData({
      ...data,
      status: data.status,
    });
  };

  const handleUnityChange = (key: string, value: string) => {
    data.unit = value;
    setData({
      ...data,
      unit: data.unit,
    });
  };

  const handleQuantityChange = (value: string) => {
    if (isNaN(Number.parseFloat(value))) {
      data.quantity = 0;
      setData({
        ...data,
        quantity: data.quantity,
      });
      return;
    }

    data.quantity = Number.parseInt(value);
    if (isNaN(data.quantity)) {
      data.quantity = 0;
    }
    setData({
      ...data,
      quantity: data.quantity,
    });
  };

  const handleMaterialCostChange = (value: string) => {
    const priceWithMask = moneyMask(value);
    data.materialCost = Number.parseInt(value.replace(/\D/g, ""));
    setData({
      ...data,
      materialCostWithMask: priceWithMask,
      materialCost: data.materialCost,
    });
  };

  const handleWorkforceCostChange = (value: string) => {
    const priceWithMask = moneyMask(value);
    data.workforceCost = Number.parseInt(value.replace(/\D/g, ""));
    setData({
      ...data,
      workforceCost: data.workforceCost,
      workforceCostWithMask: priceWithMask,
    });
  };

  const handleLocationChange = (value: string) => {
    setData({
      ...data,
      location: value,
    });
  };

  const handleResponsibleChange = (key: string, value: string) => {
    const id = userResponsibles?.filter((user) => user.name === value)[0].id;

    if (id !== undefined) {
      data.responsibleUserId = Number.parseInt(id);
      data.responsibleUserName = value;
      setData({
        ...data,
        responsibleUserId: data.responsibleUserId,
        responsibleUserName: data.responsibleUserName,
      });
    }
  };

  const handleStartDateChange = (date: any) => {
    const startDate = new Date(date);

    if (data.endDate !== undefined && startDate > data.endDate) {
      data.endDate = new Date(startDate);
    }

    setData({
      ...data,
      beginDate: startDate,
      endDate: data.endDate,
    });
  };

  const handleEndDateChange = (date: any) => {
    let endDate = new Date(date);

    if (data.beginDate != undefined && endDate < data.beginDate) {
      endDate = data.beginDate;
    }

    setData({
      ...data,
      endDate: endDate,
    });
  };

  const handleServiceTypeChange = (
    key: string,
    value: string,
    addedByUser: boolean
  ) => {
    const group = serviceTypes.filter(
      (serviceGroup) => serviceGroup.name === key
    );
    const service = group[0].services.filter(
      (serviceType: any) => serviceType.name === value
    );

    if (addedByUser) {
      data.verticalServiceGroupId = group[0].id;
      data.description = value;
      setNewServiceType({ name: value, groupId: group[0].id });
      setData({
        ...data,
        verticalServiceGroupId: data.verticalServiceGroupId,
        description: data.description,
      });
      return;
    }

    data.description = service[0].name;
    data.verticalServiceGroupId = group[0].id;
    data.verticalServiceTypeId = service[0].id;
    setData({
      ...data,
      description: data.description,
      verticalServiceGroupId: data.verticalServiceGroupId,
      verticalServiceTypeId: data.verticalServiceTypeId,
    });
  };

  const cleanData = () => {
    setNewServiceType(undefined);
  };

  const handleOnConfirm = (values: any) => {
    if (isLoadingDialog) {
      return;
    }

    let purchasingModel: PurchasingModel | undefined = undefined;
    let contractingModel: ContractingModel | undefined = undefined;

    if (values.product !== "") {
      purchasingModel = {
        name: values.product,
        quantity: values.quantity,
        unit: values.unit,
        specifications: values.purchasing_specifications,
        services: [],
      };
    }

    if (values.service !== "") {
      contractingModel = {
        name: values.service,
        specifications: values.contracting_specifications,
        services: [],
      };
    }

    onConfirm?.({ ...data, weekdaysOnly: values.weekdaysOnly }, newServiceType, purchasingModel, contractingModel);
  };

  const handleSubmit = (values: any) => {};

  const isPurchasingAllValid = (values: any) => {
    const isAllValuesNotFilledAndContainerNotOpened =
      values.product === "" &&
      values.purchasing_specifications === "" &&
      (values.quantity === "" || values.quantity === 0) &&
      values.unit === "";

    if (purchaseContractingIsOpen === false) {
      return true;
    }

    if (
      isAllValuesNotFilledAndContainerNotOpened &&
      purchaseContractingIsOpen === true
    ) {
      return true;
    }

    const isAllValuesFilled =
      values.product !== "" &&
      values.quantity !== "" &&
      values.unit !== "" &&
      values.purchasing_specifications !== "";

    return isAllValuesFilled;
  };

  const isContractingValid = (values: any) => {
    if (purchaseContractingIsOpen === false) {
      return true;
    }

    if (
      values.service === "" &&
      values.contracting_specifications === "" &&
      purchaseContractingIsOpen === true
    ) {
      return true;
    }

    return values.service !== "" && values.contracting_specifications !== "";
  };

  return (
    <ReactBaseComponent>
      <BootstrapDialog open={isOpen} disableEscapeKeyDown={false}>
        {!isLoadingDialog ? (
          <Formik
            initialValues={{
              service: "",
              quantity: "",
              unit: "",
              purchasing_specifications: "",
              contracting_specifications: "",
              product: "",
              selectedTab: PurchasingContractingENUM.PURCHASING,
              weekdaysOnly: data.weekdaysOnly != undefined ? data.weekdaysOnly : true
            }}
            validationSchema={yupSchema}
            isInitialValid={true}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              handleChange,
              isValid,
              errors,
            }) => (
              <RootContainer>
                <HeaderContainer>
                  <DialogTitle>
                    {!model ? "Vincular serviços" : "Editar serviço"}
                  </DialogTitle>
                </HeaderContainer>
                <ContentContainer>
                  <NameAndStatusContainer>
                    <CustomDropDownComponent
                      values={serviceTypes}
                      key="CustomDropDownComponent"
                      onSelectedValueChanged={handleServiceTypeChange}
                      selectedValue={selecedServiceType}
                      onDeletedCustomType={onDeleteCustomService}
                    />
                  </NameAndStatusContainer>
                  <QuanityUnitAndPriceContainer>
                    <InputValueComponent
                      onChange={(value) => handleQuantityChange(value)}
                      label="Quantidade"
                      value={
                        data.quantity !== undefined
                          ? data.quantity.toString()
                          : ""
                      }
                    />
                    <DropDownComponent
                      defaultValue="Unidade"
                      label="Unidade"
                      key="unity"
                      onSelectedValueChanged={(key, value) =>
                        handleUnityChange(key, value)
                      }
                      values={UnitValues}
                      selectedValue={data.unit}
                    />
                    <InputValueComponent
                      onChange={(value) => handleMaterialCostChange(value)}
                      label="Custo de material"
                      value={
                        data.materialCostWithMask !== undefined
                          ? data.materialCostWithMask.toString()
                          : moneyMask(data?.materialCost?.toString() || "")
                      }
                      placeholder="R$ 999.999,99"
                    />
                    <InputValueComponent
                      onChange={(value) => handleWorkforceCostChange(value)}
                      label="Custo de mão-de-obra"
                      value={
                        data.workforceCostWithMask !== undefined
                          ? data.workforceCostWithMask.toString()
                          : moneyMask(data?.workforceCost?.toString() || "")
                      }
                      mask=""
                      placeholder="R$ 999.999,99"
                    />
                  </QuanityUnitAndPriceContainer>
                  <LocationResponsibleAndTimeContainer>
                    <InputValueComponent
                      onChange={(value) => handleLocationChange(value)}
                      label="Localização"
                      value={data.location}
                    />
                    <DropDownComponent
                      defaultValue="Responsável"
                      key="responsible"
                      label="Responsável"
                      selectedValue={data.responsibleUserName}
                      onSelectedValueChanged={(key, value) =>
                        handleResponsibleChange(key, value)
                      }
                      values={userResponsibles?.map((user) => user.name) || []}
                    />
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <DesktopDatePicker
                        label="Início"
                        value={data.beginDate || new Date()}
                        onChange={(date) => handleStartDateChange(date)}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                      <DesktopDatePicker
                        label="Data fim"
                        value={
                          data.endDate ||
                          new Date().setDate(new Date().getDate() + 7)
                        }
                        onChange={(date) => handleEndDateChange(date)}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </LocationResponsibleAndTimeContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.weekdaysOnly}
                        onChange={(value) => {
                          return setFieldValue("weekdaysOnly", value.target.checked)
                        }}
                      />
                    }
                    label={<CheckboxLabel>Considerar apenas dias úteis</CheckboxLabel>}
                  />
                </ContentContainer>
                {!model && (
                  <PurchaseAndContractingContainer>
                    <PurchaseAndContractingTitleContainer>
                      <DialogTitle>Compras e contratações</DialogTitle>
                      <IconButton
                        onClick={() =>
                          setPurchaseContractingIsOpen(
                            !purchaseContractingIsOpen
                          )
                        }
                      >
                        <ArrowDownIcon
                          style={
                            purchaseContractingIsOpen
                              ? { transform: "rotate(180deg)" }
                              : {}
                          }
                        />
                      </IconButton>
                    </PurchaseAndContractingTitleContainer>
                    {purchaseContractingIsOpen && (
                      <PurchasingAndContractionContainer>
                        <TabContainer>
                          <Tabs
                            value={values.selectedTab}
                            onChange={(event, value) =>
                              setFieldValue("selectedTab", value)
                            }
                            style={{ width: "100%" }}
                          >
                            <CustomTab
                              value={PurchasingContractingENUM.PURCHASING}
                              label="Compras"
                            />
                            <CustomTab
                              value={PurchasingContractingENUM.CONTRACTING}
                              label="Contratação"
                            />
                          </Tabs>
                        </TabContainer>
                        {values.selectedTab ===
                          PurchasingContractingENUM.CONTRACTING && (
                          <ContractingForm>
                            <Field
                              name="specifications"
                              render={() => {
                                return (
                                  <InputValueComponent
                                    label="Serviço"
                                    value={values.service}
                                    onChange={(value: string) => {
                                      setFieldValue("service", value);
                                    }}
                                  />
                                );
                              }}
                            />
                            <Field
                              name="specifications"
                              render={() => {
                                return (
                                  <InputValueComponent
                                    label="Especificações"
                                    value={values.contracting_specifications}
                                    onChange={(value: string) => {
                                      setFieldValue(
                                        "contracting_specifications",
                                        value
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          </ContractingForm>
                        )}

                        {values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING && (
                          <PurchasingForm>
                            <PurchasingFormRowOne>
                              <Field
                                name="product"
                                render={() => {
                                  return (
                                    <InputValueComponent
                                      label="Produto"
                                      value={values.product}
                                      onChange={(value: string) => {
                                        setFieldValue("product", value);
                                      }}
                                    />
                                  );
                                }}
                              />

                              <Field
                                name="quantity"
                                render={() => {
                                  return (
                                    <InputValueComponent
                                      label="Quantidade"
                                      type="number"
                                      mask=""
                                      value={values.quantity}
                                      onChange={(value: string) => {
                                        let newValue = Number.parseFloat(value);
                                        if (isNaN(newValue)) {
                                          newValue = 0;
                                        }
                                        setFieldValue("quantity", newValue);
                                      }}
                                    />
                                  );
                                }}
                              />

                              <Field
                                name="unit"
                                render={() => {
                                  return (
                                    <InputValueComponent
                                      label="Unidade"
                                      value={values.unit}
                                      onChange={(value: string) => {
                                        setFieldValue("unit", value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </PurchasingFormRowOne>
                            <Field
                              name="specifications"
                              render={() => {
                                return (
                                  <InputValueComponent
                                    label="Especificações"
                                    value={values.purchasing_specifications}
                                    onChange={(value: string) => {
                                      setFieldValue(
                                        "purchasing_specifications",
                                        value
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          </PurchasingForm>
                        )}
                      </PurchasingAndContractionContainer>
                    )}
                  </PurchaseAndContractingContainer>
                )}
                <ButtonsContainer>
                  <ButtonComponent
                    text="Cancelar"
                    type={ButtonType.TRANSPARENT}
                    onClick={() => onClose?.()}
                  />
                  <ButtonComponent
                    state={
                      VerticalServiceModel.isValid(data) &&
                      isPurchasingAllValid(values) &&
                      isContractingValid(values)
                        ? ButtonState.DEFAULT_ENABLED
                        : ButtonState.DISABLED
                    }
                    text="Confirmar"
                    onClick={() => handleOnConfirm(values)}
                  />
                </ButtonsContainer>
              </RootContainer>
            )}
          </Formik>
        ) : null}

        {isLoadingDialog ? (
          <LoadingDialogContainer>
            <LoadingComponent />
          </LoadingDialogContainer>
        ) : null}
      </BootstrapDialog>
    </ReactBaseComponent>
  );
});

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 825px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
`;

const RootContainer = styled.div`
  min-width: 320px;
  max-width: 825px;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content min-content max-content min-content;
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 825px;
  height: 369px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const HeaderContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid ${Colors.primaryColor};
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  padding-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  display: grid;
  grid-template-rows: min-content min-content min-content;
  row-gap: 16px;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  padding-left: 32px;
  padding-right: 24px;
  padding-bottom: 32px;
  place-self: end;
  column-gap: 16px;
  margin-top: 24px;
`;

const NameAndStatusContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
`;

const QuanityUnitAndPriceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const LocationResponsibleAndTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 293px 1fr 1fr 1fr;
  column-gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const CheckboxLabel = styled.div`
  display: inline-block;
  font-family: "Noto Sans", Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: 2px;
`

const PurchaseAndContractingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 28px;
`;

const PurchaseAndContractingTitleContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
`;

const PurchaseAndContractingContentContainer = styled.div``;

const TabContainer = styled.div`
  margin-top: 8px;
`;

const ListPurchasingContractionContainer = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const PurchasingAndContractionContainer = styled.div``;

const ContractingForm = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 24px;
  margin-top: 24px;
`;

const PurchasingForm = styled.form`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 24px;
  margin-top: 24px;
`;

const PurchasingFormRowOne = styled.div`
  display: grid;
  grid-template-columns: 1fr 152px 152px;
  gap: 24px;
`;

export default AddServicePopUp;
