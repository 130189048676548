import styled from "styled-components";
import { Colors } from "../../theme/variants";
import { IconButton, Button } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import { ReactComponent as CalendarCheck } from "../../assets/icon_calendar_check.svg";
import { ReactComponent as CommentIcon } from "../../assets/comments_icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash_icon.svg";
import { Draggable } from "@hello-pangea/dnd";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

export interface ActionCardProps {
  id: string;
  title: string;
  tagList: string[];
  startDate: Date;
  deadlineDate: Date;
  commentsCount: number;
}

export interface ActionDraggableCardProps {
  cardProps: ActionCardProps;
  position: number;
  onTrashClick: (card: ActionCardProps) => void;
  isDragDisabled?: boolean;
}

const ActionCard: React.FC<ActionDraggableCardProps> = ({
  cardProps: { id, title, tagList, startDate, deadlineDate, commentsCount },
  position: index,
  onTrashClick,
  isDragDisabled,
}) => {
  const startDateAsString = startDate.toLocaleDateString();
  const deadlineDateAsString = deadlineDate
    .toLocaleDateString()
    .substring(0, 5);

  const onTrashClickCallback = () => {
    onTrashClick({
      id,
      title,
      tagList,
      startDate,
      deadlineDate,
      commentsCount,
    });
  };

  return (
    <Draggable isDragDisabled={isDragDisabled} draggableId={id} index={index}>
      {(provided, snapshop) => (
        <ViewActionsContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TitleContainer>
            <TitleText>{title}</TitleText>
            <IconContainer>
              {/* <IconButton >
                <ArrowUpward
                  style={{
                    rotate: "90deg",
                    color: Colors.primaryColor,
                    padding: "4px",
                  }}
                />
              </IconButton> */}
            </IconContainer>
          </TitleContainer>
          <StartDateContainer>
            <StartDateText>Inicio {startDateAsString}</StartDateText>
          </StartDateContainer>
          <TagListContainer>
            {tagList.map((tag) => (
              <TagContainer>
                <TagText>{tag}</TagText>
              </TagContainer>
            ))}
          </TagListContainer>
          <DetailsContainer>
            <MyButton startIcon={<CalendarCheck />}>
              {deadlineDateAsString}
            </MyButton>
            {/* <MyButton startIcon={<CommentIcon />}>{commentsCount}</MyButton> */}
            <div />
            <MyIconButton onClick={() => onTrashClickCallback()}>
              <TrashIcon />
            </MyIconButton>
          </DetailsContainer>
        </ViewActionsContainer>
      )}
    </Draggable>
  );
};

const ViewActionsContainer = styled.div`
  display: grid;
  width: 311px;
  height: 252px;
  background: ${Colors.textColorLight};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  grid-template-rows: min-content min-content auto min-content;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 16px;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  height: fit-content;
`;

const TitleText = styled.div`
  width: 240px;
  max-width: 240px;
  overflow: hidden;
  font-family: "Noto Sans";
  height: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
  margin-left: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const IconContainer = styled.div`
  width: 100%;
`;

const StartDateContainer = styled.div`
  display: grid;
  height: fit-content;
  margin-top: -4px;
  margin-left: 16px;
`;

const StartDateText = styled.div`
  color: ${Colors.neutralColor};
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 8px;
`;

const TagListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 12px;
  height: fit-content;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
`;

const TagContainer = styled.div`
  min-width: auto;
  max-width: 190px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  background: #565757;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const TagText = styled.div`
  color: #f1f0f0;
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  word-wrap: break-word;
  height: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  height: fit-content;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const MyButton = styled(Button)`
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorGray};
  letter-spacing: 0.4px;
  word-wrap: break-word;
  width: fit-content;
  margin-left: 24px;
`;

const MyIconButton = styled(IconButton)`
  width: fit-content;
  align-self: flex-end;
  margin-right: 12px;
`;

export default ActionCard;
