import * as React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { authorizedRoutes } from "../../config/app-info";
import { checkAuth } from "../../services/auth";
import { useQuery } from "react-query";

type AuthGuardType = {
  children: React.ReactNode;
};

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const navigate = useNavigate();
  const { signed, loading, user } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;

  useQuery<boolean>(["checkAuth"], () => checkAuth(), {
    onSuccess: (result: boolean) => {
      if (!result) {
        navigate("/");
      }
    },
    onError: (error) => {
      navigate("/");
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: 21600000,
  });

  if (!signed && !loading) {
    return moveToLoginScreen();
  }

  let authorized = false;
  if (user.role !== undefined) {
    if (user.role === 0) {
      authorized = true;
    } else {
      authorizedRoutes[user.role].forEach((route) => {
        if (pathname.indexOf(route) !== -1) {
          authorized = true;
        }
      });
    }
  }

  if (!authorized && !loading) {
    return moveToLoginScreen();
  }

  return <React.Fragment>{children}</React.Fragment>;

  function moveToLoginScreen() {
    return <Navigate to="/" />;
  }
}

export default AuthGuard;
