import React, { createContext, useContext, useState } from "react";
import { MappingContextData, MappingItem } from "../config/app-info";

const mappingDefaultValue: MappingContextData = {
  mappingItems: [],
  setMappingItems: () => {},
};

const MappingContext = createContext<MappingContextData>(mappingDefaultValue);
type Props = {
  children: React.ReactNode;
};

export const MappingProvider: React.FC<Props> = ({ children }) => {
  const [mappingItems, setMappingItems] = useState<MappingItem[]>([]);

  return (
    <MappingContext.Provider value={{ mappingItems, setMappingItems }}>
      {children}
    </MappingContext.Provider>
  );
};

export const useMapping = () => {
  const context = useContext(MappingContext);

  return context;
};
