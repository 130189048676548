import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import { LinearProgress } from "@mui/material";
import React from "react";

export enum AdvancementIndexType {
  PHYSICAL = "Físico",
  FINANCIAL = "Financeiro",
}

interface AdvancementIndexComponentProps {
  title: string;
  value: number;
  type?: AdvancementIndexType;
  concludedActions?: number;
  totalActions?: number;
  budgetUsed?: number;
}

const AdvancementIndexReportComponent: React.FC<
  AdvancementIndexComponentProps
> = ({ title, value, type, concludedActions, totalActions, budgetUsed }) => {
  return (
    <Container>
      <IndiceTitle>{title}</IndiceTitle>
      <IndiceContainer>
        <LinearProgress
          sx={{
            backgroundColor: "#DDD",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#EBA742",
            },
            height: "24px",
            borderRadius: "12px",
            marginTop: "2px",
          }}
          variant="determinate"
          value={
            value === null
              ? 0
              : value > 100
              ? 100
              : Number.parseFloat(value.toFixed(2))
          }
        />
      </IndiceContainer>
      <IndiceValue>
        {value ? Number.parseFloat(value.toFixed(2)) : 0}%
      </IndiceValue>
      {/* {type === AdvancementIndexType.PHYSICAL && <Description>{`${concludedActions} de ${totalActions} ações concluídas`}</Description>} */}
      {/* {type === AdvancementIndexType.PHYSICAL && <div style={{height:"14px"}}/>}
        {type === AdvancementIndexType.FINANCIAL && <Description>{`R$ ${budgetUsed} do orçamento previsto consumido`}</Description>}
     */}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 109px 1fr 64px;
  column-gap: 16px;
  justify-content: start;
`;

const IndiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 8px;
  justify-content: center;
`;

const IndiceTitle = styled.div`
  font-family: "Noto Sans";
  width: 109px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
  word-wrap: break-word;
`;

const IndiceValue = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.textColorGray};
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

export default AdvancementIndexReportComponent;
