import ICriteriaEntity from "../../../services/restrictions/entity/CriteriaEntity";
import RestrictionMappingModel from "./RestrictionModel";

export default interface ICriteriaModel {
  id: string;
  name: string;
  status: boolean | null;
  createdAt: string;
  restrictions?: RestrictionMappingModel[] | null;
}

export default class CriteriaModel implements ICriteriaModel {
  id: string;
  name: string;
  status: boolean | null;
  createdAt: string;
  restrictions?: RestrictionMappingModel[] | null;

  constructor(data: ICriteriaModel) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.restrictions = data.restrictions || null;
  }

  static fromEntityArray(data: ICriteriaEntity[]): ICriteriaModel[] {
    return data.map((item) => {
      return CriteriaModel.fromEntity(item);
    });
  }

  static fromEntity(data: ICriteriaEntity): ICriteriaModel {
    return new CriteriaModel({
      id: data.id,
      name: data.name,
      status: data.status,
      createdAt: data.createdAt,
      restrictions: RestrictionMappingModel.parseFromEntityArray(
        data.restrictions || []
      ),
    });
  }
}
