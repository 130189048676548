import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  title: string;
  message: string;
  buttonLabel: string;
  onClose: () => void;
  isOpened: boolean;
};

const Alert: React.FC<Props> = ({
  title,
  message,
  buttonLabel,
  onClose,
  isOpened,
}) => (
  <Dialog
    open={isOpened}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => onClose()}
        color="primary"
        autoFocus
        variant="contained"
      >
        {buttonLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

export default Alert;
