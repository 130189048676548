import { ContentContainer } from "../PurchasingAndContractingListItem/PurchasingAndContractingListItem.styles";
import {
  HeaderCellContainer,
  HeaderCellText,
} from "./PurchasingAndContractingListHeader.styles";

export enum ListTypeEnum {
  PURCHASING = "Compras",
  CONTRACTING = "Contratação",
}

interface PurchasingAndContractingListHeaderProps {
  isMobile?: boolean;
  type: ListTypeEnum;
}

const PurchasingAndContractingListHeader: React.FC<
  PurchasingAndContractingListHeaderProps
> = ({ type, isMobile }) => {
  return (
    <ContentContainer>
      <HeaderCellContainer isVisible={true}>
        <HeaderCellText>Nome</HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={true}>
        <HeaderCellText>Início Cotação</HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={isMobile ? false : true}>
        <HeaderCellText>
          {type === ListTypeEnum.PURCHASING ? "Comprar até" : "Contratar até"}
        </HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={isMobile ? false : true}>
        <HeaderCellText>
          {type === ListTypeEnum.PURCHASING ? "Entregar até" : "Receber até"}
        </HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={isMobile ? false : true}>
        <HeaderCellText>Status</HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={isMobile ? false : true}>
        <HeaderCellText>Fase atual</HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={isMobile ? false : true}>
        <HeaderCellText>Responsável</HeaderCellText>
      </HeaderCellContainer>
      <HeaderCellContainer isVisible={isMobile ? false : true}>
        <HeaderCellText>Ações</HeaderCellText>
      </HeaderCellContainer>
    </ContentContainer>
  );
};

export default PurchasingAndContractingListHeader;
