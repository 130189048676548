import { Box } from "react-feather";
import styled from "styled-components";
import DashboardCard from "../../Cards/DashboardCard/DashboardCard";
import React, { useEffect } from "react";
import ActionsService from "../../../services/action/ActionsService";
import { ViewActionsByENUM } from "../../../pages/Actions/ViewActions/utils/ViewActionsByENUM";
import { BaseState } from "../../../pages/Actions/ViewActions/utils/BaseState";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

interface ActionByStatusProps {
  constructionId?: string;
  year?: string;
}

interface ActionByStatusContainerState {
  inProgress: number;
  finished: number;
  scheduled: number;
  delayed: number;
}

const ActionsByStatus: React.FC<ActionByStatusProps> = ({
  constructionId,
  year,
}) => {
  const actionService = new ActionsService();
  const [state, setState] = React.useState<BaseState>(BaseState.loading);
  const [{ inProgress, finished, scheduled: pending, delayed }, setActions] =
    React.useState<ActionByStatusContainerState>({
      inProgress: 0,
      finished: 0,
      scheduled: 0,
      delayed: 0,
    });

  const [month, setMonth] = React.useState<number>(new Date().getMonth());

  useEffect(() => {
    getActionsByStatus(constructionId, month);
  }, [constructionId, year]);

  const onMonthChanged = (month: string, monthAsNumber: number) => {
    setMonth(monthAsNumber);
    getActionsByStatus(constructionId, monthAsNumber);
  };

  const getActionsByStatus = async (
    constructionId?: string,
    month?: number
  ) => {
    if (!constructionId || month === undefined || month === null) {
      return;
    }

    setState(BaseState.loading);
    month = month + 1;

    await actionService
      .findActionsByConstructionSortByStatus(
        constructionId,
        ViewActionsByENUM.byMonth,
        month,
        year
      )
      .then((response) => {
        setActions({
          inProgress: response.inProgress.length,
          finished: response.concluded.length,
          scheduled: response.scheduled.length,
          delayed: response.late.length,
        });
        setState(BaseState.success);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  return (
    <DashboardCard
      title="Status das ações"
      onMonthChanged={onMonthChanged}
      state={state}
      selectorLabel="Mês"
    >
      <ContentContainer>
        <BoxContainer>
          <ActionsContainer>
            <NumberText>{inProgress}</NumberText>
            <StatusText>Em andamento</StatusText>
          </ActionsContainer>
        </BoxContainer>
        <Separator />
        <BoxContainer>
          <ActionsContainer>
            <NumberText>{finished}</NumberText>
            <StatusText>Concluídas</StatusText>
          </ActionsContainer>
        </BoxContainer>
        <Separator />
        <BoxContainer>
          <ActionsContainer>
            <NumberText>{pending}</NumberText>
            <StatusText>Programadas</StatusText>
          </ActionsContainer>
        </BoxContainer>
        <Separator />
        <BoxContainer>
          <ActionsContainer>
            <NumberText>{delayed}</NumberText>
            <StatusText>Atrasados</StatusText>
          </ActionsContainer>
        </BoxContainer>
      </ContentContainer>
    </DashboardCard>
  );
};

const ContentContainer = styled.div`
  max-width: 100%;
  height: 165px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: max-content;
    overflow: scroll;
  }
`;

const BoxContainer = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px;
    background: #f8f9fc;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: max-content;
    padding: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 16px;
  }
`;

const Separator = styled.div`
  width: 1px;
  border-right: #13214e solid;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    visibility: hidden;
  }
`;

const ActionsContainer = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: fit-content;
    height: fit-content;
    padding: 8px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    height: max-content;
    grid-template-rows: max-content max-content;
    justify-content: flex-start;
  }
`;

const Text = styled.div`
  text-align: center;
  font-family: "Noto Sans";
  word-wrap: break-word;
`;

const NumberText = styled(Text)`
  height: 69px;
  color: #13214e;
  font-size: 64px;
  font-weight: 700;
  font-family: "Noto Sans";
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 42px;
    font-size: 32px;
    margin-top: 8px;
  }
`;

const StatusText = styled(Text)`
  color: #855000;
  font-size: 14px;
  font-weight: 600;
  font-family: "Noto Sans";
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 8px;
  }
`;

export default ActionsByStatus;
