import React, { createContext, useContext, useState, ReactNode } from "react";

interface NavbarVisibilityContextType {
  isNavbarVisible: boolean;
  setNavbarVisibility: (isVisible: boolean) => void;
}

const NavbarVisibilityContext = createContext<
  NavbarVisibilityContextType | undefined
>(undefined);

export const NavbarVisibilityProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  const setNavbarVisibility = (isVisible: boolean) => {
    setIsNavbarVisible(isVisible);
  };

  return (
    <NavbarVisibilityContext.Provider
      value={{ isNavbarVisible, setNavbarVisibility }}
    >
      {children}
    </NavbarVisibilityContext.Provider>
  );
};

export const useNavbarVisibility = () => {
  const context = useContext(NavbarVisibilityContext);
  if (context === undefined) {
    throw new Error(
      "useNavbarVisibility must be used within a NavbarVisibilityProvider"
    );
  }
  return context;
};
