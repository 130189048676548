import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/variants";
import PopUpBaseComponent from "../../../components/PopUps/PopUpBaseComponent";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import VerticalServiceImage from "../../../services/interfaces/VerticalServiceImages";
import React from "react";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import Viewer from "react-viewer";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import ButtonComponent, {
  ButtonType,
} from "../../../components/Button/ButtonComponent";

interface AddImagesPopUpProps {
  isOpen: boolean;
  isLoading: boolean;
  dowloadXLSXTemplate: () => void;
  cancelOnClick: () => void;
  confirmOnClick: (file: any) => void;
}

const ImportExcelFilePopUp: React.FC<AddImagesPopUpProps> = ({
  isOpen,
  isLoading,
  cancelOnClick,
  confirmOnClick,
  dowloadXLSXTemplate,
}) => {
  const [selectedFile, setSelectedFile] = React.useState<any>();

  const [isMobile, setIsMobile] = useState<boolean>();

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
    // setSelectedFile(acceptedFiles[0]);
    confirmOnClick(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
    },
    maxFiles: 1,
  });

  const handelConfirmOnClick = () => {
    confirmOnClick(selectedFile);
    setSelectedFile(undefined);
  };

  return (
    <ReactBaseComponent>
      {!selectedFile ? (
        <PopUpBaseComponent
          isOpen={isOpen}
          cancelOnClick={cancelOnClick}
          confirmOnClick={() => handelConfirmOnClick()}
          closeOnClick={() => cancelOnClick()}
          titte="Importar planilha excel"
          isButtonContainerEnabled={false}
          width={761}
        >
          <ContentContainer>
            <div style={{ width: "200px" }}>
              <ButtonComponent
                type={ButtonType.OUTLINE}
                text="Baixar planilha modelo"
                onClick={() => dowloadXLSXTemplate()}
              />
            </div>
            {!isLoading ? (
              <DropArea {...getRootProps()}>
                <DropAreaDescription>
                  Formato permitido: XLSX
                </DropAreaDescription>
                <DropAreaDescription>Tamanho máximo : 1MB</DropAreaDescription>
                <DropAreaTitulo>Arraste imagens ou clique aqui</DropAreaTitulo>
                <DropAreaDescription style={{ marginBottom: "8px" }}>
                  ou clique abaixo
                </DropAreaDescription>
                <ButtonComponent
                  type={ButtonType.PRIMARY}
                  text="Selecione o arquivo"
                />
                <InputFile type="file" {...getInputProps()} />
              </DropArea>
            ) : null}
            {isLoading ? (
              <LoadingContainer>
                <LoadingComponent size={140} />
              </LoadingContainer>
            ) : null}
          </ContentContainer>
        </PopUpBaseComponent>
      ) : null}
    </ReactBaseComponent>
  );
};

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 24px;
  overflow-x: hidden;
  width: 761px;
  margin-bottom: 32px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    max-height: 400px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 100%;
    max-height: 320px;
  }
`;

const DropArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 216px;
  border-radius: 12px;
  border: 2px dashed ${Colors.secondaryColor};
  align-items: center;
  justify-content: center;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 240px;
    max-width: 100%;
  }
`;

const LoadingContainer = styled.div`
  display: grid;
  height: 216px;
  border: 2px dashed ${Colors.secondaryColor};
  align-content: center;
  justify-content: center;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 600px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 240px;
  }
`;

const DropAreaTitulo = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
  margin-top: 8px;
  margin-bottom: 8px;
`;

const DropAreaSubTitulo = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorGray};
`;

const DropAreaDescription = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorGray};
`;

const InputFile = styled.input`
  height: 40px;
  background-color: ${Colors.primaryColor};
  width: 177px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 170px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 170px;
  }
`;

const ImageList = styled.div`
  display: grid;
  grid-gap: 24px;
  align-content: start;
  justify-content: start;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, max-content));
    width: 760px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 760px;
    grid-template-columns: repeat(auto-fill, minmax(80px, max-content));
  }
`;

export default ImportExcelFilePopUp;
