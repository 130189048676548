import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  LinearProgress as MuiLinearProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useAuth } from "../../contexts/auth";

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function FormLogin() {
  const { message, signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        login: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        login: Yup.string().max(20).required("Campo login é obrigatório"),
        password: Yup.string().max(255).required("Campo senha é obrigatório"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.login, values.password);
        } catch (error: any) {
          //const message = error.message || "Algo deu errado";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Alert mt={3} mb={3} severity="info">
            Use <strong>seu login</strong> e <strong>sua senha</strong> para
            entrar no sistema.
          </Alert>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="login"
            label="Login"
            size="small"
            value={values.login}
            error={Boolean(touched.login && errors.login)}
            fullWidth
            helperText={touched.login && errors.login}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Senha"
            size="small"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          {message.length > 0 && (
            <Alert mt={3} mb={3} severity="error">
              <strong>{message}</strong>
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Entrar
          </Button>
          {isSubmitting && <LinearProgress mt={6} />}
        </form>
      )}
    </Formik>
  );
}

export default FormLogin;
