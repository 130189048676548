import styled from "styled-components";
import DashboardCard from "../../Cards/DashboardCard/DashboardCard";
import { BaseState } from "../../../pages/Actions/ViewActions/utils/BaseState";
import { Colors } from "../../../theme/variants";
import Subtitle from "../Subtitle/Subtitle";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { use } from "i18next";
import ActionsService from "../../../services/action/ActionsService";
import { LinearProgress, withStyles } from "@mui/material";

interface ActionsEvolutionProps {
  constructionId?: string;
  year?: string;
}

const borderColor = "#0000";
const baseConfig = {
  borderColor: borderColor,
  borderWidth: 0,
  borderRadius: Number.MAX_VALUE,
  borderSkipped: false,
  categoryPercentage: 0.3,
  data: [0],
};

const baseData = {
  labels: [""],
  datasets: [
    {
      label: "IRR Semanal",
      backgroundColor: Colors.primaryColor,
      ...baseConfig,
    },
    {
      label: "IRR Global",
      backgroundColor: Colors.secondaryColor,
      ...baseConfig,
    },
  ],
};

const baseOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      max: 100,
    },
  },
  maintainAspectRatio: false,
};

const RestrictionsRemoveChart: React.FC<ActionsEvolutionProps> = ({
  constructionId,
  year,
}) => {
  const actionService = new ActionsService();
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [month, setMonth] = useState<Date>(new Date());
  const [concludedActions, setConcludedActions] = useState<number>(0);
  const [totalActions, setTotalActions] = useState<number>(0);
  const [monthIRR, setMonthIRR] = useState<number>(0);

  useEffect(() => {
    findRestrictionsRemoveByPeriod(constructionId, month);
  }, [constructionId, year]);

  const findRestrictionsRemoveByPeriod = async (
    constructionId?: string,
    date?: Date
  ) => {
    if (!constructionId || !date) {
      return;
    }
    setState(BaseState.loading);
    month.setFullYear(Number(year));

    await actionService
      .constructionActionsIrr(constructionId, date)
      .then((response) => {
        handleResponseData(response);
        setState(BaseState.success);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  const handleResponseData = (data: any) => {
    baseData.labels = data.periods;
    //baseData.datasets[0].data = data.data.totalActions;
    baseData.datasets[0].data = data.data.weekIRR;
    baseData.datasets[1].data = data.data.globalIRR;
    setConcludedActions(data.data.monthConcludedActions);
    setTotalActions(data.data.monthTotalActions);
    setMonthIRR(data.data.monthIRRAsPercentage);
    setChartData(baseData);
  };

  const onMonthChanged = (month: string, monthAsNumber: number) => {
    const date = new Date();
    date.setMonth(monthAsNumber);
    setMonth(date);
    date.setFullYear(Number(year));
    findRestrictionsRemoveByPeriod(constructionId, date);
  };

  return (
    <DashboardCard
      state={state}
      title="Remoção de Restrições"
      selectorLabel="Mês"
      onMonthChanged={onMonthChanged}
    >
      <RootContainer>
        <MonthIRRContainer>
          <MonthIRRText>IRR Mensal</MonthIRRText>
          <ProgressBarContainer>
            <LinearProgress
              sx={{
                backgroundColor: "#DDD",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: Colors.secondaryColorLight,
                },
                height: "16px",
                borderRadius: "8px",
                marginTop: "2px",
              }}
              variant="determinate"
              value={monthIRR}
            />
            <ProgressBarDescription>
              {concludedActions} de {totalActions} ações concluídas
            </ProgressBarDescription>
          </ProgressBarContainer>
          <MonthIRRValue>{monthIRR > 0 ? monthIRR : 0}%</MonthIRRValue>
        </MonthIRRContainer>
        <ChartContainer>
          <Bar width={800} options={baseOptions} data={chartData} />
        </ChartContainer>
        <SubtitleGroup>
          {/* <Subtitle color={Colors.primaryColorLight} text="Total" /> */}
          <Subtitle color={Colors.primaryColor} text="IRR Semanal" />
          <Subtitle color={Colors.secondaryColor} text="IRR Global" />
        </SubtitleGroup>
      </RootContainer>
    </DashboardCard>
  );
};

const RootContainer = styled.div`
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 28px;
  display: grid;
  grid-template-rows: max-content max-content;
`;

const MonthIRRContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 16px;
`;

const ProgressBarContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 5px;
`;

const ProgressBarDescription = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

const MonthIRRText = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.textColorBlack};
  margin-right: 16px;
`;

const MonthIRRValue = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

const ChartContainer = styled.div`
  height: 260px;
  margin-top: 34px;
`;

const SubtitleGroup = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content min-content;
  column-gap: 20px;
  width: fit-content;
  justify-self: center;
  margin-bottom: 16px;
`;

export default RestrictionsRemoveChart;
