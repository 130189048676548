import RestrictionMappingModel from "../../pages/Restrictions/models/RestrictionModel";
import { api } from "../api";
import IRestrictionMappingEntity from "../restrictionMapping/entity/RestrictionMappingEntity";

export default class ActionsService {
  async findActionsByConstructionSortByStatus(
    constructionId: string,
    queryType: string,
    month?: number,
    year?: string,
    beginDate?: Date | undefined,
    endDate?: Date | undefined
  ) {
    const result = await api.get(
      `api/actions/construction/${constructionId}/by-status`,
      {
        params: {
          queryType,
          month,
          year,
          beginDate,
          endDate,
        },
      }
    );

    return result.data;
  }

  async updateActionStatus(actionId: string, status: string) {
    const result = await api.put(`api/actions/${actionId}/status`, {
      status,
    });

    return result.data;
  }

  async updateAction(
    actionId: string,
    { beginDate, endDate }: { beginDate: Date; endDate: Date }
  ) {
    const result = await api.put(`api/actions/${actionId}`, {
      beginDate,
      endDate,
    });
    return result.data;
  }

  async deleteAction(actionId: string) {
    const result = await api.delete(`api/actions/${actionId}`);
    return result.data;
  }

  async findActionsEvolutionByPeriod(constructionId: string, date: Date) {
    const result = await api.get(
      `api/actions/construction/${constructionId}/evolution-by-period`,
      {
        params: {
          date,
        },
      }
    );
    return result.data;
  }

  async constructionActionsIrr(constructionId: string, date: Date) {
    const result = await api.get(
      `api/actions/construction/${constructionId}/construction-irr-by-period`,
      {
        params: {
          date,
        },
      }
    );

    return result.data;
  }

  async constructionPriorityCriteria(
    constructionId: string,
    dtBegin: Date,
    dtEnd: Date
  ) {
    const formateBeginDate = dtBegin?.toLocaleString().substring(0, 10);
    const formateEndDate = dtEnd?.toLocaleString().substring(0, 10);

    const result = await api.post(
      `api/dashboard/construction-priority-criteria`,
      {
        constructionId: constructionId,
        dtBegin: formateBeginDate,
        dtEnd: formateEndDate,
        dateBegin: dtBegin,
        dateEnd: dtEnd,
      }
    );

    return result.data;
  }

  async delayedActionsByCriteria(constructionId: string, criteriaId?: string) {
    const result = await api.post(`api/dashboard/construction-late-criteria`, {
      constructionId: constructionId,
    });

    return result.data;
  }

  async findActionsByConstructionAndCriteria(
    constructionId: string,
    criteriaId: string,
    showAll: boolean
  ): Promise<IRestrictionMappingEntity[]> {
    try {
      const showAllAsNUmber = showAll ? 1 : 0;
      const result = await api.get(
        `api/actions/construction/${constructionId}/criteria/${criteriaId}/${showAllAsNUmber}`
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
}
