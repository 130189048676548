import styled from "styled-components";
import { TableItemModel } from "./models/TableItemModel";
import { useEffect, useState } from "react";
import TableItem from "./item/TableItem";
import TableItemHeader from "./item/TableItemHeader";
import { TableItemTypeENUM } from "./models/TableItemENUM";
import TableItemAction from "./item/TableItemAction";
import { TableActionsENUM } from "./models/TableActionsENUM";
import { Colors } from "../../theme/variants";

interface TableComponentProps {
  data: any[];
  columns: TableItemModel[];
  isDataCountEnabled?: boolean;
  externalDataCount?: number;
  description?: string;
  isHeaderFilterEnabled?: boolean;
  onActionClick?: (item: any, action: TableActionsENUM) => void;
}

const GridTableHeader = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns = 0 }) => columns - 1}, 1fr) min-content;
  width: 100%;
  flex-shrink: 0;
`;

const GridTable = styled.div<{ columns: number }>``;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${Colors.neutralColorWhite};
  overflow-x: hidden;
`;

const GridRow = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.neutralColorLight};
`;

const TableComponent: React.FC<TableComponentProps> = ({
  data,
  columns,
  isDataCountEnabled,
  externalDataCount,
  description,
  isHeaderFilterEnabled,
  onActionClick,
}) => {
  const [tableData, setTableData] = useState<any[]>(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const getItemValue = (item: any, colum: TableItemModel) => {
    try {
      const value = item[colum.valueKey];
      return value;
    } catch (error) {
      return "";
    }
  };

  const onFilterClick = (name: string, key: string) => {
    const copyData = [...tableData];
    copyData.sort((a, b) => {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });

    setTableData(copyData);
  };

  return (
    <RootContainer>
      <GridTableHeader columns={columns.length}>
        {columns.map((item) => (
          <TableItemHeader
            type={item.type}
            name={item.columnName}
            isHeaderFilterEnabled={isHeaderFilterEnabled}
            filterOnClick={() => onFilterClick(item.columnName, item.valueKey)}
          />
        ))}
      </GridTableHeader>
      <GridContainer>
        <GridTable columns={columns.length}>
          {tableData.map((item, index) => (
            <div>
              <GridRow>
                {columns.map((column) =>
                  column.type === TableItemTypeENUM.action ? (
                    <TableItemAction
                      key={column.valueKey}
                      onClick={(action) => onActionClick?.(item, action)}
                      item={item}
                      actionList={column.actions}
                    />
                  ) : (
                    <TableItem
                      item={item}
                      value={getItemValue(item, column)}
                      key={item.key}
                    />
                  )
                )}
              </GridRow>
              {index !== tableData.length - 1 && <Divider />}
            </div>
          ))}
        </GridTable>
      </GridContainer>

      {/* <Body1
        style={{
          marginTop: "24px",
          flexShrink: 0,
          visibility: isDataCountEnabled ? "visible" : "hidden",
        }}
      >
        {externalDataCount ? externalDataCount : data.length} {description}
      </Body1> */}
    </RootContainer>
  );
};

const Body1 = styled.body`
  color: ${Colors.textColorBlack};
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 19.2px;
  word-wrap: break-word;
`;

export default TableComponent;
