import React from 'react';
import styled from 'styled-components';

interface ReactPageBaseComponentProps {
  children: React.ReactNode;
}

const ReactPageBaseComponent: React.FC<ReactPageBaseComponentProps> = ({
  children,
}) => {
  return (<ReactPageBaseComponentContainer>
    { children }
  </ReactPageBaseComponentContainer>)
};

const ReactPageBaseComponentContainer = styled.div``

export default ReactPageBaseComponent;
