import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import {
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Alert as MuiAlert,
} from "@mui/material";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Alert = styled(MuiAlert)(spacing);

const AlertNoRecords = styled(Alert)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Card, Divider, Typography, AlertNoRecords };
