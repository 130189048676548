import * as Sentry from "@sentry/react";

let initialized = false

const SentrySetup = () => {
  if (!initialized) {
    initialized = true
    Sentry.init({
      environment: process.env.NODE_ENV || 'development',
      dsn: "https://6ea3da5b9ce5bbf3483ed608510ae15f@o4507431755120640.ingest.us.sentry.io/4507450728185856",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "teste.engenhariaeperformance.com", "www.gobuilder.com.br"],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

export { SentrySetup }